<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   :sm="12">
                <v-card flat>
                    <p class="text-h4 text-center text--primary"
                       style="padding: 10px 0px 10px 0px;">Prescrizioni Precedenti</p>
                </v-card>
                <ath-prescriptions-table :headers="headers"
                                         :events="prescriptions"
                                         :total="prescriptionsTotal"
                                         :loading="loading"
                                         v-on:ath-pagination-update="handlePaginationUpdate"
                                         v-on:ath-reuse-prescription-ev="$emit('ath-reuse-prescription-ev', $event)"
                                         :reuse-prescription="true"
                                         :page="page"
                                         :perPage="perPage">
                    <!-- <template v-slot:filters
                        ></template> -->
                </ath-prescriptions-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog"
                  max-width="600px">
            <ath-view-prescription :item="prescriptionDetail">
            </ath-view-prescription>
        </v-dialog>
    </v-container>
</template>

<script>
import AthPrescriptionsTable from "../ath-prescriptions/ath-prescriptions-table.vue";
import AthPrescriptionsService from "../ath-prescriptions/ath-prescriptions.service";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthViewPrescription from "../ath-view-prescription/ath-view-prescription.vue";

export default {
    name: "AthReusePrescription",
    components: {
        AthPrescriptionsTable,
        AthViewPrescription,
    },
    props: ["patientUUID"],
    mounted() {
        this.loading = true;

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    watch: {
        patientUUID: {
            handler: function(p) {
                if(p == null) return;
                this.handlePaginationUpdate({page: 1, perPage: 5});
            }
        }
    },
    methods: {
        handleViewDetails(prescription) {
            this.prescriptionDetail = prescription;
            this.dialog = true;
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthPrescriptionsService
                .getPrescriptionsForPatient(
                    this.page,
                    this.perPage,
                    this.patientUUID
                )
                .then((data) => {
                    this.prescriptions = data.data;
                    this.prescriptionsTotal = data.total;
                    this.loading = false;
                });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
    },
    data: () => ({
        // data for the appointments table
        prescriptions: [],
        prescriptionsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Paziente", value: "patient" },
            { text: "Dottore", value: "doctor" },
            { text: "Data", value: "date" },
            { text: "Completata", value: "done" },
            { text: "Farmaci", value: "drugs" },
            // { text: "Chiusura Veloce", value: "closeReason" },
            { text: "Azione", value: "reuse" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 5,

        // details
        prescriptionDetail: null,

        // details dialog
        dialog: false,

        // dummy for change detection
        dummy: false,
    }),
};
</script>
