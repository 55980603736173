<template>
    <v-autocomplete
        outlined
        clearable
        v-on:change="handleSelectRepresentative"
        :items="representatives"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="isLoading"
        :search-input.sync="search"
        :filter="filter"
        prepend-inner-icon="mdi-account-tie"
        label="Ricerca Informatore"
        no-data-text="Scrivi per cercare"
        ref="selectRepresentativeRef"
    >
        <template v-slot:selection="{ item }">
            <span
                >{{ item.firstName + ' ' + item.lastName }} | <b>{{ item.phCompany.name }}</b></span
            >
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title
                    >{{ item.firstName + ' ' + item.lastName }} | <b>{{ item.phCompany.name }}</b> | 
                        <v-icon>mdi-map-marker</v-icon> {{ item.address }} </v-list-item-title
                >
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import AthRepresentativeService from "../../shared/ath-representatives.service";

export default {
    name: "AthSelectRepresentative",
    props: {
        doctorUUID: {
            type: String,
            required: false,
        },
        toggleClear: {
            type: Boolean,
            required: false,
        }
    },
    methods: {
        // looks okay as it is, because search is done server-side.
        // in case, refer to: https://vuetifyjs.com/en/api/v-autocomplete/#props-filter
        // and: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40
        filter: (item, queryText, itemText) => {
            // console.info({item, queryText, itemText});
            item;
            queryText;
            itemText;
            // return itemText.toUpperCase().replace(/ /g,'').indexOf(queryText.toUpperCase().replace(/ /g,'')) > -1
            return true;
        },
        // these two cannot be inlined as in the other component because of https://github.com/vuetifyjs/vuetify/issues/6635#issuecomment-528444479
        itemText: function(item) {
            return (
                item.firstName +
                " " +
                item.lastName +
                " | " +
                item.phCompany.name +
                " | " +
                item.address
            );
        },
        // the return type must be a primitive and apparenty an array or an object are not okay
        // so we return a json string containing the whole element
        itemValue: function(item) {
            return JSON.stringify(item);
        },
        handleSelectRepresentative($event) {
            this.$emit('ath-select-representative', $event);
            return $event;
        },
    },
    data: () => ({
        isLoading: false,
        representatives: [],
        search: null,
    }),
    watch: {
        search(val) {
            this.isLoading = true;

            AthRepresentativeService.search(val || "null")
                .then((res) => {
                    this.representatives = res;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
        },
        toggleClear: {
            handler: function(t) {
                t;
                this.$refs.selectRepresentativeRef.reset();
            }
        }
    },
};
</script>
