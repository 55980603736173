import AthWebClient from "./ath-webclient";

export default {
    name: "AthSubstitutionsService",
    create: function(payload) {
        let url = "/substitutions/";

        return AthWebClient.post(url, payload)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getAllActive(page, perPage) {
        let url =
            "/substitutions/auto/active?page=" +
            (page - 1) +
            "&perPage=" +
            perPage;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    getAllExpired(page, perPage) {
        let url =
            "/substitutions/auto/expired?page=" +
            (page - 1) +
            "&perPage=" +
            perPage;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteSubscription(uuid) {
        let url = "/substitutions/" + uuid;

        return AthWebClient.delete(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
};
