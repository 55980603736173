import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthPrescriptionsService",
    getPrescriptionsAuto: function(page, perPage, filters) {
        // (page - 1) because Spring has 0-based pagination
        let url =
            "/prescriptions/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if (filters.from != null) url += "&startDate=" + filters.from;

        if (filters.end != null) url += "&endDate=" + filters.end;

        if (filters.doctorId != null) url += "&doctorUUID=" + filters.doctorId;

        if (filters.patientId != null)
            url += "&patientUUID=" + filters.patientId;

        if (filters.showOnlyToday != null)
            url += "&showOnlyToday=" + filters.showOnlyToday;

        if (filters.showOnlyCompleted != null)
            url += "&showOnlyCompleted=" + filters.showOnlyCompleted;

        if (filters.showOnlyNotCompleted != null)
            url += "&showOnlyNotCompleted=" + filters.showOnlyNotCompleted;

        if (filters.sortByDate != null)
            url += "&sortByDateDirection=" + filters.sortByDate;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },

    getPrescriptionsDeletedAuto: function(page, perPage, filters) {
        // (page - 1) because Spring has 0-based pagination
        let url =
            "/prescriptions/deleted/auto?page=" +
            (page - 1) +
            "&perPage=" +
            perPage;

        if (filters.from != null) url += "&startDate=" + filters.from;

        if (filters.end != null) {
            url += "&endDate=" + filters.end;
        }

        if (filters.doctorId != null) url += "&doctorUUID=" + filters.doctorId;

        if (filters.patientId != null)
            url += "&patientUUID=" + filters.patientId;

        if (filters.showOnlyToday != null)
            url += "&showOnlyToday=" + filters.showOnlyToday;

        if (filters.showOnlyCompleted != null)
            url += "&showOnlyCompleted=" + filters.showOnlyCompleted;

        if (filters.sortByDate != null)
            url += "&sortByDateDirection=" + filters.sortByDate;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },

    getPrescriptionsForPatient: function(page, perPage, patientUUID) {
        // (page - 1) because spring has 0-based pagination
        let url =
            "/prescriptions/patientUUID/" +
            patientUUID +
            "?page=" +
            (page - 1) +
            "&perPage=" +
            perPage;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    toggleCompleted(prescriptionUUID) {
        let url = "/prescriptions/toggle-completed/" + prescriptionUUID;

        return AthWebClient.put(url)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    },
    setCompleted(uuid, completed) {
        let url = "/prescriptions/set-completed/" + uuid + "/" + completed;

        return AthWebClient.put(url)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    },
    setCloseReason(prescriptionUUID, reason) {
        let url = "/prescriptions/set-close-reason/" + prescriptionUUID;

        return AthWebClient.put(url, {
            closeReason: reason,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    },
    createPrescription(prescription) {
        let url = "/prescriptions/";

        return AthWebClient.post(url, prescription)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteWithReason(uuid, reason) {
        let url = "/prescriptions/delete-with-reason/" + uuid;

        return AthWebClient.delete(url, { data: { deleteReason: reason } })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
};
