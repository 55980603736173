<template>
    <v-container fluid>
        <v-container fluid
                     class="text-h6">
            <span v-if="!loading">
                <v-alert v-if="notification"
                         type="info">{{ notification }}</v-alert>

                <span style="text-decoration: underline 3px rgb(33, 150, 243);"
                      class="text-h5">Riepilogo</span>
                <br />
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Paziente
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Dottore
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Data
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Farmaci
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>{{ (old.tmpPatient ? old.tmpPatient.firstName : '')  + " " + (old.tmpPatient ? old.tmpPatient.lastName : '') }}</b></td>
                                <td><b>{{ (old.doctor ? old.doctor.firstName : '') + " " + (old.doctor ? old.doctor.lastName : '') }}</b></td>
                                <td><b>{{ old.date }}</b></td>
                                <td>
                                    <ath-drugs-for-prescription :prescriptionUUID="prescriptionUUID"></ath-drugs-for-prescription>
                                </td>
                            </tr>
                            <v-card-text class="mt-10">
                                <h4 class="d-flex text-center justify-center">Cosa si desidera modificare?</h4>
                            </v-card-text>
                            <tr>
                                <td>
                                    <v-switch inset
                                              color="info"
                                              label="Paziente"
                                              v-model="edit.patient"></v-switch>
                                </td>
                                <td>
                                    <v-switch inset
                                              color="info"
                                              label="Dottore"
                                              v-if="$store.state.user.loginRole >= 5"
                                              v-model="edit.doctor"></v-switch>
                                </td>
                                <td>
                                    <v-switch inset
                                              color="info"
                                              label="Data"
                                              v-model="edit.date"></v-switch>
                                </td>
                                <td>
                                    <v-switch inset
                                              color="info"
                                              label="Farmaci"
                                              v-model="edit.drugs"></v-switch>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <br />
                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.patient && $store.state.user.loginRole >= 5">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Paziente</div>
                    <br />
                    <ath-select-patient style="min-width: 50%"
                                        :doctorUUID="doctorUUID"
                                        v-on:ath-select-patient="handleSelectPatient($event)"></ath-select-patient>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.doctor">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Dottore</div>
                    <br />
                    <ath-select-doctor :doctors="doctors"
                                       v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.date">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Data</div>
                    <br />
                    <v-date-picker v-model="modified.date"
                                   full-width
                                   class="mt-4"
                                   no-title
                                   color="#2196f3"
                                   first-day-of-week="1"
                                   style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.drugs">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Farmaci</div>
                    <br />
                    <ath-select-drug v-on:ath-select-drug="handleSelectDrug($event)"
                                     :clear-input-toggle="clearDrugSearch"></ath-select-drug>
                    <ath-drugs-list :drugs="modified.drugs"
                                    v-on:ath-remove-drug="handleRemoveDrug($event)"
                                    v-on:ath-decrement-drug="handleDecrement($event)"
                                    v-on:ath-increment-drug="handleIncrement($event)"></ath-drugs-list>
                </v-container>

                <v-container fluid
                             style="padding: 0px">
                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Note</div>
                    <br />
                    <v-textarea prepend-inner-icon="mdi-note"
                                label="Note"
                                rows="3"
                                v-model="modified.notes"
                                outlined></v-textarea>
                </v-container>

                <v-container fluid
                             style="padding: 0px">

                    <div style="text-decoration: underline 3px #ff5252;"
                         class="text-h5">Motivazione</div>
                    <br />
                    <v-text-field label="Motivazione Modifica"
                                  outlined
                                  v-model="modified.editReason"></v-text-field>
                </v-container>

                <br />
                <br />
                <br />
            </span>
            <div>
                <v-btn @click="$emit('ath-cancel-edit-prescription', true)">
                    Annulla
                </v-btn>
                &nbsp;
                <v-btn color="success"
                       @click="editPrescription()"
                       :disabled="!modified.editReason || modified.editReason.length < 3"
                       raised>
                    Conferma Modifiche
                </v-btn>
            </div>
            <v-overlay color="white"
                       absolute
                       :value="loading"
                       light
                       opacity="0.7">
                <v-progress-circular color="info"
                                     indeterminate
                                     size="128">
                </v-progress-circular>
            </v-overlay>
        </v-container>
    </v-container>
</template>

<script>
import moment from "moment";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthWebClient from "../../shared/ath-webclient";
import AthDrugsForPrescription from "../ath-drugs-for-prescription/ath-drugs-for-prescription.vue";
import AthSelectPatient from "../ath-select-patient/ath-select-patient.vue";
import AthSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthSelectDrug from "../ath-select-drug/ath-select-drug.vue";
import AthDrugsList from "../ath-drugs-list/ath-drugs-list.vue";
import AthDrugsService from "../../shared/ath-drugs.service";

export default {
    components: {
        AthDrugsForPrescription,
        AthSelectPatient,
        AthSelectDoctor,
        AthSelectDrug,
        AthDrugsList,
    },
    name: "AthEditPrescription",
    props: ["prescriptionUUID"],
    data: () => ({
        doctors: [],
        clearSelectDoctor: false,
        doctorUUID: null,
        doctor: null,

        loading: false,
        successMessage: false,

        clearDrugSearch: false,

        old: {
            doctorUUID: null,
            doctor: null,
            patientUUID: null,
            drugsJson: null,
            date: null,
            notes: null,
            tmpPatient: {
                firstName: null,
                lastName: null,
            },
        },

        modified: {
            doctorUUID: null,
            doctor: null,
            patientUUID: null,
            drugsJson: null,
            date: null,
            notes: null,
            tmpPatient: {
                firstName: null,
                lastName: null,
            },
            editReason: null,
        },

        edit: {
            patient: false,
            doctor: false,
            date: false,
            drugs: false,
        },

        notification: null,
    }),
    computed: {},
    mounted: function () {
        this.loading = true;
        moment();

        // load doctors
        AthDoctorsService.getDoctorsV2()
            .then((data) => {
                this.doctors = data;

                AthWebClient.get("/prescriptions/" + this.prescriptionUUID)
                    .then((res) => {
                        // console.info(res.data);

                        AthDrugsService.findDrugsForPrescription(
                            this.prescriptionUUID
                        )
                            .then((res2) => {
                                // console.info(res2);
                                // TODO: handle duplicate ids
                                let modified2 = res2.map((it) => {
                                    it.quantity = it.qty;
                                    return it;
                                });

                                this.old.notes = res.data.anotes;

                                this.old.doctorUUID = res.data.uuuid;
                                this.old.patientUUID = res.data.puuid;
                                this.old.drugsJson = res.data.adrugsJson;

                                this.old.drugs = modified2;
                                this.old.date = res.data.adate;

                                this.old.doctor = this.doctors.filter(
                                    (d) => d.uuid == this.old.doctorUUID
                                )[0];

                                this.old.tmpPatient.firstName =
                                    res.data.pfirstName;
                                this.old.tmpPatient.lastName =
                                    res.data.plastName;

                                this.modified = Object.assign({}, this.old);

                                this.loading = false;
                            })
                            .catch((err) => {
                                console.error(err);

                                this.loading = false;
                            });
                    })
                    .catch((err) => {
                        console.info(err);
                        this.loading = false;
                    });
            })
            .catch((err) => {
                err;
                this.loading = false;
                this.doctors = [];
            });
    },
    methods: {
        editPrescription() {
            // check for issues
            if (this.modified.patientUUID == null || !this.edit.patient) {
                this.modified.patientUUID = this.old.patientUUID;
            }

            if (this.modified.doctorUUID == null || !this.edit.doctor) {
                this.modified.doctorUUID = this.old.doctorUUID;
            }

            this.modified.drugsJson = JSON.stringify(
                this.modified.drugs.map((it) => {
                    it.qty = it.quantity;
                    return it;
                })
            );

            AthWebClient.put(
                "/prescriptions/" + this.prescriptionUUID,
                this.modified
            )
                .then((res) => {
                    this.$emit("ath-prescription-edit-success", res);
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la modifica della prescrizione!",
                    });
                });
        },
        handleSelectDoctor(doctorUUID) {
            this.modified.doctorUUID = doctorUUID;
        },
        handleSelectPatient(payload) {
            let parsed = JSON.parse(payload);

            if (parsed == null) {
                this.modified.patientUUID = null;
                this.notification = null;
                return;
            }

            // patient is not generic, has an associated doctor
            if (parsed.pisGeneric == false) {
                if (parsed.uuuid != this.doctorUUID) {
                    this.notification =
                        "Questo paziente non appartiene a questo medico";
                }
                this.modified.patientUUID = parsed.puuid;
            }
            // patient is generic, no doctor associated, show the dropdown
            else {
                this.modified.patientUUID = parsed.puuid;
                this.notification = null;
            }
        },
        handleSelectDrug: function (e) {
            if (e == null) return;

            let parsed = JSON.parse(e);

            for (let i = 0; i < this.modified.drugs.length; i++) {
                if (this.modified.drugs[i].id == parsed.id) {
                    this.modified.drugs[i].quantity =
                        this.modified.drugs[i].quantity + 1;
                    return;
                }
            }

            this.modified.drugs.push({ ...parsed, quantity: 1 });

            this.clearDrugSearch = !this.clearDrugSearch;
        },
        handleDecrement: function (drugId) {
            for (let i = 0; i < this.modified.drugs.length; i++) {
                if (this.modified.drugs[i].id == drugId) {
                    if (this.modified.drugs[i].quantity <= 1) {
                        return;
                    } else {
                        this.modified.drugs[i].quantity =
                            this.modified.drugs[i].quantity - 1;
                    }
                }
            }
        },
        handleIncrement: function (drugId) {
            for (let i = 0; i < this.modified.drugs.length; i++) {
                if (this.modified.drugs[i].id == drugId) {
                    this.modified.drugs[i].quantity =
                        this.modified.drugs[i].quantity + 1;
                }
            }
        },
        handleRemoveDrug: function (drugId) {
            for (let i = 0; i < this.modified.drugs.length; i++) {
                if (this.modified.drugs[i].id == drugId) {
                    this.modified.drugs.splice(i, 1);
                }
            }
        },
    },
};
</script>

<style>
.v-date-picker-table .v-btn {
    font-size: 1rem !important;
}

.v-date-picker-table th {
    font-size: 1rem !important;
}

.v-date-picker-header__value button {
    text-transform: uppercase;
    font-size: 1.1rem !important;
}

.v-picker__body {
    padding-bottom: 1rem;
}

#quick-actions {
    display: flex;
}
@media (min-width: 960px) and (max-width: 1264px) {
    #quick-actions {
        display: flex;
        flex-direction: column;
        align-content: space-around;
    }
}
</style>
