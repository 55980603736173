<template>
    <v-autocomplete
        prepend-inner-icon="mdi-stethoscope"
        outlined
        clearable
        v-on:change="$emit('ath-select-doctor', $event)"
        :items="doctors"
        :item-text="item => item.firstName + ' ' + item.lastName"
        :item-value="item => item.uuid"
        :label="label"
        :hint="hint"
        :persistent-hint="pi"
        v-model="dummy"
    ></v-autocomplete>
</template>

<script>
export default {
    name: "AthSelectDoctor",
    props: {
        doctors: {
            type: Array,
            required: true,
        },
        itemText: {
            type: Function,
            required: false,
        },
        itemValue: {
            type: Function,
            required: false,
        },
        clearInputToggle: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "Ricerca Dottore"
        },
        hint: {
            type: String,
            required: false,
            default: null
        },
        pi: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: () => ({
        dummy: null,
    }),
    watch: {
        clearInputToggle: function(ignore) {
            ignore;
            this.dummy = null;
            this.$emit('ath-select-doctor', this.dummy);
        }
    }
};
</script>