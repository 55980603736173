<template>
    <div class="text-center"
         style="width: 100%;">
        <span v-if="this.online == true"
              class="success--text text-h6 text-center">
            <v-icon style="vertical-align: middle;">mdi-check</v-icon> App ONLINE
        </span>
        <span v-if="this.online == false"
              class="error--text text-h6 text-center">
            <v-icon style="vertical-align: middle;">mdi-close</v-icon> App OFFLINE
        </span>
        <br />
        <br />
        <div style="font-size: 1rem">
            <span class="black--text text-h6 text-center">Versione 
                <span style="text-decoration: underline 3px solid #2196f3">{{ backendVersion }}</span> 
                + <span style="text-decoration: underline 3px solid #2196f3">{{ frontendVersion }}</span> </span>
        </div>
    </div>
</template>

<script>
import athWebClient from "../../shared/ath-webclient";
import hash from "../../assets/hash.json";

export default {
    name: "ath-backend-status",
    data() {
        return {
            online: false,
            backendVersion: "X",
            frontendVersion: hash.commit,
        };
    },
    mounted() {
        athWebClient
            .get("/backend/health")
            .then((response) => {
                response;
                this.online = true;
            })
            .catch((e) => {
                e;
                this.online = false;
            });

        athWebClient
            .get("/backend/version")
            .then((response2) => {
                this.backendVersion = response2.data;
            })
            .catch((e) => {
                e;
                this.online = false;
            });
    },
};
</script>