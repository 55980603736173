import Vue from "vue";
import Vuex from "vuex";
import athWebClient from "../shared/ath-webclient.js";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            loginToken: null,
            loginRole: null,
            loginName: null,
        },
        ui: {
            drawer: {
                open: true,
            },
            alert: {
                visible: false,
                text: "",
                icon: "",
                color: "",
            },
            chat: {
                open: false,
                refreshInterval: [],
                unreads: 0,
            },
        },
    },
    mutations: {
        "store.load.saved": function(state) {
            let st = localStorage.getItem("serializedStore");

            // get rid of unused variable "state"
            state;

            if (st) {
                let parsedSt = JSON.parse(st);

                if (!parsedSt.ui.chat) {
                    parsedSt.ui.chat = {
                        open: false,
                        refreshInterval: [],
                        unreads: 0,
                    };
                }

                Object.assign(state, parsedSt);
            }
        },
        "user.login": function(state, payload) {
            // console.info(payload);
            state.user.loggedIn = true;
            state.user.loginToken = payload.loginToken;
            state.user.loginRole = payload.loginRole;
            state.user.loginId = payload.loginId;
            state.user.loginEmail = payload.loginEmail;
            state.user.loginName = payload.loginName;
            state.user.preferredIntervalTimeMins =
                payload.preferredIntervalTimeMins;
            athWebClient.defaults.headers.common["Authorization"] =
                "Bearer " + store.state.user.loginToken;
            saveState();
        },
        "user.logout": function(state) {
            state.user.loggedIn = false;
            state.user.loginToken = null;
            state.user.loginRole = null;
            state.user.loginName = null;
            state.user.loginId = null;
            state.user.loginEmail = null;
            state.user.loginName = null;
            state.user.preferredIntervalTimeMins = null;

            for (let i = 0; i < state.ui.chat.refreshInterval.length; i++) {
                clearInterval(state.ui.chat.refreshInterval[i]);
            }

            state.ui.chat = {
                open: false,
                refreshInterval: [],
                unreads: 0,
            };

            // saveState();
            localStorage.clear();
        },
        "ui.alert.show": function(state, payload) {
            state.ui.alert.visible = true;
            state.ui.alert.text = payload.text;
            state.ui.alert.icon = payload.icon;
            state.ui.alert.color = payload.color;
        },
        "ui.alert.hide": function(state) {
            state.ui.alert.visible = false;
            state.ui.alert.text = "";
            state.ui.alert.icon = "";
            state.ui.alert.color = "";
        },
        "ui.chat.toggle": function(state) {
            state.ui.chat.open = !state.ui.chat.open;
        },
        "ui.chat.set.refreshInterval": function(state, payload) {
            state.ui.chat.refreshInterval.push(payload);
        },
        "ui.chat.set.unreads": function(state, payload) {
            state.ui.chat.unreads = payload;
        },
    },
});

function saveState() {
    localStorage.setItem("serializedStore", JSON.stringify(store.state));
}

export default store;
