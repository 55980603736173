import AthWebClient from "./ath-webclient";

export default {
    name: "AthRepresentativesService",
    getByUUID: function(uuid) {
        let url = "/representatives/" + uuid;

        return AthWebClient
            .get(url)
            .then((res) => {
                return res.data;
            });
    },
    getRepresentativesAuto: function(page, perPage, filters) {
        filters;

        let url =
            "/representatives/search/paged/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if (filters.search) {
            url += "&search=" + filters.search;
        }

        if (filters.companyUUID) {
            url += "&companyUUID=" + filters.companyUUID;
        }

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    // getRepresentativesAuto: function(page, perPage, filters) {
    //     filters;

    //     let url =
    //         "/representatives/auto?page=" + (page - 1) + "&perPage=" + perPage;

    //     if (filters.search) {
    //         url += "&search=" + filters.search;
    //     }

    //     return AthWebClient.get(url)
    //         .then((res) => {
    //             return {
    //                 data: res.data.content,
    //                 total: res.data.totalElements,
    //             };
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    // },
    search: function(firstLastName) {
        return AthWebClient.get(
            "/representatives/search/" + firstLastName
        ).then((res) => {
            return res.data;
        });
        //.catch(err => console.error(err))
    },
};
