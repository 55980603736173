<template>
    <v-row>
        <!-- <v-col cols="12" sm="10" :md="modal ? 12 : 10" :offset-md="modal ? 0 : 1"> -->
        <v-col cols="12"
               sm="10"
               offset-sm="1">
            <v-container fluid>
                <v-card :elevation="modal ? 0 : 2">
                    <v-card-title class="text-h4"
                                  style="justify-content: center">Crea Paziente</v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-card :elevation="modal ? 0 : 2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Nome"
                                              prepend-inner-icon="mdi-account"
                                              outlined
                                              v-model="patient.firstName">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Cognome"
                                              prepend-inner-icon="mdi-account-outline"
                                              outlined
                                              v-model="patient.lastName">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Data di Nascita"
                                              prepend-inner-icon="mdi-calendar"
                                              outlined
                                              v-model="patient.birthdate">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Indirizzo"
                                              prepend-inner-icon="mdi-map-marker"
                                              outlined
                                              v-model="patient.address">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Email"
                                              prepend-inner-icon="mdi-email"
                                              outlined
                                              v-model="patient.email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Telefono"
                                              prepend-inner-icon="mdi-phone"
                                              outlined
                                              v-model="patient.phone">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-textarea outlined
                                            prepend-inner-icon="mdi-note"
                                            label="Note"
                                            rows="2"
                                            v-model="patient.notes"></v-textarea>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-checkbox v-model="isGeneric"
                                            label="Paziente Generico?"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="doctorFilterEnabled && !isGeneric">
                            <v-col cols="12"
                                   md="12">
                                <ath-select-doctor :doctors="doctors"
                                                   v-on:ath-select-doctor="
                                    handleSelectDoctor($event)
                                "></ath-select-doctor>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            ">
                                <v-btn color="info"
                                       @click="create()"
                                       raised>
                                    Salva
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthPatientsService from "../../shared/ath-patients.service";
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";

export default {
    components: { athSelectDoctor },
    name: "AthCreatePatient",
    props: ["modal"],
    watch: {
        isGeneric: {
            handler: function (ig) {
                // not generic and the one creating the patient is a doctor
                if (ig == false && this.doctorFilterEnabled == false) {
                    this.doctorUUID = this.$store.state.user.loginId;
                }
            },
        },
    },
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.doctors = [];
                this.doctorFilterEnabled = false;

                // cannot load doctors, set his uuid (because he is a doctor)
                this.doctorUUID = this.$store.state.user.loginId;
            });
    },
    methods: {
        create() {
            if(this.isGeneric) {
                this.doctorUUID = null;
            }

            AthPatientsService.create({
                ...this.patient,
                doctorUUID: this.doctorUUID,
            })
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Paziente creato con successo",
                    })
                    .then((r) => {
                        r;
                        this.$emit('ath-create-patient-success', r);
                    })
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione del paziente",
                    });
                });
        },
        handleSelectDoctor(doctorUUID) {
            this.doctorUUID = doctorUUID;
        },
    },
    data: () => ({
        patient: {},
        doctors: [],
        doctorUUID: null,
        doctorFilterEnabled: false,
        isGeneric: false,
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
