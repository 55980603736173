<template>
    <v-container>
        <v-card>
            <v-card-title>Storico Sostituzioni</v-card-title>
            <v-card-text>
                <ath-substitutions :get-active="false"></ath-substitutions>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import AthSubstitutions from "../components/ath-substitutions/ath-substitutions.vue";
export default {
    name: "AthSubstitutionsHistoryView",
    components: { AthSubstitutions },
};
</script>