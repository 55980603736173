import Vue from 'vue'
import VueRouter from 'vue-router'
import AthDashboardView from '../views/ath-dashboard-view.vue'
import AthAppointments from '../components/ath-appointments/ath-appointments.vue';
import AthPrescriptions from '../components/ath-prescriptions/ath-prescriptions.vue';
import AthCreateAppointment from '../components/ath-create-event/ath-create-appointment/ath-create-appointment.vue';
import AthCreatePrescription from '../components/ath-create-event/ath-create-prescription/ath-create-prescription.vue';
import AthLogin from '../components/ath-login/ath-login.vue';
import AthPatients from '../components/ath-patients/ath-patients.vue';
import AthCreatePatient from '../components/ath-create-patient/ath-create-patient.vue';
import AthCreateDrug from '../components/ath-create-drug/ath-create-drug.vue';
import AthDrugs from '../components/ath-drugs/ath-drugs.vue';
import AthRepresentatives from '../components/ath-representatives/ath-representatives.vue';
import AthPhCompanies from '../components/ath-ph-companies/ath-ph-companies.vue';
import AthCreateRepresentative from '../components/ath-create-representative/ath-create-representative.vue';
import AthCreatePhCompany from '../components/ath-create-ph-company/ath-create-ph-company.vue';
import AthCreateSubstitution from '../components/ath-create-substitution/ath-create-substitution.vue';
import AthCreateCalendarBlock from '../components/ath-create-calendar-block/ath-create-calendar-block.vue';
import AthSubsitutionsView from '../views/ath-substitutions.view.vue';
import AthSubsitutionsHistoryView from '../views/ath-substitutions-history.view.vue';
import AthCalendarBlocksView from '../views/ath-calendar-blocks.view.vue';
import AthCreateLocationMedataView from '../views/ath-create-location-metadata.view.vue';
import AthAdminCreateUserView from '../views/admin/ath-create-user.view.vue';
import AthRecoverUsers from '../components/ath-recover-users/ath-recover-users.vue';
import AthUsers from '../components/ath-users/ath-users.vue';
import AthDeletedAppointments from '../components/ath-deleted-appointments/ath-deleted-appointments.vue';
import AthDeletedPrescriptions from '../components/ath-deleted-prescriptions/ath-deleted-prescriptions.vue';
import AthCreateNotification from '../components/ath-create-notification/ath-create-notification.vue';
import AthEditProfile from '../views/ath-edit-profile.view.vue';
import AthCreateBugReport from "../components/ath-create-bug-report/ath-create-bug-report.vue";
import AthManageFeatureToggles from "../components/ath-manage-feature-toggles/ath-manage-feature-toggles.vue";
import AthChat from "../components/ath-chat/ath-chat.vue";
import AthAdminDashboard from '../views/admin/ath-admin-dashboard.view.vue';
import AthCreateExam from '../components/ath-create-exam/ath-create-exam.vue';

import AthChatFullpage from "../views/ath-chat-fullpage.view.vue";

import store from '../store/store';

Vue.use(VueRouter)

const routes = [
    /**
     * ALL-USERS PATHS
     */
    {
        path: '/login',
        name: 'Login',
        component: AthLogin
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: AthDashboardView
    },
    {
        path: '/appointments',
        name: 'Appointments',
        component: AthAppointments
    },
    {
        path: '/prescriptions',
        name: 'Prescriptions',
        component: AthPrescriptions
    },
    {
        path: '/create-appointment',
        name: 'CreateAppointment',
        component: AthCreateAppointment
    },
    {
        path: '/create-prescription',
        name: 'CreatePrescription',
        component: AthCreatePrescription
    },
    {
        path: '/patients',
        name: 'Patients',
        component: AthPatients
    },
    {
        path: '/create-patient',
        name: 'CreatePatient',
        component: AthCreatePatient
    },
    {
        path: '/create-drug',
        name: 'CreateDrug',
        component: AthCreateDrug
    },
    {
        path: '/drugs',
        name: 'Drugs',
        component: AthDrugs
    },
    {
        path: '/representatives',
        name: 'Representatives',
        component: AthRepresentatives
    },
    {
        path: '/ph-companies',
        name: 'Companies',
        component: AthPhCompanies
    },
    {
        path: '/create-representative',
        name: 'CreateRepresentative',
        component: AthCreateRepresentative
    },
    {
        path: '/create-ph-company',
        name: 'CreatePhCompany',
        component: AthCreatePhCompany
    },
    {
        path: '/create-substitution',
        name: 'CreateSubstitution',
        component: AthCreateSubstitution
    },
    {
        path: '/create-calendar-block',
        name: 'CreateCalendarBlock',
        component: AthCreateCalendarBlock
    },
    {
        path: '/substitutions',
        name: 'Subsitutions',
        component: AthSubsitutionsView
    },
    {
        path: '/substitutions-history',
        name: 'SubsitutionsHistory',
        component: AthSubsitutionsHistoryView
    },
    {
        path: '/calendar-blocks',
        name: 'CalendarBlocks',
        component: AthCalendarBlocksView
    },
    {
        path: '/create/location-metadata',
        name: 'CreateLocationMetadata',
        component: AthCreateLocationMedataView
    },
    {
        path: '/edit/my/profile',
        name: 'EditMyProfile',
        component: AthEditProfile
    },
    {
        path: '/create/bug-report',
        name: 'CreateBugReport',
        component: AthCreateBugReport
    },
    {
        path: '/view/my/chats',
        name: 'ViewMyChats',
        component: AthChat
    },
    {
        path: '/testing/chat-fullpage',
        name: 'AthChatFullpage',
        component: AthChatFullpage  
    },
    {
        path: '/create-exam',
        name: 'AthCreateExam',
        component: AthCreateExam  
    },
    /**
     * ADMIN PATHS
     */
    {
        path: '/admin/create/user',
        name: 'AdminCreateUser',
        component: AthAdminCreateUserView
    },
    {
        path: '/admin/recover/users',
        name: 'AdminRecoverUsers',
        component: AthRecoverUsers
    },
    {
        path: '/admin/list/users',
        name: 'AdminListUsers',
        component: AthUsers
    },
    {
        path: '/admin/list/appointments/deleted',
        name: 'AdminListDeletedAppointments',
        component: AthDeletedAppointments
    },
    {
        path: '/admin/list/prescriptions/deleted',
        name: 'AdminListDeletedPrescriptions',
        component: AthDeletedPrescriptions
    },
    {
        path: '/admin/create/notification',
        name: 'AdminCreateNotification',
        component: AthCreateNotification
    },
    {
        path: '/admin/manage/2ad5623197671570/feature-toggles',
        name: 'AdminAthManageFeatureToggles',
        component: AthManageFeatureToggles
    },
    {
        path: '/admin/dashboard',
        name: 'AthAdminDashboard',
        component: AthAdminDashboard
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    { path: '*', redirect: '/dashboard' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(to.fullPath == from.fullPath) next(false);
    else if(to.fullPath != '/login' && !store.state.user.loggedIn)
    {
        let st = localStorage.getItem('serializedStore');

        if(!st)
        {
            next('/login');

            return;
        }

        let parsedSt = JSON.parse(st);

        if(parsedSt.user.loggedIn) next();
        else next('/login');
    }
    else next();
})

export default router
