var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.substitutions,"items-per-page":_vm.perPage,"server-items-length":_vm.substitutionsTotal,"options":_vm.options,"page":_vm.page,"footer-props":{
                        'items-per-page-options': [5, 10, 15, 25, 50],
                        'show-current-page': true,
                        'show-first-last-page': true,
                    },"disable-filtering":"","disable-sort":"","hide-default-header":"","mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(heads){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((heads.props.headers),function(h){return _c('th',{key:h.text,staticClass:"text-start",staticStyle:{"font-size":"1rem","color":"rgba(0, 0, 0, 0.87)"},attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" "+_vm._s(h.text)+" ")])}),0)])]}},{key:"item.leavingDoctor",fn:function(ref){
                    var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.leavingDoctor.firstName)+" "+_vm._s(item.leavingDoctor.lastName))])]}},{key:"item.substituteDoctor",fn:function(ref){
                    var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.substituteDoctor.firstName)+" "+_vm._s(item.substituteDoctor.lastName))])]}},{key:"item.startDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_c('v-btn',{attrs:{"small":"","plain":""},on:{"click":function($event){return _vm.del(item.uuid)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v("Elimina")],1)],1)],1)],1)],1)]}}],null,true)}),_c('v-overlay',{attrs:{"color":"white","absolute":"","value":_vm.loading,"light":"","opacity":"0.7"}},[_c('v-progress-circular',{attrs:{"color":"info","indeterminate":"","size":"128"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }