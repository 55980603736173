<template>
    <v-container fluid
                 style="padding: 1rem;">
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Nome"
                              prepend-inner-icon="mdi-account"
                              readonly
                              outlined
                              :value="patient.firstName">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Cognome"
                              prepend-inner-icon="mdi-account"
                              readonly
                              outlined
                              :value="patient.lastName">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Data di Nascita"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              :value="getBirthdate(patient.birthdate)">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Indirizzo"
                              prepend-inner-icon="mdi-map-marker"
                              readonly
                              outlined
                              :value="patient.address">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Email"
                              prepend-inner-icon="mdi-email"
                              readonly
                              outlined
                              :value="patient.email">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Telefono"
                              prepend-inner-icon="mdi-phone"
                              readonly
                              outlined
                              :value="patient.phone">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-textarea outlined
                            readonly
                            prepend-inner-icon="mdi-note"
                            label="Note"
                            rows="2"
                            :value="patient.notes"></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-text-field label="Medico Curante"
                              readonly
                              outlined
                              prepend-inner-icon="mdi-stethoscope"
                              :value="(doctor.firstName || 'PAZIENTE') + ' ' + (doctor.lastName || 'GENERICO')"
                              background-color="rgba(113,124,141,0.14)">
                </v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthPatientsService from "../../shared/ath-patients.service";
// import moment from "moment";

export default {
    name: "AthViewPatient",
    props: {
        patientUUID: {
            type: String,
            required: false,
        },
    },
    mounted() {
        if (this.patientUUID == null || this.patientUUID == undefined) return;

        AthPatientsService.getPatientByUUID(this.patientUUID)
            .then((res) => {
                this.patient = res;

                AthPatientsService.getDoctorByPatientUUID(this.patientUUID)
                    .then((res) => {
                        this.doctor = res;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
        //.finally(() => (this.isLoading = false));
    },
    methods: {
        getBirthdate(d) {
            // let md = moment(d, "YYYY-MM-DD");

            // if (md.isValid) return md.format("DD/MM/YYYY");
            // else return md;
            return d;
        },
    },
    watch: {
        patientUUID: function (p) {
            if (p == null || p == undefined) return;

            AthPatientsService.getPatientByUUID(p)
                .then((res) => {
                    this.patient = res;
                })
                .catch((err) => {
                    console.log(err);
                });

            AthPatientsService.getDoctorByPatientUUID(p)
                .then((res) => {
                    this.doctor = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    data: () => ({
        patient: {},
        doctor: {},
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
