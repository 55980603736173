import AthWebClient from './ath-webclient';

export default {
    name: 'AthDoctorsService',
    getDoctors: function () {
        return AthWebClient
            .get('/users/doctors')
            .then(res => {
                return res.data;    
            })
            //.catch(err => console.error(err))
    },
    getDoctorsV2: function () {
        return AthWebClient
            .get('/users/doctors/v2')
            .then(res => {
                return res.data;    
            })
            //.catch(err => console.error(err))
    },
    getDoctorStartEndTime: function(doctorUUID) {
        return AthWebClient
            .get('/users/' + doctorUUID + '/startEndTime')
            .then(res => {
                return res.data;    
            })
    },
    getDoctorsOccupiedTimeSlot: function(doctorUUID, day)
    {
        return AthWebClient
            .get('/appointments/occupied/day/' + day + '/doctor/' + doctorUUID + "/v2")
            .then(res => {
                return res.data;
            })
    }
}