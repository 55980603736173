<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Appuntamento creato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               xs="12"
               sm="10"
               offset-sm="1">
            <v-container fluid>
                <v-card hover>
                    <v-card-title class="text-h4 text-center"
                                  style="justify-content: center; margin-top: 2rem; color: #253c99">
                        Nuovo Appuntamento
                    </v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-sheet elevation="2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text ma-2"><span>Ricerca Paziente o Informatore</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   sm="4"
                                   md="3"
                                   style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;">
                                <!--                            <span>Tipologia</span>-->
                                <v-radio-group v-model="radioGroup">
                                    <v-radio color="info"
                                             :label="'Paziente'"
                                             :value="'patient'"></v-radio>
                                    <v-radio color="success"
                                             :label="'Informatore'"
                                             :value="'representative'"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12"
                                   sm="8"
                                   md="9"
                                   class="mt-5">
                                <!--                            <span style="padding-bottom: 1rem"-->
                                <!--                                  v-if="radioGroup == 'patient'">Seleziona il Paziente</span>-->
                                <!--                            <span style="padding-bottom: 1rem"-->
                                <!--                                  v-if="radioGroup == 'representative'">Seleziona l'Informatore</span>-->
                                <ath-select-patient style="min-width: 50%"
                                                    v-if="radioGroup == 'patient'"
                                                    :doctorUUID="doctorUUID"
                                                    :toggleClear="toggleClear"
                                                    v-on:ath-select-patient="handleSelectPatient($event)"></ath-select-patient>

                                <ath-select-representative style="min-width: 50%"
                                                           v-if="radioGroup == 'representative'"
                                                           :toggleClear="toggleClear"
                                                           v-on:ath-select-representative="handleSelectRepresentative($event)"></ath-select-representative>

                                <ath-select-doctor :doctors="doctors"
                                                   v-if="viewSelectDoctor || radioGroup == 'representative'"
                                                   v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>

                            </v-col>
                        </v-row>

                        <!--                  QUICK ACTIONS IF PATIENTS-->

                        <v-row id="quick-actions"
                               v-if="radioGroup == 'patient'">
                            <v-col class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click="createDialog = true;">Crea Nuovo Paziente</v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click="editDialog = true;"
                                       :disabled="patientUUID == null">
                                    Modifica Paziente
                                </v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click.stop="viewPatientModal = true"
                                       :disabled="patientUUID == null">Dettagli Paziente
                                </v-btn>
                                <v-dialog v-model="viewPatientModal"
                                          max-width="800">
                                    <v-card>
                                        <v-card-title class="text-h5"
                                                      style="justify-content: center;">
                                            Dettagli Paziente
                                        </v-card-title>

                                        <ath-view-patient v-if="patientUUID"
                                                          :patientUUID="patientUUID"></ath-view-patient>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error darken-1"
                                                   @click="viewPatientModal = false">
                                                Chiudi
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>

                        <!--                  QUICK ACTION IF REPRESENTATIVE-->

                        <v-row id="quick-actions"
                               v-if="radioGroup == 'representative'">
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="success"
                                       @click="createRepresentativeModal = true;"
                                       outlined>Crea Nuovo Informatore</v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="success"
                                       outlined>Modifica Informatore</v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="success"
                                       outlined
                                       @click.stop="viewRepresentativeModal = true"
                                       :disabled="representativeUUID == null">Dettagli Informatore</v-btn>
                                <v-dialog v-model="viewRepresentativeModal"
                                          max-width="800">
                                    <v-card>
                                        <v-card-title class="text-h5"
                                                      style="justify-content: center;">
                                            Dettagli Informatore
                                        </v-card-title>

                                        <!-- <ath-view-patient :patientUUID="patientUUID"></ath-view-patient> -->
                                        <ath-view-representative :representativeUUID="representativeUUID"></ath-view-representative>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error darken-1"
                                                   @click="viewRepresentativeModal = false">
                                                Chiudi
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="createRepresentativeModal"
                                          max-width="960">
                                    <v-card>
                                        <v-card-title style="justify-content: end"><span>
                                                <v-icon large
                                                        @click="createRepresentativeModal = false;">mdi-close</v-icon>
                                            </span></v-card-title>
                                        <v-card-text>
                                            <ath-create-representative v-on:ath-create-representative-success="createRepresentativeModal = false;"
                                                                       :modal="true"></ath-create-representative>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>

                        <v-row class="mt-xl-10">
                            <v-col cols="12"
                                   sm="6">
                                <v-col cols="12"
                                       class="text-h5 text-center info--text"><span>Data Appuntamento</span>
                                    <v-divider></v-divider>
                                </v-col>

                                <v-col cols="12"
                                       style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            ">
                                    <v-date-picker v-model="appointmentDate"
                                                   full-width
                                                   :allowed-dates="allowedDates"
                                                   class="mt-4"
                                                   no-title
                                                   color="#2196f3"
                                                   first-day-of-week="1"
                                                   style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>
                                    <br />
                                    <ath-check-active-substitutions :doctorUUID="doctorUUID"
                                                                    v-on:ath-appointment-substitute-doctor="handleSubstituteDoctor($event)"
                                                                    :day="appointmentDate"></ath-check-active-substitutions>
                                </v-col>
                            </v-col>
                            <v-col cols="12"
                                   sm="6">
                                <v-col cols="12"
                                       class="text-h5 text-center info--text"><span>Appuntamento Ricorrente?</span>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12"
                                       style="
                                display: flex;
                                flex-direction: column;
                            ">
                                    <v-switch v-model="recurringAppointment"
                                              :label="recurringAppointment ? 'SI' : 'NO'"
                                              append-icon="mdi-help-circle"
                                              @click:append.stop="helpDialog = true"
                                              inset
                                              color="info"></v-switch>
                                    <div v-if="recurringAppointment">
                                        <v-row>
                                            <v-col cols="12"
                                                   sm="6">
                                                <v-text-field prepend-inner-icon="mdi-repeat"
                                                              outlined
                                                              type="number"
                                                              v-model="howmany"
                                                              label="Numero sessioni"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="6">
                                                <v-text-field outlined
                                                              prepend-inner-icon="mdi-calendar-refresh"
                                                              label="Distanza"
                                                              type="number"
                                                              v-model="quantity"></v-text-field>
                                            </v-col>
                                            <v-col cols="12"
                                                   sm="12"
                                                   md="6">
                                                <v-select :items="[{text: 'Giorni', value: 'days'}, {text: 'Settimana', value: 'weeks'}]"
                                                          v-model="unit"
                                                          prepend-inner-icon="mdi-calendar"
                                                          label="Unità"
                                                          outlined></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-flex justify-center align-center">
                                            <v-card cols="12"
                                                    sm="12"
                                                    color="blue lighten-5">
                                                <v-card-text>
                                                    Verranno creati in totale <b>{{ +howmany + 1 }}</b> appuntamenti a distanza di <b>{{ parseInt(quantity) }} {{ getName(parseInt(quantity), unit) }}</b> l'uno dall'altro
                                                </v-card-text>
                                            </v-card>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row class="mt-xl-10"
                               v-if="weekdaysAllocation">
                            <v-col cols="12"
                                   sm="12">
                                <v-col cols="12"
                                       class="text-h5 text-center info--text"><span>Orari Ambulatori</span>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12"
                                       style="display: flex; flex-direction: column;">
                                    <ath-view-location-metadata :weekdaysAllocation="weekdaysAllocation"
                                                                :highlightday="highlightday"></ath-view-location-metadata>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text"><span>Orari Disponibili</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            ">
                                <ath-timeslots-component v-if="timeslotLoaded"
                                                         :day="appointmentDate"
                                                         :timeDivision="doctorPreferredIntervalTime"
                                                         :occupiedSlots="doctorOccupiedTimeSlots"
                                                         :startingTime="doctorStartTime"
                                                         :endingTime="doctorEndTime"
                                                         v-on:ath-select-time="handleSelectTime($event)"
                                                         v-on:ath-show-occupied-slot="showAppointmentInfo($event)"></ath-timeslots-component>
                                <v-sheet v-if="!timeslotLoaded"
                                         style="justify-content: center;">
                                    <v-icon style="width: 100%"
                                            size="150">mdi-timer-sand-empty</v-icon>
                                    <p class="text-h6">
                                        Seleziona un dottore per vedere gli orari
                                    </p>
                                </v-sheet>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   xl="6"
                                   class="text-h5 text-center info--text"><span>Note</span>
                                <v-divider class="pb-5"></v-divider>
                                <v-textarea full-width
                                            outlined
                                            prepend-inner-icon="mdi-note"
                                            clearable
                                            label="Note"
                                            no-resize
                                            v-model="appointmentNotes">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12"
                                   xl="6"><span class="text-h5 text-center info--text">Riepilogo Appuntamento</span>
                                <v-divider class="pb-5"></v-divider>
                                <v-card flat
                                        class="pl-3 pt-2 pb-1">
                                    <p>
                                        Data appuntamento:
                                        <b style="font-size: 1.2rem;">{{
                                    formatDate(appointmentDate)
                                }}</b>
                                    </p>
                                    <p>
                                        Orario appuntamento:
                                        <b style="font-size: 1.2rem;">{{
                                    appointmentTime
                                }}</b>
                                    </p>
                                    <!-- <p>
                                        <ath-view-patient-reduced v-if="patientUUID != null"
                                                                  :patientUUID="patientUUID"></ath-view-patient-reduced>
                                    </p> -->
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mb-5">
                            <v-col cols="12"
                                   sm="12"
                                   style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    ">
                                <v-btn color="info"
                                       @click="createAppointment()"
                                       raised>
                                    Conferma Appuntamento
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
            </v-container>
        </v-col>
        <v-dialog v-model="helpDialog"
                  max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Appuntamento Ricorrente</span>
                </v-card-title>
                <br />
                <v-card-text>
                    <p class="black--text text-body-1">
                        La funzionalità di appuntamento ricorrente consente di
                        creare una serie di appuntamenti tra dottore e
                        paziente/informatore ad intervalli regolari e ripetuti. E' necessario inserire il numero di appuntamenti da creare e l'intervallo tra i vari appuntamenti.

                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error"
                           text
                           @click="helpDialog = false">
                        Chiudi
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createDialog"
                  max-width="960px">
            <v-card>
                <v-card-title style="justify-content: end"><span>
                        <v-icon large
                                @click="createDialog = false;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-create-patient v-on:ath-create-patient-success="createDialog = false;"
                                        :modal="true"></ath-create-patient>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog"
                  persistent
                  max-width="960px">
            <v-card>
                <v-card-title class="text-h4"
                              style="justify-content: space-between;">Modifica Paziente<span>
                        <v-icon large
                                @click="editDialog = false;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-edit-patient v-on:ath-edit-patient-success="editDialog = false; toggleClear = !toggleClear; patientUUID = null;"
                                      :patientUUID="patientUUID"
                                      v-on:ath-edit-patient-cancel="editDialog = false;"></ath-edit-patient>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="recurringAppointmentPreviewDialog"
                  persistent
                  width="960">

            <v-card v-if="recurringAppointment && !lastDialog">
                <v-card-title>
                    Riepilogo appuntamenti:
                </v-card-title>

                <v-card-text>
                    <v-list dense>
                        <v-list-item-group color="info">
                            <v-list-item v-for="(item, i) in recurringDates"
                                         :key="i">
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">
                                        <b>{{ item }}</b> &nbsp;
                                        <v-progress-circular indeterminate
                                                             color="primary"
                                                             v-if="availableDates[item] == null"></v-progress-circular>
                                        <span v-if="availableDates[item] && availableDates[item].available == true">
                                            <v-icon color="success">mdi-check-bold</v-icon> (Orario Disponibile)
                                        </span>
                                        <span v-if="availableDates[item] && availableDates[item].available == false">
                                            <v-icon color="error">mdi-close-thick</v-icon> (Orario Non Disponibile)
                                        </span>
                                        <v-expansion-panels v-if="availableDates[item] && availableDates[item].available == false">
                                            <v-expansion-panel>
                                                <v-expansion-panel-header color="blue lighten-5">
                                                    <h3>Appuntamento Esistente</h3>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content color="blue lighten-5">
                                                    Dottore: {{ availableDates[item].appointment.ufirstName + " " + availableDates[item].appointment.ulastName }}
                                                    <br />
                                                    <span v-if="availableDates[item].appointment.atype == 'WITH_PATIENT'"> <br />
                                                        Paziente: {{ availableDates[item].appointment.pfirstName + " " + availableDates[item].appointment.plastName }}
                                                    </span>
                                                    <span v-if="availableDates[item].appointment.atype != 'WITH_PATIENT'"> <br />
                                                        Informatore {{ availableDates[item].appointment.rfirstName + " " + availableDates[item].appointment.rlastName }}
                                                    </span>
                                                    <span> <br /> <br />
                                                        Note: {{ availableDates[item].appointment.anotes }}
                                                    </span>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider></v-divider>
                    <br />
                    <v-card>
                        <h3 class="text-center">
                            In caso di conflitto con un altro appuntamento esistente, è possibile scegliere tra: </h3>
                        <v-card-text class="text-center">
                            <!--                    <ol>
                        <li><span style="color: #FF5252"> Annullare</span> l'operazione in corso per modificare giorno/ora in modo da evitare conflitti con appuntamenti già esistenti</li>
                        <li><span style="color: #4CAF50">Confermare</span> gli appuntamenti <b>solo negli slot disponibili</b>, ignorando gli appuntamenti in conflitto (da fissare manualmente). </li>
                    </ol>-->

                            <h4><span style="color: #FF5252"> Annullare</span> l'operazione in corso per modificare giorno/ora in modo da evitare conflitti con appuntamenti già esistenti</h4>
                            <h4><span style="color: #4CAF50">Confermare</span> gli appuntamenti <b>solo negli slot disponibili</b>, ignorando gli appuntamenti in conflitto (da fissare manualmente). </h4>

                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="error"
                           @click="recurringAppointmentPreviewDialog = false">
                        Annulla
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success"
                           @click="createRecurringAppointment()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="lastDialog">
                <v-card-title class="justify-center">
                    <h2 class="pt-5">Appuntamenti Confermati.</h2>
                </v-card-title>
                <v-card-text style="color: black; font-size: 1rem; padding-top: 1rem;">
                    Appuntamenti confermati con successo!

                    Cosa si desidera fare adesso?

                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn color="primary"
                           @click="insertNewAppointment();">Nuovo Appuntamento</v-btn>
                    &nbsp;
                    <v-btn color="success"
                           @click="goToDashboard();">Dashboard</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="!recurringAppointment">
                <v-card-title class="justify-center">
                    <h2 class="pt-5">Appuntamento Confermato.</h2>
                </v-card-title>
                <v-card-text style="color: black; font-size: 1rem; padding-top: 1rem;">
                    Appuntamento confermato con successo!

                    Cosa si desidera fare adesso?

                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn color="primary"
                           @click="insertNewAppointment();">Nuovo Appuntamento</v-btn>
                    &nbsp;
                    <v-btn color="success"
                           @click="goToDashboard();">Dashboard</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import moment from "moment";
import AthDoctorsService from "../../../shared/ath-doctors.service";
import AthSelectPatient from "../../ath-select-patient/ath-select-patient.vue";
import AthSelectRepresentative from "../../ath-select-representative/ath-select-representative.vue";
import AthViewPatient from "../../ath-view-patient/ath-view-patient.vue";
import AthTimeslotsComponent from "../../ath-timeslots-component/ath-timeslots-component.vue";
// import AthViewPatientReduced from "../../ath-view-patient/ath-view-patient-reduced.vue";
import AthSelectDoctor from "../../ath-select-doctor/ath-select-doctor.vue";
import athAppointmentsService from "../../ath-appointments/ath-appointments.service";
import AthCreatePatient from "../../ath-create-patient/ath-create-patient.vue";
import AthEditPatient from "../../ath-edit-patient/ath-edit-patient.vue";
import AthViewLocationMetadata from "../../ath-view-location-metadata/ath-view-location-metadata.vue";
import AthWebClient from "../../../shared/ath-webclient";
import AthViewRepresentative from "../../ath-view-representative/ath-view-representative.vue";
import AthCreateRepresentative from "../../ath-create-representative/ath-create-representative.vue";
import AthCheckActiveSubstitutions from "../../ath-check-active-substitutions/ath-check-active-substitutions.vue";

export default {
    components: {
        AthSelectPatient,
        AthSelectRepresentative,
        AthViewPatient,
        AthTimeslotsComponent,
        // AthViewPatientReduced,
        AthSelectDoctor,
        AthCreatePatient,
        AthEditPatient,
        AthViewLocationMetadata,
        AthViewRepresentative,
        AthCreateRepresentative,
        AthCheckActiveSubstitutions,
    },
    name: "AthCreateAppointment",
    data: () => ({
        doctors: [],
        clearSelectDoctor: false,
        doctorUUID: null,
        doctorStartTime: null,
        doctorEndTime: null,
        doctorPreferredIntervalTime: null,
        doctorOccupiedTimeSlots: [],

        viewRepresentativeModal: false,

        // default value
        radioGroup: "patient",

        // deafult to today
        appointmentDate: moment().format("YYYY-MM-DD"),

        // TODO: add code for the closest default
        appointmentTime: "00:00",

        // this will come from the ath-select-patient component
        patientUUID: null,

        // this will come from the ath-select-representative component
        representativeUUID: null,

        // controls whether the view patient modal is open
        viewPatientModal: false,

        appointmentNotes: null,

        // show appointment info on the side panel
        dialog: false,

        // controls whether to show the select doctor component
        viewSelectDoctor: false,

        // success snackbar
        successMessage: false,

        // is it a recurring appointment?
        recurringAppointment: false,

        // show recurring appointment help dialog
        helpDialog: false,

        // if true, show timeslot component, otherwise show loader
        timeslotLoaded: false,

        // create patient dialog
        createDialog: false,

        // edit patient dialog
        editDialog: false,

        // advanced options for the recurring appointment
        advancedOptions: false,

        // interval duration
        quantity: 1,

        // unit of time : Giorno | Settimana
        unit: { text: "Settimana", value: "weeks" },

        howmany: 1,

        // dialog for the preview list with dates for the recurring appointments
        recurringAppointmentPreviewDialog: false,

        weekdaysAllocation: null,

        recurringDates: [],

        // hash with date as key and boolean true/false as value to indicate if date is available and null as starting value
        availableDates: {},

        toggleClear: false,

        lastDialog: false,

        createRepresentativeModal: false,

        blockedDays: [],

        substituteDoctorUUID: null,

        effectiveDoctorUUID: null,
    }),
    computed: {
        highlightday: {
            get() {
                let engDay = moment(this.appointmentDate).format("dddd");

                let itDay = "";

                if (engDay == "Sunday") itDay = "domenica";
                if (engDay == "Saturday") itDay = "sabato";
                if (engDay == "Friday") itDay = "venerdi";
                if (engDay == "Thursday") itDay = "giovedi";
                if (engDay == "Wednesday") itDay = "mercoledi";
                if (engDay == "Tuesday") itDay = "martedi";
                if (engDay == "Monday") itDay = "lunedi";

                return itDay;
            },
            set() {},
        },
    },
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctorsV2()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled =
                    this.$store.state.user.loginRole >= 5;
            })
            .catch((err) => {
                err;
                this.loading = false;
                this.doctors = [];
            });
    },
    watch: {
        recurringAppointmentPreviewDialog: function (t) {
            this.effectiveDoctorUUID = this.substituteDoctorUUID
                ? this.substituteDoctorUUID
                : this.doctorUUID;

            if (t == false || this.doctorUUID == null) return;
            for (let i = 0; i < this.recurringDates.length; i++) {
                AthWebClient.get(
                    "/appointments/check-for-date/" +
                        this.recurringDates[i] +
                        "/doctorUUID/" +
                        this.effectiveDoctorUUID
                )
                    .then((data) => {
                        let key = this.recurringDates[i];
                        this.availableDates[key] = {};
                        this.availableDates[key].available =
                            data.data.slotAvailable;
                        this.availableDates[key].appointment =
                            data.data.appointmentEgress;
                        this.$forceUpdate();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        },
        appointmentDate: function (d) {
            // reset appointment time
            this.appointmentTime = "00:00";
            d;
            this.updateTimeSlots("appointmentDate");
            this.calculateRecurringDates();
            this.updateBlockedDays();
        },
        appointmentTime: function (d) {
            d;
            this.calculateRecurringDates();
        },
        quantity: function () {
            this.calculateRecurringDates();
        },
        howmany: function () {
            this.calculateRecurringDates();
        },
        unit: function () {
            this.calculateRecurringDates();
        },
        recurringAppointment: function () {
            this.calculateRecurringDates();
        },
        doctorUUID: function (d) {
            if (d == null) {
                this.weekdaysAllocation = null;
                return;
            }
            this.updateTimeSlots("doctorUUID");
            this.getMetadataForDoctor(d);
            this.updateBlockedDays();
        },
        patientUUID: function (p) {
            if (p == null) return;
            this.updateTimeSlots("patientUUID");
        },
        representativeUUID: function (r) {
            if (r == null) return;
            this.updateTimeSlots("representativeUUID");
        },
        substituteDoctorUUID: function (d) {
            d;
            // already handled in handleSubstituteDoctor
            // if (d == null) {
            //     this.weekdaysAllocation = null;
            //     return;
            // }
            //this.updateTimeSlots("substituteDoctorUUID");
            //this.getMetadataForDoctor(d);
            //this.updateBlockedDays();
        },
    },
    methods: {
        allowedDates: function (val) {
            return !this.blockedDays.includes(val);
        },
        goToDashboard: function () {
            this.recurringAppointmentPreviewDialog = false;
            this.$router.push(
                "/",
                () => {},
                () => {}
            );
        },
        insertNewAppointment: function () {
            this.clearSelectDoctor = false;
            this.doctorUUID = null;
            this.doctorStartTime = null;
            this.doctorEndTime = null;
            this.doctorPreferredIntervalTime = null;
            this.doctorOccupiedTimeSlots = [];

            // default value
            this.radioGroup = "patient";

            // deafult to today
            this.appointmentDate = moment().format("YYYY-MM-DD");

            this.appointmentTime = "00:00"; // TODO: add code for the closes = default;

            // this will come from the ath-select-patient component
            this.patientUUID = null;

            // controls whether the view patient modal is open
            this.viewPatientModal = false;

            this.appointmentNotes = null;

            // show appointment info on the side panel
            this.dialog = false;

            // controls whether to show the select doctor component
            this.viewSelectDoctor = false;

            // success snackbar
            this.successMessage = false;

            // is it a recurring appointment?
            this.recurringAppointment = false;

            // show recurring appointment help dialog
            this.helpDialog = false;

            // if true, show timeslot component, otherwise show loader
            this.timeslotLoaded = false;

            // create patient dialog
            this.createDialog = false;

            // edit patient dialog
            this.editDialog = false;

            // advanced options for the recurring appointment
            this.advancedOptions = false;

            // interval duration
            this.quantity = 1;

            // unit of time : Giorno  = Settiman;
            this.unit = { text: "Settimana", value: "weeks" };

            this.howmany = 1;

            // dialog for the preview list with dates for the recurring appointments
            this.recurringAppointmentPreviewDialog = false;

            this.weekdaysAllocation = null;

            this.recurringDates = [];

            // hash with date as key and boolean true/false as value to indicate if date is available and null as starting value
            this.availableDates = {};

            this.toggleClear = !this.toggleClear;

            this.lastDialog = false;

            this.blockedDays = [];

            this.substituteDoctorUUID = null;

            this.effectiveDoctorUUID = null;
        },
        calculateRecurringDates: function () {
            if (!this.recurringAppointment) return;
            let st = moment(this.appointmentDate)
                .set("hour", this.appointmentTime.split(":")[0])
                .set("minute", this.appointmentTime.split(":")[1]);

            let dt = [];
            this.availableDates = {};

            dt.push(st.format("DD-MM-YYYY HH:mm"));

            for (let i = 0; i < this.howmany; i++) {
                let cl = st.clone();
                // console.info({st: st.format("DD-MM-YYYY HH:mm"), cl: cl.format("DD-MM-YYYY HH:mm")});
                cl.add(this.quantity * (i + 1), this.unit.value || this.unit);
                dt.push(cl.format("DD-MM-YYYY HH:mm"));
                this.availableDates[cl.format("DD-MM-YYYY HH:mm")] = null;
            }

            this.recurringDates = dt;
        },
        getMetadataForDoctor: function (d) {
            AthWebClient.get("/general/user-metadata/" + d)
                .then((data) => {
                    this.weekdaysAllocation = JSON.parse(
                        data.data.weekdaysAllocation
                    );
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore caricamento orari ambulatori",
                    });
                });
        },
        updateTimeSlots: function (str) {
            //console.log(str);
            str; // used to log the invoking callsite
            if (this.doctorUUID == null) return;

            this.timeslotLoaded = true;

            this.effectiveDoctorUUID = this.substituteDoctorUUID
                ? this.substituteDoctorUUID
                : this.doctorUUID;

            // console.info("updateTimeSlots: " + str + " :" , this.effectiveDoctorUUID, this.substituteDoctorUUID, this.doctorUUID);

            AthDoctorsService.getDoctorStartEndTime(this.effectiveDoctorUUID)
                .then((data) => {
                    this.doctorStartTime = data.startTime;
                    this.doctorEndTime = data.endTime;

                    AthDoctorsService.getDoctorsOccupiedTimeSlot(this.effectiveDoctorUUID, this.appointmentDate)
                        .then((data2) => {
                            for (let i = 0; i < data2.length; i++) {
                                if (data2[i].date != data2[i].endDate) {
                                    // deal with the case when the appointment falls on a already-blocked date
                                    if (this.appointmentDate == data2[i].date) 
                                    {
                                        data2[i].endTime = "23:00";
                                    } 
                                    else if (this.appointmentDate == data2[i].endDate)
                                    {
                                        data2[i].startTime = "01:00";
                                    }
                                }
                            }

                            this.doctorOccupiedTimeSlots = data2;
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        updateBlockedDays: function () {
            if (this.appointmentDate == null || this.doctorUUID == null) return;

            this.effectiveDoctorUUID = this.substituteDoctorUUID
                ? this.substituteDoctorUUID
                : this.doctorUUID;

            AthWebClient.get(
                "/appointments/blocked/days/month/" +
                    (moment(this.appointmentDate).month() + 1) +
                    "/doctor/" +
                    this.effectiveDoctorUUID
            )
                .then((res) => {
                    let arr = res.data;

                    for (let i = 0; i < arr.length; i++) {
                        var now = moment(arr[i].startDate)
                            .clone()
                            .add(1, "days");

                        while (now.isBefore(arr[i].endDate)) {
                            this.blockedDays.push(now.format("YYYY-MM-DD"));
                            now.add(1, "days");
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        formatDate: function (d) {
            return moment(d).format("DD/MM/YYYY");
        },
        getItemText: function (item) {
            return item.firstLastName;
        },
        getItemValue: function (item) {
            return item.id;
        },
        handleSelectDoctor(doctorUUID) {
            this.doctorUUID = doctorUUID;

            if (doctorUUID != null) {
                this.doctorPreferredIntervalTime = this.doctors.filter(
                    (d) => d.uuid == doctorUUID
                )[0].preferredIntervalTimeMins;
            }
        },
        // this hack is needed because of the issue with primitive return type of function item-value
        // check ath-select-patient component for more details
        handleSelectPatient(payload) {
            let parsed = JSON.parse(payload);

            if (parsed == null) {
                this.patientUUID = null;
                this.doctorUUID = null;
                this.doctorPreferredIntervalTime = null;

                return;
            }

            // patient is not generic, has an associated doctor
            if (parsed.pisGeneric == false) {
                this.viewSelectDoctor = false;
                this.patientUUID = parsed.puuid;
                this.doctorUUID = parsed.uuuid;
                this.doctorPreferredIntervalTime = this.doctors.filter(
                    (d) => d.uuid == this.doctorUUID
                )[0].preferredIntervalTimeMins;
                //parsed.doctorPreferredIntervalTime;

                // AthDoctorsService.getDoctorStartEndTime(this.doctorUUID)
                //     .then((data) => {
                //         this.doctorStartTime = data.startTime;
                //         this.doctorEndTime = data.endTime;
                //     })
                //     .catch((err) => {
                //         console.error(err);
                //     });

                // AthDoctorsService.getDoctorsOccupiedTimeSlot(
                //     this.doctorUUID,
                //     this.appointmentDate
                // )
                //     .then((data) => {
                //         this.doctorOccupiedTimeSlots = data;
                //     })
                //     .catch((err) => {
                //         console.error(err);
                //     });
            }
            // patient is generic, no doctor associated, show the dropdown
            else {
                // admin or secretary
                if (this.$store.state.user.loginRole >= 5) {
                    this.viewSelectDoctor = true;
                    this.patientUUID = parsed.puuid;
                } else {
                    this.doctorUUID = this.$store.state.user.loginId;
                    this.viewSelectDoctor = false;
                    this.patientUUID = parsed.puuid;
                    this.doctorPreferredIntervalTime = this.$store.state.user.preferredIntervalTimeMins;
                }
            }
        },
        handleSelectRepresentative(e) {
            let parsed = JSON.parse(e);

            if (parsed == null) {
                this.representativeUUID = null;

                return;
            }

            this.representativeUUID = JSON.parse(e).uuid;
        },
        handleSelectTime(time) {
            this.appointmentTime = time;
            this.dialog = false;
        },
        showAppointmentInfo(e) {
            e;
            this.dialog = true;
        },
        createRecurringAppointment() {
            let appointment = {
                doctorUUID: null,
                patientUUID: null,
                representativeUUID: null,
                startDatetimeUtc: null,
                endDatetimeUtc: null,
                notes: null,
            };

            if (this.appointmentTime == null || this.appointmentTime == "") {
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Orario selezionato non valido!",
                });
                return;
            }

            if (this.radioGroup == "patient" && this.patientUUID != null) {
                appointment.patientUUID = this.patientUUID;
                appointment.doctorUUID = this.substituteDoctorUUID
                    ? this.substituteDoctorUUID
                    : this.doctorUUID;
            } else if (
                this.radioGroup == "representative" &&
                this.representativeUUID != null
            ) {
                appointment.representativeUUID = this.representativeUUID;
                appointment.doctorUUID = this.substituteDoctorUUID
                    ? this.substituteDoctorUUID
                    : this.doctorUUID;
            }

            appointment.notes = this.appointmentNotes;

            let promises = [];
            for (let i = 0; i < this.recurringDates.length; i++) {
                let idx = this.recurringDates[i];

                if (this.availableDates[idx].available == true) {
                    let stTime = moment(idx, "DD-MM-YYYY HH:mm");

                    let endTime = stTime.clone();

                    endTime = endTime.add(
                        this.doctorPreferredIntervalTime,
                        "minutes"
                    );

                    appointment.startDatetimeUtc = stTime.format(
                        "YYYY-MM-DD[T]HH:mm:ssZ"
                    );
                    appointment.endDatetimeUtc = endTime.format(
                        "YYYY-MM-DD[T]HH:mm:ssZ"
                    );

                    appointment.startDateTimeAsFrontendNoTimezone = stTime.format("YYYY-MM-DD[T]HH:mm:ss");
                    appointment.endDateTimeAsFrontendNoTimezone = endTime.format("YYYY-MM-DD[T]HH:mm:ss");

                    promises.push(athAppointmentsService.create(appointment));
                }
            }

            Promise.all(promises)
                .then((res) => {
                    res;
                    this.successMessage = true;
                    // this.recurringAppointmentPreviewDialog = false;
                    this.lastDialog = true;
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Slot Occupato! Si prega di cambiare data/ora",
                    });
                });
        },
        createAppointment() {
            let appointment = {
                doctorUUID: null,
                patientUUID: null,
                representativeUUID: null,
                startDatetimeUtc: null,
                endDatetimeUtc: null,
                notes: null,
            };

            if (this.appointmentTime == null || this.appointmentTime == "") {
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Orario selezionato non valido!",
                });
                return;
            }

            if (this.radioGroup == "patient" && this.patientUUID != null) {
                appointment.patientUUID = this.patientUUID;
                appointment.doctorUUID = this.substituteDoctorUUID
                    ? this.substituteDoctorUUID
                    : this.doctorUUID;
            } else if (
                this.radioGroup == "representative" &&
                this.representativeUUID != null
            ) {
                appointment.representativeUUID = this.representativeUUID;
                appointment.doctorUUID = this.substituteDoctorUUID
                    ? this.substituteDoctorUUID
                    : this.doctorUUID;
            }

            appointment.notes = this.appointmentNotes;

            let startDateTime = moment(this.appointmentDate)
                .set("hour", this.appointmentTime.split(":")[0])
                .set("minute", this.appointmentTime.split(":")[1]);

            let endDateTime = startDateTime.clone();

            endDateTime = endDateTime.add(
                this.doctorPreferredIntervalTime,
                "minutes"
            );

            appointment.startDateTimeAsFrontendNoTimezone = startDateTime.format("YYYY-MM-DD[T]HH:mm:ss");
            appointment.endDateTimeAsFrontendNoTimezone = endDateTime.format("YYYY-MM-DD[T]HH:mm:ss");

            appointment.startDatetimeUtc = startDateTime.format(
                "YYYY-MM-DD[T]HH:mm:ssZ"
            );
            appointment.endDatetimeUtc = endDateTime.format(
                "YYYY-MM-DD[T]HH:mm:ssZ"
            );

            if (this.recurringAppointment) {
                this.recurringAppointmentPreviewDialog = true;
                return;
            } else {
                // console.info(appointment);
                athAppointmentsService
                    .create(appointment)
                    .then((res) => {
                        res;
                        this.recurringAppointmentPreviewDialog = true;
                        this.successMessage = true;
                    })
                    .catch((err) => {
                        err;
                        this.successMessage = false;
                        this.recurringAppointmentPreviewDialog = false;
                        this.$swal({
                            icon: "error",
                            title: "Errore",
                            text: "Slot Occupato! Si prega di cambiare data/ora",
                        });
                    });
            }
        },
        getName(q, u) {
            if (u.text) return u.text;
            else if (u == "days") return q > 1 ? "Giorni" : "Giorno";
            else if (u == "weeks") return q > 1 ? "Settmane" : "Settimana";
        },
        handleSubstituteDoctor(doc) {
            if (doc == null) this.substituteDoctorUUID = null;
            else this.substituteDoctorUUID = doc.uuid;

            this.updateTimeSlots("handleSubstituteDoctor");
            this.calculateRecurringDates();
            this.updateBlockedDays();
        },
    },
};
</script>

<style>
.v-date-picker-table .v-btn {
    font-size: 1rem !important;
}

.v-date-picker-table th {
    font-size: 1rem !important;
}

.v-date-picker-header__value button {
    text-transform: uppercase;
    font-size: 1.1rem !important;
}

.v-picker__body {
    padding-bottom: 1rem;
}

td > button.v-btn--disabled {
    background-color: rgba(255, 0, 0, 0.5);
}

td > button.v-btn--disabled > div {
    color: white;
}

#quick-actions {
    display: flex;
}
@media (min-width: 960px) and (max-width: 1264px) {
    #quick-actions {
        display: flex;
        flex-direction: column;
        align-content: space-around;
    }
}
</style>
