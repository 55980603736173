<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center"> Informatori</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card>
                    <v-card-title>Filtri e Ricerca</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   md="6">
                                <v-text-field prepend-inner-icon="mdi-account"
                                              outlined
                                              clearable
                                              v-model="filters.search"
                                              label="Nome o Cognome"></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="6">
                                <v-select prepend-inner-icon="mdi-domain"
                                          outlined
                                          label="Azienda Farmaceutica"
                                          :items="phCompanies"
                                          :item-text="(item) => item.name"
                                          v-model="filters.companyUUID"
                                          item-value="uuid"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions style="display: flex;
                                align-content: flex-start;
                                justify-content: flex-end;
                                align-items: center;">
                        <v-btn large
                               @click="filter()"
                               color="info"
                               raised>Filtra</v-btn>
                        <v-btn large
                               @click="reset()"
                               color="error"
                               raised>Reset Filtri</v-btn>
                    </v-card-actions>
                </v-card>
                <br />
                <v-card>
                    <v-data-table :headers="headers"
                                  :items="representatives"
                                  :items-per-page="perPage"
                                  :server-items-length="representativesTotal"
                                  :options.sync="options"
                                  :page="page"
                                  :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50],
                                        'show-current-page': true,
                                        'show-first-last-page': true,
                                  }"
                                  class="elevation-1"
                                  disable-filtering
                                  disable-sort
                                  hide-default-header
                                  mobile-breakpoint="100">
                        <!-- make headers bold -->
                        <template v-slot:header="heads">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th role="columnheader"
                                        scope="col"
                                        v-for="h in heads.props.headers"
                                        :key="h.text"
                                        style="
                                    font-size: 0.9rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                        class="text-start">
                                        {{ h.text }}
                                    </th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu bottom
                                    left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info"
                                           outlined
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>mdi-menu</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn plain
                                                   @click="repToEdit = item; editDialog = true;">Modifica</v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-overlay color="white"
                               absolute
                               :value="loading"
                               light
                               opacity="0.7">
                        <v-progress-circular color="info"
                                             indeterminate
                                             size="128"></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog v-model="editDialog"
                  persistent
                  max-width="960px">
            <v-card>
                <v-card-title class="text-h4"
                              style="justify-content: space-between;">&nbsp;<span>
                        <v-icon large
                                @click="editDialog = false;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-edit-representative v-on:ath-edit-representative-success="editDialog = false; repToEdit = null; filter();"
                                      :repToEdit="repToEdit"
                                      :modal="true"
                                      v-on:ath-edit-representative-cancel="editDialog = false;"></ath-edit-representative>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AthRepresentativesService from "../../shared/ath-representatives.service";
import AthWebClient from "../../shared/ath-webclient";
import athEditRepresentative from '../ath-edit-representative/ath-edit-representative.vue';


export default {
    name: "AthRepresentatives",
    components: {athEditRepresentative},
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate({
                    page: opts.page,
                    perPage: opts.itemsPerPage,
                });
            },
            deep: true,
        },
    },
    mounted() {
        this.loading = true;

        AthWebClient.get("/phcompanies/all")
            .then((res) => {
                this.phCompanies = res.data;
            })
            .catch((err) => {
                console.error(err);
            });

        // load representatives
        AthRepresentativesService.getRepresentativesAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.representatives = data.data;
                this.representativesTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
    },
    methods: {
        alert(e) {
            alert(e);
        },
        reset: function () {
            this.filters = {
                search: null,
                companyUUID: null,
            };

            this.filter();
        },
        filter: function () {
            this.handlePaginationUpdate({
                page: 1,
                perPage: 10,
            });
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthRepresentativesService.getRepresentativesAuto(
                this.page,
                this.perPage,
                this.filters
            )
                .then((data) => {
                    this.representatives = data.data;
                    this.representativesTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
    },
    data: () => ({
        // data for the representatives table
        representatives: [],
        representativesTotal: 0,

        phCompanies: [],

        // headers for the appointments table
        headers: [
            { text: "Nome", value: "firstName" },
            { text: "Cognome", value: "lastName" },
            { text: "Azienda", value: "phCompany.name" },
            { text: "Indirizzo", value: "address" },
            { text: "Telefono", value: "phone" },
            { text: "Email", value: "email" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            search: null,
            companyUUID: null,
        },

        editDialog: false,

        repToEdit: null,

        options: {},
    }),
};
</script>
