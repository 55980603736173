<template>
    <v-data-table :headers="headers"
                  :items="drugs"
                  disable-sort
                  disable-pagination
                  :hide-default-footer="true"
                  class="elevation-1">
        <template v-slot:[`item.quantity`]="{ item }">
            {{ item.qty }}
        </template>
    </v-data-table>
</template>

<script>
import AthDrugsService from "../../shared/ath-drugs.service";

export default {
    name: "AthDrugsForPrescription",
    props: ["prescriptionUUID"],
    watch: {
        prescriptionUUID: {
            handler: function (p) {
                this.getDrugs(p);
            },
        },
    },
    created() {
        AthDrugsService.findDrugsForPrescription(this.prescriptionUUID)
            .then((res) => {
                this.drugs = res;
                this.$forceUpdate();
            })
            .catch((err) => {
                console.error(err);
            });
    },
    methods: {
        getDrugs(prescriptionUUID) {
            AthDrugsService.findDrugsForPrescription(prescriptionUUID)
                .then((res) => {
                    this.drugs = res;
                    this.$forceUpdate();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
    data: () => ({
        drugs: [],
        headers: [
            { text: "Nome", value: "name" },
            { text: "Principio Attivo", value: "activeSubstance" },
            { text: "Qtà", value: "quantity" },
            // { text: "Azioni", value: "actions" },
        ],
    }),
};
</script>