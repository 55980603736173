<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Prescrizione creata con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               xs="12"
               sm="10"
               offset-sm="1">
            <v-container fluid>
                <v-card hover>
                    <v-card-title class="text-h4 text-center"
                                  style="justify-content: center;">
                        Nuova Prescrizione
                    </v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-sheet elevation="2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text mt-2"><span>Ricerca Paziente</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   xl="12">
                                <ath-select-patient style="min-width: 50%"
                                                    :doctorUUID="doctorUUID"
                                                    :toggleClear="toggleClear"
                                                    v-on:ath-select-patient="handleSelectPatient($event)">
                                </ath-select-patient>
                                <ath-select-doctor :doctors="doctors"
                                                   v-if="viewSelectDoctor"
                                                   v-on:ath-select-doctor="handleSelectDoctor($event)">
                                </ath-select-doctor>
                            </v-col>
                        </v-row>
                        <v-row id="quick-actions">
                            <v-col class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click="createDialog = true;">Crea Nuovo Paziente</v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="4"
                                   class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click="editDialog = true;"
                                       :disabled="patientUUID == null">
                                    Modifica Paziente
                                </v-btn>
                            </v-col>
                            <v-col cols="12"
                                   xl="3"
                                   class="justify-center d-flex">
                                <v-btn color="info"
                                       outlined
                                       @click.stop="viewPatientModal = true"
                                       :disabled="patientUUID == null">Dettagli Paziente</v-btn>
                                <v-dialog v-model="viewPatientModal"
                                          max-width="800">
                                    <v-card>
                                        <v-card-title class="text-h5"
                                                      style="justify-content: center;">
                                            Dettagli Paziente
                                        </v-card-title>

                                        <ath-view-patient v-if="patientUUID"
                                                          :patientUUID="patientUUID"></ath-view-patient>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error darken-1"
                                                   @click="viewPatientModal = false">
                                                Chiudi
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12"
                                   xl="3"
                                   class="justify-center d-flex">
                                <v-btn :disabled="patientUUID == null"
                                       color="cyan darken-1"
                                       class="white--text"
                                       @click.stop="reusePrescriptionModal = true"
                                       raised>Riutilizza Prescrizione</v-btn>
                                <v-dialog v-model="reusePrescriptionModal"
                                          max-width="1600">
                                    <v-card>

                                        <ath-reuse-prescription :patientUUID="patientUUID"
                                                                v-on:ath-reuse-prescription-ev="setAsModel($event)"></ath-reuse-prescription>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error darken-1"
                                                   @click="reusePrescriptionModal = false">
                                                Chiudi
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text"><span>Data Prescrizione</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    ">
                                <v-date-picker v-model="prescriptiontDate"
                                               full-width
                                               class="mt-4"
                                               no-title
                                               color="primary darken-2"
                                               first-day-of-week="1"
                                               style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>

                                <br />
                                <ath-check-active-substitutions :doctorUUID="doctorUUID"
                                                                v-on:ath-appointment-substitute-doctor="handleSubstituteDoctor($event)"
                                                                :day="prescriptiontDate"></ath-check-active-substitutions>
                                <span style="margin-top: 1rem">La data selezionata &egrave;
                                    <span class="text-h6">{{
                                    formatDate(prescriptiontDate)
                                }}</span></span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                        display: flex;
                                        flex-direction: column;
                                    ">
                                <ath-select-drug v-on:ath-select-drug="handleSelectDrug($event)"
                                                 :clear-input-toggle="clearDrugSearch"></ath-select-drug>
                                <ath-drugs-list :drugs="drugs"
                                                v-on:ath-remove-drug="handleRemoveDrug($event)"
                                                v-on:ath-decrement-drug="handleDecrement($event)"
                                                v-on:ath-increment-drug="handleIncrement($event)"></ath-drugs-list>
                            </v-col>

                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text"><span> Note</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   sm="12">
                                <v-textarea prepend-inner-icon="mdi-note"
                                            full-width
                                            outlined
                                            clearable
                                            label="Note"
                                            v-model="prescriptiontNotes"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="mb-5">
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    ">
                                <v-btn @click="confirm()"
                                       color="primary"
                                       raised>
                                    Conferma Prescrizione
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
            </v-container>
        </v-col>
        <v-dialog max-width="600"
                  persistent
                  v-model="confirmationDialog">
            <v-card>
                <v-card-title class="justify-center">
                    <h2 class="pt-5">Prescrizione Confermata.</h2>
                </v-card-title>
                <v-card-text style="color: black; font-size: 1rem; padding-top: 1rem;">
                    Prescrizione confermata con successo!

                    Cosa si desidera fare adesso?

                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <v-btn color="primary"
                           @click="insertNewPrescription();">Nuova Prescrizione</v-btn>
                    &nbsp;
                    <v-btn color="success"
                           @click="goToDashboard();">Dashboard</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createDialog"
                  max-width="960px">
            <v-card>
                <v-card-title style="justify-content: end"><span>
                        <v-icon large
                                @click="createDialog = false;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-create-patient v-on:ath-create-patient-success="createDialog = false;"
                                        :modal="true"></ath-create-patient>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog"
                  persistent
                  max-width="960px">
            <v-card>
                <v-card-title class="text-h4"
                              style="justify-content: space-between;">Modifica Paziente<span>
                        <v-icon large
                                @click="editDialog = false; toggleClear = !toggleClear; patientUUID = null;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-edit-patient v-on:ath-edit-patient-success="editDialog = false;"
                                      :patientUUID="patientUUID"></ath-edit-patient>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import moment from "moment";

import AthDoctorsService from "../../../shared/ath-doctors.service";
import AthSelectPatient from "../../ath-select-patient/ath-select-patient.vue";
import AthViewPatient from "../../ath-view-patient/ath-view-patient.vue";
import AthSelectDoctor from "../../ath-select-doctor/ath-select-doctor.vue";
import AthSelectDrug from "../../ath-select-drug/ath-select-drug.vue";
import AthDrugsList from "../../ath-drugs-list/ath-drugs-list.vue";
import AthPrescriptionService from "../../ath-prescriptions/ath-prescriptions.service";
import AthReusePrescription from "../../ath-reuse-prescription/ath-reuse-prescription.vue";
import AthDrugsService from "../../../shared/ath-drugs.service";
import AthCreatePatient from "../../ath-create-patient/ath-create-patient.vue";
import AthEditPatient from "../../ath-edit-patient/ath-edit-patient.vue";
import AthCheckActiveSubstitutions from "../../ath-check-active-substitutions/ath-check-active-substitutions.vue";
import AthWebClient from "../../../shared/ath-webclient";

export default {
    name: "AthCreatePrescription",
    components: {
        AthSelectPatient,
        AthViewPatient,
        AthSelectDoctor,
        AthSelectDrug,
        AthDrugsList,
        AthReusePrescription,
        AthCreatePatient,
        AthEditPatient,
        AthCheckActiveSubstitutions,
    },
    watch: {
        visitSearch(val) {
            if (!val || val.length < 3) {
                console.info(val);

                return;
            }

            AthWebClient.get("/general/visits/" + encodeURIComponent(val))
                .then((res) => {
                    this.visits = res.data;
                })
                .catch((err) => {
                    //this.visits = [];
                    console.log(err);
                });

            return true;
        },
    },
    data: () => ({
        doctors: [],
        clearSelectDoctor: false,
        doctorUUID: null,

        // deafult to today
        prescriptiontDate: moment().format("YYYY-MM-DD"),

        // this will come from the ath-select-patient component
        patientUUID: null,

        // controls whether the view patient modal is open
        viewPatientModal: false,

        prescriptiontNotes: null,

        // controls whether to show the select doctor component
        viewSelectDoctor: false,

        // success snackbar
        successMessage: false,

        drugs: [],

        clearDrugSearch: false,

        confirmationDialog: false,

        toggleClear: false,

        reusePrescriptionModal: false,

        tempPres: null,

        // create patient dialog
        createDialog: false,

        // edit patient dialog
        editDialog: false,

        substituteDoctorUUID: null,

        enableVisits: false,

        visitSearch: null,
        visits: [],
        dummy2: null,
    }),
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        handleSelectVisit: function ($event) {
            return $event;
        },
        setAsModel: function (e) {
            AthDrugsService.findDrugsForPrescription(e.auuid)
                .then((res) => {
                    // TODO: handle duplicate ids
                    let modified = res.map((it) => {
                        it.quantity = it.qty;
                        return it;
                    });
                    this.drugs = this.drugs.concat(modified);
                    this.$forceUpdate();

                    this.reusePrescriptionModal = false;
                })
                .catch((err) => {
                    console.error(err);

                    this.reusePrescriptionModal = false;
                });
        },
        goToDashboard: function () {
            this.confirmationDialog = false;
            this.$router.push(
                "/",
                () => {},
                () => {}
            );
        },
        alert: function (e) {
            console.info(e);
        },
        insertNewPrescription: function () {
            this.confirmationDialog = false;

            this.clearSelectDoctor = !this.clearSelectDoctor;
            this.clearDrugSearch = !this.clearDrugSearch;

            // deafult to today
            this.prescriptiontDate = moment().format("YYYY-MM-DD");

            // this will come from the ath-select-patient component
            this.patientUUID = null;

            // controls whether the view patient modal is open
            this.viewPatientModal = false;

            this.prescriptiontNotes = null;

            // success snackbar
            this.successMessage = false;

            this.viewSelectDoctor = false;

            this.drugs = [];

            this.toggleClear = !this.toggleClear;
        },

        confirm: function () {
            this.createPrescription().then(() => {
                this.confirmationDialog = true;
            });
        },

        handleSelectDrug: function (e) {
            if (e == null) return;

            let parsed = JSON.parse(e);

            for (let i = 0; i < this.drugs.length; i++) {
                if (this.drugs[i].id == parsed.id) {
                    this.drugs[i].quantity = this.drugs[i].quantity + 1;
                    return;
                }
            }

            this.drugs.push({ ...parsed, quantity: 1 });

            this.clearDrugSearch = !this.clearDrugSearch;
        },
        handleDecrement: function (drugId) {
            for (let i = 0; i < this.drugs.length; i++) {
                if (this.drugs[i].id == drugId) {
                    if (this.drugs[i].quantity <= 1) {
                        return;
                    } else {
                        this.drugs[i].quantity = this.drugs[i].quantity - 1;
                    }
                }
            }
        },
        handleIncrement: function (drugId) {
            for (let i = 0; i < this.drugs.length; i++) {
                if (this.drugs[i].id == drugId) {
                    this.drugs[i].quantity = this.drugs[i].quantity + 1;
                }
            }
        },
        handleRemoveDrug: function (drugId) {
            for (let i = 0; i < this.drugs.length; i++) {
                if (this.drugs[i].id == drugId) {
                    this.drugs.splice(i, 1);
                }
            }
        },
        formatDate: function (d) {
            return moment(d).format("DD/MM/YYYY");
        },

        handleSelectDoctor(doctorUUID) {
            this.doctorUUID = doctorUUID;
        },

        // this hack is needed because of the issue with primitive return type of function item-value
        // check ath-select-patient component for more details
        handleSelectPatient(payload) {
            let parsed = JSON.parse(payload);

            if (parsed == null) {
                this.patientUUID = null;
                this.doctorUUID = null;
                this.doctorPreferredIntervalTime = null;

                return;
            }

            // TODO: Hack
            this.doctorPreferredIntervalTime = 15;

            // patient is not generic, has an associated doctor
            if (parsed.pisGeneric == false) {
                this.viewSelectDoctor = false;
                this.patientUUID = parsed.puuid;
                this.doctorUUID = parsed.uuuid;
                //parsed.doctorPreferredIntervalTime;
            }
            // patient is generic, no doctor associated, show the dropdown
            else {
                // admin or secretary
                if (this.$store.state.user.loginRole >= 5) {
                    this.viewSelectDoctor = true;
                    this.patientUUID = parsed.puuid;
                } else {
                    this.doctorUUID = this.$store.state.user.loginId;
                    this.viewSelectDoctor = false;
                    this.patientUUID = parsed.puuid;
                    this.doctorPreferredIntervalTime =
                        this.$store.state.user.preferredIntervalTimeMins;
                }
            }
        },

        createPrescription() {
            let dt = moment(this.prescriptiontDate).format("YYYY-MM-DD");

            let effectiveDoctorUUID = this.substituteDoctorUUID
                ? this.substituteDoctorUUID
                : this.doctorUUID;

            let prescription = {
                doctorUUID: effectiveDoctorUUID,
                patientUUID: this.patientUUID,
                drugsIdList: this.drugs.map((d) => d.id).join(","),
                // drugsJson: JSON.stringify(
                //     JSON.stringify(
                //         this.drugs.map((it) => {
                //             it.qty = it.quantity;
                //             return it;
                //         })
                //     )
                // ),
                drugsJson: JSON.stringify(
                    this.drugs.map((it) => {
                        it.qty = it.quantity;
                        return it;
                    })
                ),
                notes: this.prescriptiontNotes,
                date: dt,
            };

            return AthPrescriptionService.createPrescription(prescription)
                .then((res) => {
                    res;
                    this.successMessage = true;

                    return Promise.resolve(true);
                })
                .catch((err) => {
                    err;
                    this.successMessage = false;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione della prescrizione!",
                    });

                    return Promise.reject(true);
                });
        },
        handleSubstituteDoctor(doc) {
            if (doc == null) this.substituteDoctorUUID = null;
            else this.substituteDoctorUUID = doc.uuid;
        },
    },
};
</script>
