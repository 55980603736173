<template>
    <v-card v-if="isVisible"
            cols="12"
            sm="12"
            color="blue lighten-5">
        <v-card-text>
            <div v-if="substitutions.length == 1">
                ATTENZIONE: Nella data selezionata, il medico <b>
                "{{ substitutions[0].leavingDoctor.firstName + " " + substitutions[0].leavingDoctor.lastName }}" </b> viene sostituito da
                <b>{{ substitutions[0].substituteDoctor.firstName + " " + substitutions[0].substituteDoctor.lastName }}</b> pertanto l'appuntamento verrà
                creato su <b>{{ substitutions[0].substituteDoctor.firstName + " " + substitutions[0].substituteDoctor.lastName }}</b>
            </div>
            <div v-if="substitutions.length > 1">
                ATTENZIONE: Nella data selezionata, il medico
                "{{ substitutions[0].leavingDoctor.firstName + " " + substitutions[0].leavingDoctor.lastName }}" viene sostituito da più medici.
                <br />
                Selezionare quale di questi deve prendere in carico l'appuntamento

                <v-select v-model="substitute"
                          :items="docs"
                          outlined
                          :item-text="(item) => item.firstName + ' ' + item.lastName"
                          item-value="uuid"
                          label="Seleziona un medico sostitutivo per questo appuntamento"
                          return-object
                          single-line></v-select>
                <span v-if="substitute">L'appuntamento verrà
                creato su <b>{{ substitute.firstName + " " + substitute.lastName }}</b></span>
                <br />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthCheckActiveSubstitutions",
    props: ["doctorUUID", "day"],
    watch: {
        substitute: {
            handler: function(s) {
                if(s == null) return;
                this.$emit('ath-appointment-substitute-doctor', s);
            }
        },
        doctorUUID: {
            handler: function (d) {
                // this.$emit('ath-appointment-substitute-doctor', null);
                this.getActiveSubstitutions(this.day, d);
            },
        },
        day: {
            handler: function (h) {
                // this.$emit('ath-appointment-substitute-doctor', null);
                this.getActiveSubstitutions(h, this.doctorUUID);
            },
        },
    },
    computed: {
        isVisible: {
            get() {
                return (
                    this.doctorUUID != null &&
                    this.substitutions &&
                    this.substitutions.length > 0
                );
            },
            set() {},
        },
    },
    data: () => ({
        substitutions: [],
        docs: [],
        substitute: null
    }),
    methods: {
        getActiveSubstitutions(day, doctor) {
            if (doctor == null || doctor == "null") {
                this.$emit('ath-appointment-substitute-doctor', null);
                return;
            } 
            this.docs = [];
            this.substitute = null;
            AthWebClient.get("/substitutions/day/" + day + "/doctor/" + doctor)
                .then((res) => {
                    this.substitutions = res.data;
                    for(let i = 0; i < this.substitutions.length; i++) {
                        if(this.substitutions[i]) this.docs.push(this.substitutions[i].substituteDoctor);
                    }

                    if(this.substitutions && this.substitutions.length == 1 && this.substitutions[0]) {
                        this.$emit('ath-appointment-substitute-doctor', this.substitutions[0].substituteDoctor);
                    }
                    else {
                        this.$emit('ath-appointment-substitute-doctor', null);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
};
</script>