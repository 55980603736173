<template>
    <v-container fluid>
        <v-dialog v-model="deleteModal"
                  persistent
                  width="960">
            <v-card>
                <v-card-title class="text-h5">
                    Cancella Blocco Calendario
                </v-card-title>

                <v-card-text>
                    Confermare la cancellazione del blocco calendario selezionato?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteModal = false">
                        Annulla
                    </v-btn>
                    <v-btn color="error"
                           raised
                           @click="deleteCalendarBlock()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table :headers="headers"
                      :items="calendarBlocks"
                      :items-per-page="perPage"
                      :server-items-length="calendarBlocksTotal"
                      :options.sync="options"
                      :page="page"
                      :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }"
                      class="elevation-1"
                      disable-filtering
                      disable-sort
                      hide-default-header
                      mobile-breakpoint="100">
            <!-- make headers bold -->
            <template v-slot:header="heads">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader"
                            scope="col"
                            v-for="h in heads.props.headers"
                            :key="h.text"
                            style="
                                    font-size: 1rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                            class="text-start">
                            {{ h.text }}
                        </th>
                    </tr>
                </thead>
            </template>

            <template v-slot:[`item.doctor`]="{ item }">
                <b>{{ item.doctor.firstName }} {{ item.doctor.lastName }}</b>
            </template>

            <template v-slot:[`item.startDateUtc`]="{ item }">
                {{ item.startDateUtc | formatDate }}
            </template>

            <template v-slot:[`item.endDateUtc`]="{ item }">
                {{ item.endDateUtc | formatDate }}
            </template>

            <template v-slot:[`item.blockType`]="{ item }">
                <v-btn :color="getColor(item.blockType)"
                       small
                       outlined>{{ getText(item.blockType) }}</v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom
                        left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="info"
                               outlined
                               v-bind="attrs"
                               v-on="on">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item dense>
                            <v-list-item-title>
                                <v-btn small
                                       plain
                                       @click="confirm(item.uuid)">
                                    <v-icon>mdi-delete</v-icon>Elimina
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <v-overlay color="white"
                   absolute
                   :value="loading"
                   light
                   opacity="0.7">
            <v-progress-circular color="info"
                                 indeterminate
                                 size="128">
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";
// import moment from "moment";

export default {
    name: "AthCalendarBlocksComponent",
    filters: {
        formatDate(d) {
            return d;
        },
    },
    mounted: function () {
        let url = "/calendar-blocks/auto?page=0&perPage=10";
        AthWebClient.get(url)
            .then((data) => {
                this.calendarBlocks = data.data.content;
                this.calendarBlocksTotal = data.data.totalElements;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
    },
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate(opts);
            },
            deep: true,
        },
    },
    data: () => ({
        headers: [
            { text: "Dottore", value: "doctor" },
            { text: "Data Inizio", value: "startDateUtc" },
            { text: "Data Fine", value: "endDateUtc" },
            { text: "Tipo", value: "blockType" },
            { text: "Motivazione", value: "reason" },
            { text: "Azione", value: "actions" },
        ],
        calendarBlocks: [],
        calendarBlocksTotal: 0,

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        options: {},

        toDeleteUUID: null,
        deleteModal: false,
    }),
    methods: {
        confirm(u) {
            this.toDeleteUUID = u;
            this.deleteModal = true;
        },
        deleteCalendarBlock() {
            AthWebClient.delete("/calendar-blocks/" + this.toDeleteUUID)
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo!",
                        text: "Blocco calendario cancellato con successo",
                    })
                    .then(() => {
                        this.deleteModal = false;
                        this.toDeleteUUID = null;

                        this.handlePaginationUpdate({page: 1, itemsPerPage: 10});
                    })
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la cancellazione del blocco calendario",
                    });
                });
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.itemsPerPage;

            let url =
                "/calendar-blocks/auto?page=" +
                (this.page - 1) +
                "&perPage=" +
                this.perPage;
            AthWebClient.get(url)
                .then((data) => {
                    this.calendarBlocks = data.data.content;
                    this.calendarBlocksTotal = data.data.totalElements;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });

            this.loading = true;
        },
        getText: function (blockType) {
            if (blockType == "GENERIC_REASON") return "Ragione Generica";
            if (blockType == "PAUSE") return "Pausa";
            if (blockType == "HOLIDAYS") return "Ferie";
        },
        getColor: function (blockType) {
            if (blockType == "GENERIC_REASON") return "info";
            if (blockType == "PAUSE") return "blue-grey";
            if (blockType == "HOLIDAYS") return "success";
        },
    },
};
</script>