import AthWebClient from "./ath-webclient";

export default {
    name: "AthDrugsService",
    getDrugsAuto: function(page, perPage, filters) {
        filters;

        let url = "/drugs/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if (filters.search) {
            url += "&search=" + filters.search;
        }

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    findDrugsForPrescription(prescriptionUUID) {
        let url = "/drugs/for-prescription/" + prescriptionUUID;

        return AthWebClient.get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    edit(drug, id) {
        let url = "/drugs/" + id;

        return AthWebClient.put(url, drug)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    delete(id) {
        let url = "/drugs/" + id;

        return AthWebClient.delete(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
};
