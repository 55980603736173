<template>
    <v-autocomplete
        outlined
        clearable
        v-on:change="handleSelectPatient"
        :items="patients"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="isLoading"
        :search-input.sync="search"
        :filter="filter"
        prepend-inner-icon="mdi-account"
        label="Ricerca Paziente"
        no-data-text="Scrivi per cercare"
        ref="selectPatientRef"
    >
        <template v-slot:selection="{ item }">
            <span
                >{{ item.ufirstName + ' ' + item.ulastName }} | <b>{{ item.pfirstName + " " + item.plastName }}</b></span
            >
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title
                    ><b>{{ item.pfirstName + " " + item.plastName }}</b> |
                    {{ getBirthdate(item.pbirthdate) }} | <v-icon>mdi-map-marker</v-icon> {{ item.paddress }} </v-list-item-title
                >
                <v-list-item-subtitle
                    class="primary--text text-button"
                ><v-icon>mdi-stethoscope</v-icon> {{ item.ufirstName + ' ' + item.ulastName }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
// import moment from "moment";
import AthPatientsService from "../../shared/ath-patients.service";

export default {
    name: "AthSelectPatient",
    props: {
        doctorUUID: {
            type: String,
            required: false,
        },
        toggleClear: {
            type: Boolean,
            required: false,
        }
    },
    methods: {
        // looks okay as it is, because search is done server-side.
        // in case, refer to: https://vuetifyjs.com/en/api/v-autocomplete/#props-filter
        // and: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40
        filter: (item, queryText, itemText) => {
            // console.info({item, queryText, itemText});
            item;
            queryText;
            itemText;
            // return itemText.toUpperCase().replace(/ /g,'').indexOf(queryText.toUpperCase().replace(/ /g,'')) > -1
            return true;
        },
        // these two cannot be inlined as in the other component because of https://github.com/vuetifyjs/vuetify/issues/6635#issuecomment-528444479
        itemText: function(item) {
            return (
                item.ufirstName +
                " " +
                item.ulastName +
                " | " +
                item.pfirstName +
                " " +
                item.plastName +
                " | " +
                item.pbirthdate +
                " | " +
                item.paddress
            );
        },
        // the return type must be a primitive and apparenty an array or an object are not okay
        // so we return a json string containing the whole element
        itemValue: function(item) {
            return JSON.stringify(item);
        },
        handleSelectPatient($event) {
            this.$emit('ath-select-patient', $event);
            return $event;
        },
        handleInput(ev) {
            console.info(ev);
            // let timeout = null;
            // if(timeout) clearTimeout(timeout);

            // timeout = setTimeout(() => {
            //     // this.search = ev;
            //     console.info(ev);
            // }, 1000);
        },
        getBirthdate(d) {
            // let md = moment(d, "YYYY-MM-DD");

            // if(md.isValid) 
            //     return md.format("DD/MM/YYYY");
            // else
            //     return md;
            return d;
        }
    },
    data: () => ({
        isLoading: false,
        patients: [],
        search: null,
    }),
    watch: {
        search(val) {
            this.isLoading = true;

            AthPatientsService.searchPatient(
                this.doctorUUID || null,
                val || "null",
                {}
            )
                .then((res) => {
                    this.patients = res;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
        },
        toggleClear: {
            handler: function(t) {
                t;
                this.$refs.selectPatientRef.reset();
            }
        }
    },
};
</script>
