<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Impegnativa creata con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               sm="12"
               md="10"
               offset-md="1">
            <v-container fluid>
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center">Crea Impegnativa</v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <ath-select-patient :doctorUUID="doctorUUID"
                                                    :toggleClear="toggleClear"
                                                    v-on:ath-select-patient="handleSelectPatient($event)">
                                </ath-select-patient>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <ath-select-doctor :doctors="doctors"
                                                   v-if="viewSelectDoctor"
                                                   v-on:ath-select-doctor="handleSelectDoctor($event)">
                                </ath-select-doctor>
                            </v-col>
                            <v-col cols="12"
                                   md="12">
                                <ath-select-visit-type :toggleClear=toggleClear></ath-select-visit-type>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    ">
                                <v-date-picker v-model="examDate"
                                               full-width
                                               class="mt-4"
                                               no-title
                                               color="primary darken-2"
                                               first-day-of-week="1"
                                               style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>

                                <br />
                                <ath-check-active-substitutions :doctorUUID="doctorUUID"
                                                                v-on:ath-appointment-substitute-doctor="handleSubstituteDoctor($event)"
                                                                :day="examDate"></ath-check-active-substitutions>
                                <span style="margin-top: 1rem">La data selezionata &egrave;
                                    <span class="text-h6">{{
                                    formatDate(examDate)
                                }}</span></span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="2"
                                   offset-sm="5"
                                   class="d-flex align-center justify-center">
                                <v-btn block
                                       color="info"
                                       raised>
                                    Crea Impegnativa
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthSelectPatient from "../ath-select-patient/ath-select-patient.vue";
import AthSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthSelectVisitType from "../ath-select-visit-type/ath-select-visit-type.vue";
import AthCheckActiveSubstitutions from "../ath-check-active-substitutions/ath-check-active-substitutions.vue";
import moment from "moment";

export default {
    components: {
        AthSelectPatient,
        AthSelectDoctor,
        AthSelectVisitType,
        AthCheckActiveSubstitutions,
    },
    name: "AthCreateExam",
    methods: {
        handleSelectDoctor(doctorUUID) {
            this.doctorUUID = doctorUUID;
        },
        // this hack is needed because of the issue with primitive return type of function item-value
        // check ath-select-patient component for more details
        handleSelectPatient(payload) {
            let parsed = JSON.parse(payload);

            if (parsed == null) {
                this.patientUUID = null;
                this.doctorUUID = null;

                return;
            }

            // patient is not generic, has an associated doctor
            if (parsed.pisGeneric == false) {
                this.viewSelectDoctor = false;
                this.patientUUID = parsed.puuid;
                this.doctorUUID = parsed.uuuid;
            }
            // patient is generic, no doctor associated, show the dropdown
            else {
                // admin or secretary
                if (this.$store.state.user.loginRole >= 5) {
                    this.viewSelectDoctor = true;
                    this.patientUUID = parsed.puuid;
                } else {
                    this.doctorUUID = this.$store.state.user.loginId;
                    this.viewSelectDoctor = false;
                    this.patientUUID = parsed.puuid;
                }
            }
        },
        formatDate: function (d) {
            return moment(d).format("DD/MM/YYYY");
        },
        handleSubstituteDoctor(doc) {
            if (doc == null) this.substituteDoctorUUID = null;
            else this.substituteDoctorUUID = doc.uuid;
        },
    },
    data: () => ({
        successMessage: false,
        doctors: [],
        clearSelectDoctor: false,
        doctorUUID: null,
        // this will come from the ath-select-patient component
        patientUUID: null,
        // controls whether to show the select doctor component
        viewSelectDoctor: false,

        toggleClear: false,
        examDate: moment().format("YYYY-MM-DD"),
    }),
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
};
</script>
