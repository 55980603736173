<template>
  <v-col cols="12" sm="12" md="10" offset-md="1">
    <v-container fluid>
        <v-card hover>
          <v-card-title class="text-h4" style="justify-content: center;">Blocco Calendari</v-card-title>
            <v-card-text>
                <ath-calendar-blocks></ath-calendar-blocks>
            </v-card-text>
        </v-card>
    </v-container>
  </v-col>
</template>

<script>
import AthCalendarBlocks from '../components/ath-calendar-blocks/ath-calendar-blocks.vue';
export default {
    name: "AthCalendarBlocksView",
    components: { AthCalendarBlocks },
};
</script>