<template>
    <v-container>
        <v-data-table :headers="headers"
                      :items="substitutions"
                      :items-per-page="perPage"
                      :server-items-length="substitutionsTotal"
                      :options.sync="options"
                      :page="page"
                      :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }"
                      class="elevation-1"
                      disable-filtering
                      disable-sort
                      hide-default-header
                      mobile-breakpoint="100">
            <!-- make headers bold -->
            <template v-slot:header="heads">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader"
                            scope="col"
                            v-for="h in heads.props.headers"
                            :key="h.text"
                            style="
                                    font-size: 1rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                            class="text-start">
                            {{ h.text }}
                        </th>
                    </tr>
                </thead>
            </template>

            <template v-slot:[`item.leavingDoctor`]="{ item }">
                <b>{{ item.leavingDoctor.firstName }} {{ item.leavingDoctor.lastName }}</b>
            </template>

            <template v-slot:[`item.substituteDoctor`]="{ item }">
                <b>{{ item.substituteDoctor.firstName }} {{ item.substituteDoctor.lastName }}</b>
            </template>

            <template v-slot:[`item.startDate`]="{ item }">
                {{ item.startDate | formatDate }}
            </template>

            <template v-slot:[`item.endDate`]="{ item }">
                {{ item.endDate | formatDate }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom
                        left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="info"
                               outlined
                               v-bind="attrs"
                               v-on="on">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item dense>
                            <v-list-item-title>
                                <v-btn small
                                       plain
                                       @click="del(item.uuid)"><v-icon>mdi-delete</v-icon>Elimina</v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <v-overlay color="white"
                   absolute
                   :value="loading"
                   light
                   opacity="0.7">
            <v-progress-circular color="info"
                                 indeterminate
                                 size="128">
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import AthSubstitionsService from "../../shared/ath-substitutions.service";
import moment from 'moment';

export default {
    name: "AthSubstitutionsComponent",
    filters: {
        formatDate(d) {
            return moment(d).format('DD/MM/YYYY');
        }
    },
    mounted: function () {
        if (this.getActive) {
            AthSubstitionsService.getAllActive(1, 10)
                .then((data) => {
                    this.substitutions = data.data;
                    this.substitutionsTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        } else {
            AthSubstitionsService.getAllExpired(1, 10)
                .then((data) => {
                    this.substitutions = data.data;
                    this.substitutionsTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        }
    },
    props: ["getActive", "refreshToggle"],
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate(opts);
            },
            deep: true,
        },

        refreshToggle: {
            handler() {
                this.handlePaginationUpdate(this.options);
            }
        }
    },
    data: () => ({
        headers: [
            { text: "Dottore", value: "leavingDoctor" },
            { text: "Sostituito Da", value: "substituteDoctor" },
            { text: "Data Inizio", value: "startDate" },
            { text: "Data Fine", value: "endDate" },
            { text: "Note", value: "notes" },
            { text: "Azione", value: "actions" },
        ],
        substitutions: [],
        substitutionsTotal: 0,

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        options: {},
    }),
    methods: {
        del: function(uuid) {
            AthSubstitionsService.deleteSubscription(uuid).then((res) => {
                res;
                this.handlePaginationUpdate({page: 1, itemsPerPage: 10});
                this.$forceUpdate();
            });
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.itemsPerPage;

            this.loading = true;

            if (this.getActive) {
                AthSubstitionsService.getAllActive(this.page, this.perPage)
                    .then((data) => {
                        this.substitutions = data.data;
                        this.substitutionsTotal = data.total;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.loading = false;
                    });
            } else {
                AthSubstitionsService.getAllExpired(this.page, this.perPage)
                    .then((data) => {
                        this.substitutions = data.data;
                        this.substitutionsTotal = data.total;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.loading = false;
                    });
            }
        },
    },
};
</script>