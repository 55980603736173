<template>
    <v-row>
        <v-col cols="12"
               sm="12"
               md="10"
               offset-md="1">
            <v-container fluid>
                <v-card :elevation="modal ? 0 : 2">
                    <v-card-title class="text-h4"
                                  style="justify-content: center">Crea Informatore</v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-card :elevation="modal ? 0 : 2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Nome"
                                              prepend-inner-icon="mdi-account"
                                              outlined
                                              v-model="representative.firstName">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Cognome"
                                              prepend-inner-icon="mdi-account-outline"
                                              outlined
                                              v-model="representative.lastName">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-select prepend-inner-icon="mdi-domain"
                                          outlined
                                          label="Azienda Farmaceutica"
                                          :items="phCompanies"
                                          :item-text="(item) => item.name + ' | ' + item.address"
                                          v-model="representative.phCompanyUUID"
                                          item-value="uuid"></v-select>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Indirizzo"
                                              prepend-inner-icon="mdi-map-marker"
                                              outlined
                                              v-model="representative.address">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Email"
                                              prepend-inner-icon="mdi-email"
                                              outlined
                                              v-model="representative.email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Telefono"
                                              prepend-inner-icon="mdi-phone"
                                              outlined
                                              v-model="representative.phone">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-textarea outlined
                                            prepend-inner-icon="mdi-note"
                                            label="Note"
                                            rows="2"
                                            v-model="representative.notes"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    ">
                                <v-btn color="info"
                                       raised
                                       @click="create()">
                                    Salva
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthCreateRepresentative",
    props: ["modal"],
    methods: {
        create() {
            if (this.representative.phCompanyUUID == null) {
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Selezionare un'azienda farmaceutica!",
                });

                return;
            }

            AthWebClient.post("/representatives/", this.representative)
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Informatore creato con successo",
                    })
                    .then((val) => {
                        this.$emit('ath-create-representative-success', val);
                        if(this.modal == false) this.$router.push("/representatives");
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione dell'informatore!",
                    });
                });
        },
    },
    mounted: function () {
        AthWebClient.get("/phcompanies/all")
            .then((res) => {
                this.phCompanies = res.data;
            })
            .catch((err) => {
                console.error(err);
            });
    },
    data: () => ({
        representative: {},
        phCompanies: [],
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
