<template>
    <v-container fluid>
        <v-row dense>
          <v-col cols="12" md="10" offset-md="1">
            <v-card hover>
              <v-card-title class="text-h4" style="justify-content: center"> Aziende Farmaceutiche</v-card-title>
            </v-card>
          </v-col>
            <v-col cols="12" md="10" offset-md="1">

                <v-card>
                    <v-card-title>Filtri e Ricerca</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    prepend-inner-icon="mdi-domain"
                                    outlined
                                    label="Nome"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions
                        style="display: flex;
                                align-content: flex-start;
                                justify-content: flex-end;
                                align-items: center;"
                    >
                        <v-btn large color="info" raised>Filtra</v-btn>
                        <v-btn large color="error" raised>Reset Filtri</v-btn>
                    </v-card-actions>
                </v-card>
                <br />
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="companies"
                        :items-per-page="perPage"
                        :server-items-length="companiesTotal"
                        :options.sync="options"
                        :page="page"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }"
                        class="elevation-1"
                        disable-filtering
                        disable-sort
                        hide-default-header
                        mobile-breakpoint="100"
                    >
                        <!-- make headers bold -->
                        <template v-slot:header="heads">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th
                                        role="columnheader"
                                        scope="col"
                                        v-for="h in heads.props.headers"
                                        :key="h.text"
                                        style="
                                    font-size: 0.9rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                        class="text-start"
                                    >
                                        {{ h.text }}
                                    </th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="info" outlined v-bind="attrs" v-on="on">
                                    <v-icon>mdi-menu</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title
                                            ><v-btn
                                                plain
                                                @click="alert(item.uuid)"
                                                >Modifica</v-btn
                                            ></v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-overlay
                        color="white"
                        absolute
                        :value="loading"
                        light
                        opacity="0.7"
                    >
                        <v-progress-circular
                            color="info"
                            indeterminate
                            size="128"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthCompaniesService from "../../shared/ath-companies.service";

export default {
    name: "AthPhCompanies",
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate({ page: opts.page, perPage: opts.itemsPerPage });
            },
            deep: true,
        },
    },
    mounted() {
        this.loading = true;

        // load patients
        AthCompaniesService.getCompaniesAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.companies = data.data;
                this.companiesTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
    },
    methods: {
        alert(e) {
            alert(e);
        },
        handlePaginationUpdate: function(paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

        AthCompaniesService.getCompaniesAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.companies = data.data;
                this.companiesTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
        },
        handleFilterUpdate: function(fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
    },
    data: () => ({
        // data for the companies table
        companies: [],
        companiesTotal: 0,

        // headers for the appointments table
        headers: [
            { text: "Nome", value: "name" },
            { text: "Indirizzo", value: "address" },
            { text: "Telefono", value: "phone" },
            { text: "Email", value: "email" },
            { text: "Note", value: "notes" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            search: null,
        },

        options: {},
    }),
};
</script>
