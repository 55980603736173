<template>
    <v-card>
        <v-card-title style="justify-content: center">
            Dettagli Prescrizione
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12"
                       sm="12">
                    <h3 class="black--text">Dati</h3>
                </v-col>
                <v-col cols="12"
                       sm="6">
                    <v-text-field prepend-inner-icon="mdi-calendar"
                                  label="Data"
                                  outlined
                                  readonly
                                  :value="getDate(item.adate)"></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="6">
                    <v-text-field prepend-inner-icon="mdi-account"
                                  label="Paziente"
                                  outlined
                                  readonly
                                  :value="item.pfirstName + ' ' + item.plastName"
                                  append-icon="mdi-eye"
                                  @click:append="showDetails(item.puuid)"></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       v-if="showPatientDetails">
                    <v-card cols="12"
                            sm="12"
                            color="blue lighten-5">
                        <v-card-text>
                            <b>{{ patient.firstName + " " + patient.lastName }} | {{ getBirthdate(patient.birthdate) }} | {{ patient.address }}</b>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12"
                       sm="6">
                    <v-text-field :prepend-inner-icon="
                            item.acompleted ? 'mdi-check' : 'mdi-close'
                        "
                                  :success="item.acompleted"
                                  :error="!item.acompleted"
                                  label="Compleata"
                                  outlined
                                  readonly
                                  :value="item.acompleted ? 'SI' : 'NO'"></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="6">
                    <v-text-field prepend-inner-icon="mdi-note"
                                  label="Esito"
                                  outlined
                                  readonly
                                  :value="item.acloseReason"></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12">
                    <v-text-field prepend-inner-icon="mdi-stethoscope"
                                  label="Dottore"
                                  outlined
                                  readonly
                                  :value="item.ufirstName + ' ' + item.ulastName"
                                  @click:append="alert(item.uuuid)"></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12">
                    <v-textarea prepend-inner-icon="mdi-note"
                                  label="Note"
                                  rows="3"
                                  outlined
                                  readonly
                                  :value="item.anotes"></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       sm="12">
                    <h3 class="black--text">Farmaci</h3>
                </v-col>
                <v-col cols="12"
                       sm="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Nome
                                    </th>
                                    <th class="text-left">
                                        Principio Attivo
                                    </th>
                                    <th class="text-left">
                                        Quantità
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(drug, index) in drugs"
                                    :key="index">
                                    <td>{{ drug.name }}</td>
                                    <td>{{ drug.activeSubstance }}</td>
                                    <td>{{ drug.qty }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-overlay color="white"
                               absolute
                               :value="loading"
                               light
                               opacity="0.7">
                        <v-progress-circular color="info"
                                             indeterminate
                                             size="128"></v-progress-circular>
                    </v-overlay>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <div class="col-12 d-flex flex-column">
                <v-textarea v-model="acloseReason"
                            outlined
                            rows="3"
                            label="Esito"></v-textarea>
                <v-btn @click.stop="saveAndSetComplete(true, {
                            auuid: item.auuid,
                            acloseReason: acloseReason,
                        })"
                       color="success">
                    Salva come completata
                </v-btn>
                <br />
                <v-btn @click.stop="saveAndSetComplete(false, {
                            auuid: item.auuid,
                            acloseReason: acloseReason,
                        })"
                       color="error">
                    Salva come non completata
                </v-btn>
                <br />
                <v-btn @click.stop="$emit('ath-close-view-prescription', true)">
                    Annulla
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import AthDrugsService from "../../shared/ath-drugs.service";
import AthPatientsService from "../../shared/ath-patients.service";
import moment from "moment";

export default {
    name: "AthViewPrescription",
    props: ["item"],
    watch: {
        item: {
            handler: function (i) {
                //this.drugs = [];
                AthDrugsService.findDrugsForPrescription(i.auuid)
                    .then((res) => {
                        console.info(res);
                        this.drugs = res;
                        this.acloseReason = i.acloseReason;
                        this.$forceUpdate();
                    })
                    .catch((err) => console.error(err));
            },
            deep: true,
        },
    },
    mounted: function () {
        this.drugs = [];
        this.showPatientDetails = false;
        AthDrugsService.findDrugsForPrescription(this.item.auuid)
            .then((res) => {
                this.drugs = res;
                this.$forceUpdate();
            })
            .catch((err) => console.error(err));
    },
    data() {
        return {
            drugs: [],
            loading: false,
            acloseReason: "",
            showPatientDetails: false,
            patient: null,
        };
    },
    methods: {
        getDate(d) {
            return moment(d).format("DD/MM/YYYY");
        },
        saveAndSetComplete(completed, event) {
            this.$emit("ath-prescriptions-table-set-close-reason", event);
            this.$emit("ath-prescriptions-table-set-status", {
                uuid: event.auuid,
                status: completed,
            });
        },
        showDetails(puuid) {
            AthPatientsService.getPatientByUUID(puuid)
                .then((res) => {
                    this.patient = res;
                    this.showPatientDetails = !this.showPatientDetails;
                })
                .catch((err) => {
                    err;
                    this.patient = null;
                });
        },
        getBirthdate(d) {
            return d;
            // let md = moment(d, "YYYY-MM-DD");

            // if (md.isValid) return md.format("DD/MM/YYYY");
            // else return md;
        },
    },
};
</script>

<style>
.v-icon.v-icon.v-icon--link {
    color: #1976d2;
}
</style>
