<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center"> Farmaci</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="10"
                   offset-md="1">

                <v-card>
                    <v-card-title>Filtri e Ricerca</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   md="9">
                                <v-text-field prepend-inner-icon="mdi-pill"
                                              clearable
                                              v-model="filters.search"
                                              outlined
                                              hint="Digita il farmaco e premi filtra per ottenere i risultati"
                                              label="Nome o Principio Attivo"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions style="display: flex;
                                align-content: flex-start;
                                justify-content: flex-end;
                                align-items: center;">
                        <v-btn large
                               @click="filter()"
                               color="info"
                               raised>Filtra</v-btn>
                        <v-btn large
                               @click="resetFilters()"
                               color="error"
                               raised>Reset Filtri</v-btn>
                    </v-card-actions>
                </v-card>
                <br />
                <v-card>
                    <v-data-table :headers="headers"
                                  :items="drugs"
                                  :items-per-page="perPage"
                                  :server-items-length="drugsTotal"
                                  :options.sync="options"
                                  :page="page"
                                  :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }"
                                  class="elevation-1"
                                  disable-filtering
                                  disable-sort
                                  hide-default-header>
                        <!-- make headers bold -->
                        <template v-slot:header="heads">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th role="columnheader"
                                        scope="col"
                                        v-for="h in heads.props.headers"
                                        :key="h.text"
                                        style="
                                    font-size: 0.9rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                        class="text-start">
                                        {{ h.text }}
                                    </th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu bottom
                                    left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info"
                                           outlined
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>mdi-menu</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn small
                                                   color="primary"
                                                   @click="toEdit = item; editDialog = true;">
                                                <v-icon>mdi-pencil</v-icon> Modifica
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn small
                                                   color="error"
                                                   @click="del(item)">
                                                <v-icon>mdi-delete</v-icon> Elimina
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-overlay color="white"
                               absolute
                               :value="loading"
                               light
                               opacity="0.7">
                        <v-progress-circular color="primary"
                                             indeterminate
                                             size="128"></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog persistent
                  max-width="600"
                  v-model="confirm"
                  v-if="toDelete">
            <v-card>
                <v-card-title class="text-h5">Conferma Eliminazione Farmaco</v-card-title>
                <v-card-text style="color: black; font-size: 1.1rem;">
                    Sicuri di voler cancellare? <br /> <br />
                    Nome: <b>{{toDelete.name}}</b> <br />
                    Principio Attivo: <b>{{ toDelete.activeSubstance }}</b>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green"
                           class="white--text"
                           @click="confirm = false; toDelete = null;">
                        Annulla
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error"
                           @click="_del()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent
                  max-width="960"
                  v-model="editDialog"
                  v-if="toEdit">
            <v-card>
                <v-card-title class="text-h5">Modifica Farmaco</v-card-title>
                <v-card-text class="black--text">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Nome"
                                              prepend-inner-icon="mdi-pill"
                                              outlined
                                              v-model="toEdit.name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Principio Attivo"
                                              prepend-inner-icon="mdi-note"
                                              outlined
                                              v-model="toEdit.activeSubstance">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info"
                           @click="editDialog = false; toEdit = null;">
                        Annulla
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success"
                           @click="editDrug()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AthDrugsService from "../../shared/ath-drugs.service";

export default {
    name: "AthDrugs",
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate({
                    page: opts.page,
                    perPage: opts.itemsPerPage,
                });
            },
            deep: true,
        },
    },
    mounted() {
        this.loading = true;

        // load patients
        AthDrugsService.getDrugsAuto(this.page, this.perPage, this.filters)
            .then((data) => {
                this.drugs = data.data;
                this.drugsTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
    },
    methods: {
        filter: function () {
            this.handleFilterUpdate(this.filters);
        },
        resetFilters: function () {
            this.filters.search = null;
            this.handleFilterUpdate(this.filters);
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthDrugsService.getDrugsAuto(this.page, this.perPage, this.filters)
                .then((data) => {
                    this.drugs = data.data;
                    this.drugsTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
        editDrug: function () {
            AthDrugsService.edit(this.toEdit, this.toEdit.id).then((res) => {
                res;
                this.editDialog = false;
                this.$swal({
                    icon: "success",
                    title: "Successo",
                    text: "Farmaco modificato con successo!",
                }).then((res) => {
                    res;
                    this.handlePaginationUpdate({
                        page: 1,
                        perPage: this.perPage,
                    });
                    this.$forceUpdate();
                });
            });
        },
        del: function (i) {
            this.toDelete = i;
            this.confirm = true;
        },
        _del: function () {
            return AthDrugsService.delete(this.toDelete.id).then((res) => {
                res;
                this.confirm = false;
                this.$swal({
                    icon: "success",
                    title: "Successo",
                    text: "Farmaco eliminato con successo!",
                }).then((res) => {
                    res;
                    this.handlePaginationUpdate({
                        page: 1,
                        perPage: this.perPage,
                    });
                    this.$forceUpdate();
                });
            });
        },
    },
    data: () => ({
        // data for the patients table
        drugs: [],
        drugsTotal: 0,

        toDelete: null,

        // headers for the appointments table
        headers: [
            { text: "Nome", value: "name" },
            { text: "Principio Attivo", value: "activeSubstance" },
            { text: "Azioni", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            search: null,
        },

        options: {},

        confirm: false,

        toEdit: null,
        editDialog: false,
    }),
};
</script>
