<template>
    <v-container fluid>
        <v-card class="d-flex flex-wrap"
                flat>
            <span class="timeslot"
                  v-for="time in timeslots"
                  :key="time"
                  v-on:click="checkAndSelect(time, $event)"
                  v-bind:class="getClasses(time)">
                {{ time }}
            </span>
        </v-card>
        <br />
        <v-expansion-panels focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>
                <span class="d-flex justify-center">Legenda (clicca per aprire)</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="ma-2">
                        <v-col cols="12"
                               sm="12"
                               md="3"
                               class="d-flex justify-center">
                            <v-btn height="40"
                                   color="#F44336"
                                   class="mr-2 white--text"
                            ><span style="font-style: italic;">Appuntamento</span></v-btn>

                        </v-col>
                        <v-col cols="12"
                               sm="12"
                               md="3"
                               class="d-flex justify-center">
                            <v-btn height="40"
                                   color="#7d8085"
                                   class="mr-2 white--text"
                            ><span style="font-style: italic;">Pausa</span></v-btn>

                        </v-col>
                        <v-col cols="12"
                               sm="12"
                               md="3"
                               class="d-flex justify-center">
                            <v-btn height="40"
                                   color="#9ed65f"
                                   class="mr-2 w"><span>Ferie</span></v-btn>

                        </v-col>
                        <v-col cols="12"
                               sm="12"
                               md="3"
                               class="d-flex justify-center">
                            <v-btn height="40"
                                   color="#e6e56e"
                                   class="mr-2"><span>Ragione Generica</span></v-btn>

                        </v-col>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-container>
</template>

<script>
// import moment from 'moment';
// import moment from "moment";
/**
 *      occupiedSlots.indexOf(time) > -1
                    ? checkAppointment($event)
                    : selectTime($event)
 */
// prettier-ignore
let by5 = [
     '07:00','07:05','07:10','07:15','07:20','07:25','07:30','07:35','07:40','07:45','07:50','07:55','08:00'
    ,'08:05','08:10','08:15','08:20','08:25','08:30','08:35','08:40','08:45','08:50','08:55','09:00','09:05'
    ,'09:10','09:15','09:20','09:25','09:30','09:35','09:40','09:45','09:50','09:55','10:00','10:05','10:10'
    ,'10:15','10:20','10:25','10:30','10:35','10:40','10:45','10:50','10:55','11:00','11:05','11:10','11:15'
    ,'11:20','11:25','11:30','11:35','11:40','11:45','11:50','11:55','12:00','12:05','12:10','12:15','12:20'
    ,'12:25','12:30','12:35','12:40','12:45','12:50','12:55','13:00','13:05','13:10','13:15','13:20','13:25'
    ,'13:30','13:35','13:40','13:45','13:50','13:55','14:00','14:05','14:10','14:15','14:20','14:25','14:30'
    ,'14:35','14:40','14:45','14:50','14:55','15:00','15:05','15:10','15:15','15:20','15:25','15:30','15:35'
    ,'15:40','15:45','15:50','15:55','16:00','16:05','16:10','16:15','16:20','16:25','16:30','16:35','16:40'
    ,'16:45','16:50','16:55','17:00','17:05','17:10','17:15','17:20','17:25','17:30','17:35','17:40','17:45'
    ,'17:50','17:55','18:00','18:05','18:10','18:15','18:20','18:25','18:30','18:35','18:40','18:45','18:50'
    ,'18:55','19:00','19:05','19:10','19:15','19:20','19:25','19:30','19:35','19:40','19:45','19:50','19:55'
    ,'20:00','20:05','20:10','20:15','20:20','20:25','20:30','20:35','20:40','20:45','20:50','20:55','21:00'
    ];

// prettier-ignore
let by10 = [
     '07:00','07:10','07:20','07:30','07:40','07:50','08:00','08:10','08:20','08:30','08:40','08:50','09:00'
    ,'09:10','09:20','09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10'
    ,'11:20','11:30','11:40','11:50','12:00','12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20'
    ,'13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30'
    ,'15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40'
    ,'17:50','18:00','18:10','18:20','18:30','18:40','18:50','19:00','19:10','19:20','19:30','19:40','19:50'
    ,'20:00','20:10','20:20','20:30','20:40','20:50','21:00'
];

// prettier-ignore
let by15 = [
     '07:00','07:15','07:30','07:45','08:00','08:15','08:30','08:45','09:00','09:15','09:30','09:45'
    ,'10:00','10:15','10:30','10:45','11:00','11:15','11:30','11:45','12:00','12:15','12:30','12:45'
    ,'13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15','15:30','15:45'
    ,'16:00','16:15','16:30','16:45','17:00','17:15','17:30','17:45','18:00','18:15','18:30','18:45'
    ,'19:00','19:15','19:30','19:45','20:00','20:15','20:30','20:45','21:00'
];

export default {
    name: "AthTimeslots",
    props: {
        day: {
            type: String,
            required: false,
        },
        timeDivision: {
            type: Number,
            required: false,
        },
        occupiedSlots: {
            type: Array,
            required: false,
        },
        startingTime: {
            type: String,
            required: false,
        },
        endingTime: {
            type: String,
            required: false,
        },
    },
    mounted() {
        if (this.timeDivision == 5)
            this.timeslots = by5.filter(
                (t) => t >= this.startingTime && t <= this.endingTime
            );
        if (this.timeDivision == 10)
            this.timeslots = by10.filter(
                (t) => t >= this.startingTime && t <= this.endingTime
            );
        if (this.timeDivision == 15)
            this.timeslots = by15.filter(
                (t) => t >= this.startingTime && t <= this.endingTime
            );
    },
    data: () => ({
        timeslots: [],
        selectedTime: null,
    }),
    methods: {
        checkAndSelect: function (t, e) {
            let found = this.occupiedSlots.filter((t1) => {
                return t1.startTime == t;
            });

            if (found && found.length > 0) {
                return this.checkAppointment(e);
            } else {
                return this.selectTime(e);
            }
        },
        getClasses: function (t) {
            let found = this.occupiedSlots.filter((t1) => {
                return t >= t1.startTime && t <= t1.endTime;
            });

            let type = null;

            if (found && found.length > 0) {
                type = found[0].blockType;
            }

            return {
                "timeslot-occupied": type == null && found.length > 0,
                "timeslot-pause": type == "PAUSE",
                "timeslot-generic-reason": type == "GENERIC_REASON",
                "timeslot-holidays": type == "HOLIDAYS",
                selected: this.selectedTime === t,
            };
        },
        selectTime: function (e) {
            this.selectedTime = e.target.innerText;

            this.$emit("ath-select-time", this.selectedTime);
        },
        checkAppointment: function (e) {
            e;
            this.$emit("ath-show-occupied-slot", true);
        },
        update: function (s) {
            s;
            if (this.timeDivision == 5)
                this.timeslots = by5.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
            if (this.timeDivision == 10)
                this.timeslots = by10.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
            if (this.timeDivision == 15)
                this.timeslots = by15.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
            this.selectedTime = null;
        },
    },
    watch: {
        startingTime: function (s) {
            this.update(s);
        },
        endingTime: function (s) {
            this.update(s);
        },
        day: function (s) {
            this.update(s);
        },
        occupiedSlots: function (s) {
            this.update(s);
        },
        timeDivision: function (s) {
            if (s == 5)
                this.timeslots = by5.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
            if (s == 10)
                this.timeslots = by10.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
            if (s == 15)
                this.timeslots = by15.filter(
                    (t) => t >= this.startingTime && t <= this.endingTime
                );
        },
    },
};
</script>

<style scoped>
.timeslot {
    padding: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border: 2px solid lightgray;
    border-radius: 5px;
}

.timeslot:hover {
    border-color: black;
    /* color: #1976d2; */
    text-shadow: 0 0 1px #1976d2;
    cursor: pointer;
}

.selected {
    background-color: #2196f3;
    color: white;
    text-shadow: 0 0 1px #2196f3;
}

.timeslot-occupied {
    background-color: #f44336;
    color: white;
    border-color: #f44336;
    pointer-events: none;
}

.timeslot-pause {
    background-color: #7d8085;
    color: white;
    border-color: #7d8085;
    pointer-events: none;
}

.timeslot-generic-reason {
    background-color: #e6e56e;
    color: black;
    border-color: #e6e56e;
    pointer-events: none;
}

.timeslot-holidays {
    background-color: #9ed65f;
    color: white;
    border-color: #9ed65f;
    pointer-events: none;
}
</style>
