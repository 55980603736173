var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-card',{attrs:{"hover":""}},[_c('v-card-title',{staticClass:"text-h4",staticStyle:{"justify-content":"center"}},[_vm._v(" Aziende Farmaceutiche")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filtri e Ricerca")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-domain","outlined":"","label":"Nome"}})],1)],1)],1),_c('v-card-actions',{staticStyle:{"display":"flex","align-content":"flex-start","justify-content":"flex-end","align-items":"center"}},[_c('v-btn',{attrs:{"large":"","color":"info","raised":""}},[_vm._v("Filtra")]),_c('v-btn',{attrs:{"large":"","color":"error","raised":""}},[_vm._v("Reset Filtri")])],1)],1),_c('br'),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.companies,"items-per-page":_vm.perPage,"server-items-length":_vm.companiesTotal,"options":_vm.options,"page":_vm.page,"footer-props":{
                        'items-per-page-options': [5, 10, 15, 25, 50],
                        'show-current-page': true,
                        'show-first-last-page': true,
                    },"disable-filtering":"","disable-sort":"","hide-default-header":"","mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(heads){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((heads.props.headers),function(h){return _c('th',{key:h.text,staticClass:"text-start",staticStyle:{"font-size":"0.9rem","color":"rgba(0, 0, 0, 0.87)"},attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" "+_vm._s(h.text)+" ")])}),0)])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.alert(item.uuid)}}},[_vm._v("Modifica")])],1)],1)],1)],1)]}}],null,true)}),_c('v-overlay',{attrs:{"color":"white","absolute":"","value":_vm.loading,"light":"","opacity":"0.7"}},[_c('v-progress-circular',{attrs:{"color":"info","indeterminate":"","size":"128"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }