<template>
  <v-col cols="12" sm="12" md=10 offset-md="1">
    <v-container fluid>
        <v-card hover>
          <v-card-title class="text-h4" style="justify-content: center;">Sostituzioni attive</v-card-title>
        </v-card>
      <v-card>
            <v-card-text>
                <ath-substitutions :get-active="true"></ath-substitutions>
            </v-card-text>
        </v-card>
    </v-container>
  </v-col>
</template>

<script>
import AthSubstitutions from "../components/ath-substitutions/ath-substitutions.vue";
export default {
    name: "AthSubstitutionsView",
    components: { AthSubstitutions },
};
</script>