import AthWebClient from "./ath-webclient";

export default {
    name: "AthPatientsService",
    create: function(payload) {
        let url = "/patients/";

        return AthWebClient.post(url, payload).then((res) => {
            return res.data;
        })
        .catch(err => {throw err;});
    },
    edit: function(payload, uuid) {
        let url = "/patients/" + uuid;

        return AthWebClient.put(url, payload).then((res) => {
            return res.data;
        })
        .catch(err => {throw err;});
    },
    getPatientsAuto: function(page, perPage, filters) {
        let url = "/patients/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if(filters.doctorUUID) {
            url += '&doctorUUID=' + filters.doctorUUID;
        }

        return AthWebClient.get(url).then((res) => {
            return { data: res.data.content, total: res.data.totalElements };
        })
        .catch(err => {throw err;});
    },
    searchPatientsAuto: function(page, perPage, filters) {
        let url = "/patients/search/paged/" + (filters.firstLastName || "null") + "?page=" + (page - 1) + "&perPage=" + perPage;
        
        if(filters.doctorUUID) {
            url += '&doctorUUID=' + filters.doctorUUID;
        }

        if(filters.email) {
            url += '&email=' + encodeURIComponent(filters.email);
        }

        if(filters.phone) {
            url += '&phone=' + encodeURIComponent(filters.phone);
        }

        if(filters.address) {
            url += '&address=' + encodeURIComponent(filters.address);
        }

        if(filters.birthdate) {
            url += '&birthdate=' + encodeURIComponent(filters.birthdate);
        }

        return AthWebClient.get(url).then((res) => {
            return { data: res.data.content, total: res.data.totalElements };
        })
        .catch(err => {throw err;});
    },
    searchPatient: function(doctorUUID, firstLastName, filters) {
        let url = "/patients/search/fuzzy/" + firstLastName;

        if(filters.doctorUUID) {
            url += '&doctorUUID=' + filters.doctorUUID;
        }

        if(filters.email) {
            url += '&email=' + encodeURIComponent(filters.email);
        }

        if(filters.phone) {
            url += '&phone=' + encodeURIComponent(filters.phone);
        }

        if(filters.address) {
            url += '&address=' + encodeURIComponent(filters.address);
        }

        if(filters.birthdate) {
            url += '&birthdate=' + encodeURIComponent(filters.birthdate);
        }

        // TODO: for the next iteration, use doctorUUID if provided
        return AthWebClient.get(url).then(
            (res) => {
                return res.data;
            }
        );
        //.catch(err => console.error(err))
    },
    getPatientByUUID: function(patientUUID) {
        if (patientUUID == null) {
            return;
        }

        return AthWebClient.get("/patients/" + patientUUID)
            .then((res) => {
                return res.data;
            });
    },
    getDoctorByPatientUUID: function(patientUUID) {
        if (patientUUID == null) {
            return;
        }

        return AthWebClient.get("/users/doctors/get-by-patient/" + patientUUID)
            .then((res) => {
                return res.data;
            });
    }
};
