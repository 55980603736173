<template>
    <v-container>
        <v-dialog v-model="createRoomDialog"
                  width="600px">
            <v-card>
                <v-card-title style="justify-content: center">
                    Crea Chat
                </v-card-title>
                <v-card-text>
                    <v-autocomplete prepend-inner-icon="mdi-account"
                                    outlined
                                    :items="usersForChat"
                                    :item-text="item => item.firstName + ' ' + item.lastName"
                                    :item-value="item => item.uuid"
                                    label="Destinatario"
                                    hint="Utente a cui inviare il messaggio"
                                    :persistent-hint="true"
                                    v-model="newChatUser"></v-autocomplete>
                    <br />
                    <v-btn block
                           class="success"
                           :disabled="newChatUser == null"
                           @click="createRoom()">Crea chat</v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-btn block
                           color="error"
                           @click.stop="createRoomDialog = false;">
                        Annulla
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12"
                   md="12">
                <vue-advanced-chat :current-user-id="currentUserId"
                                    :rooms="JSON.stringify(rooms)"
                                    :messages="JSON.stringify(messages)"
                                    :room-actions="JSON.stringify(roomActions)"
                                    :message-actions="JSON.stringify(messageActions)"
                                    :rooms-loaded="true"
                                    :load-first-room="false"
                                    :messages-loaded="messagesLoaded"
                                    show-audio="false"
                                    show-files="false"
                                    :text-messages="JSON.stringify(transl)"
                                    :styles="JSON.stringify(customStyles)"
                                    @add-room="addRoom($event)"
                                    @fetch-messages="onFetchMessages"
                                    @send-message="onSendMessage($event)"
                                    @delete-message="onDeleteMessage($event.detail[0])"
                                    @edit-message="onEditMessage($event.detail[0])">
                    
                                    
                </vue-advanced-chat>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { register } from "vue-advanced-chat";
import athWebClient from "../shared/ath-webclient";

register();

let stompClient = null;

let currentRoomId = null;

export default {
    name: "AthChatFullpage",
    mounted: function () {
        /*
        // HACKS

        // // hide audio icon - not handled yet
        // document
        //     .querySelector(
        //         "#app > div > main > div > div > div > div > div > div > div.v-card__text > vue-advanced-chat"
        //     )
        //     .shadowRoot.querySelector(
        //         "#room-footer > div > div.vac-icon-textarea-left"
        //     ).style.display = "none";

        // vac-room-footer

        // document
        //     .getElementById(
        //         "#room-footer"
        //     ).style.zIndex = 1;

        // // hide file selector
        // document
        //     .querySelector(
        //         "#app > div > main > div > div > div > div > div > div > div.v-card__text > vue-advanced-chat"
        //     )
        //     .shadowRoot.querySelector(
        //         "#room-footer > div > div.vac-icon-textarea > div:nth-child(2)"
        //     ).style.display = "none";
        */

        athWebClient.get('/newchat/get-rooms-for-participant/' + this.$store.state.user.loginId)
            .then((res) => {
                this.rooms = res.data;
            })
            .catch((err) => {
                console.error(err);
                this.rooms = [];
            });

        athWebClient.get('/users/users-for-new-chatroom-for-user/' + this.$store.state.user.loginId)
        .then((res) => {
                this.usersForChat = res.data;
            })
            .catch((err) => {
                console.error(err);
                this.usersForChat = [];
            });

        this.connect();

        this.interval = setInterval(() => {this.requestNewMessages();}, 1500);
    },
    methods: {
        connect: function() {
            let url = athWebClient.defaults.baseURL + "/chat";
            var socket = new window.SockJS(url);
            stompClient = window.Stomp.over(socket);
            stompClient.debug = null;
            let self = this;
            stompClient.connect({}, function(frame) {
                // console.log('Connected: ' + frame);
                frame;
                stompClient.subscribe('/topic/messages', function(messageOutput) {
                    if(JSON.parse(messageOutput.body).forUserUUID != self.currentUserId) return;
                    let unreadCountByRoom = JSON.parse(messageOutput.body).unreadCountByRoom;

                    self.onFetchMessages({detail: [{
                        room: {
                            roomId: currentRoomId
                        }
                    }]});

                    // console.info(unreadCountByRoom);

                    // update rooms latest unread count
                    /*
                        {
                            "d7eabe1e-7633-4807-b582-7bbf27ef9e66": 0,
                            "3aafac9c-2fcd-489b-9c87-c23c7a09e8a1": 0
                        }
                    */

                    let badgeCount = 0;

                    for (let i = 0; i < self.rooms.length; i++) {
                        badgeCount += unreadCountByRoom[self.rooms[i].roomId];
                        self.rooms[i].unreadCount = unreadCountByRoom[self.rooms[i].roomId];
                    }

                    self.$store.state.ui.chat.unreads = badgeCount;
                });
            });
        },
        disconnect: function() {
            if(stompClient != null) {
                stompClient.disconnect();
            }
            // console.log("Disconnected");
        },
        requestNewMessages: function() {
            stompClient.send("/app/chat", {}, 
                JSON.stringify({'requestingUserUUID': this.currentUserId}));
        },
        refresh: function() {
            athWebClient.get('/newchat/get-rooms-for-participant/' + this.$store.state.user.loginId)
            .then((res) => {
                this.rooms = res.data;
            })
            .catch((err) => {
                console.error(err);
                this.rooms = [];
            });
        },
        createRoom: function() {
            if(this.newChatUser != null) {
                athWebClient.post("/newchat/rooms", {
                    participant1UUID: this.$store.state.user.loginId,
                    participant2UUID: this.newChatUser
                })
                .then((res) => {
                    res;
                    this.createRoomDialog = false;
                    
                    athWebClient.get('/newchat/get-rooms-for-participant/' + this.$store.state.user.loginId)
                    .then((res) => {
                        this.rooms = res.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.rooms = [];
                    });

                    athWebClient.get('/users/users-for-new-chatroom-for-user/' + this.$store.state.user.loginId)
                    .then((res) => {
                            this.usersForChat = res.data;
                        })
                        .catch((err) => {
                            console.error(err);
                            this.usersForChat = [];
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
            }
            else {
                console.error("ERROR!");
                this.createRoomDialog = false;
            }
        },
        addRoom: function(ev) {
            ev;
            this.createRoomDialog = true;
        },
        info: function(str) {
            console.info(str);
        },
        onDeleteMessage: function (msg) {
            let msgToDelete = this.messages.filter(
                (m) => m._id == msg.message._id
            )[0];

            msgToDelete.deleted = true;
        },
        onEditMessage: function (msg) {
            let msgToEdit = this.messages.filter(
                (m) => m._id == msg.messageId
            )[0];

            msgToEdit.edited = new Date();

            msgToEdit.content = msg.newContent;
        },
        onSendMessage: function (msg) {
            let newMsg = {
                //_id: "" + parseInt("" + Math.random() * 1000),
                // indexId: 12092,
                content: msg.detail[0].content,
                senderId: this.currentUserId,
                roomId: msg.detail[0].roomId
            };

            // send message
            athWebClient.post("/newchat/send", newMsg)
            .then((res) => {
                res;

                for (let i = 0; i < this.rooms.length; i++) {
                    if(this.rooms[i].roomId == newMsg.roomId) {
                        if(this.rooms[i].lastMessage != null) {
                            this.rooms[i].lastMessage.content = newMsg.content;
                        }
                    }
                }

                // update messages for room
                return this.onFetchMessages({detail: [{
                    room: {
                        roomId: newMsg.roomId
                    }
                }]});
            })
            .catch((err) => {
                console.error(err);
           });
        },
        onFetchMessages: function (obj) {
            this.messagesLoaded = false;
            
            currentRoomId = obj.detail[0].room.roomId;

            if(this.$store.state.ui.chat.open == true || this.$route.path == "/testing/chat-fullpage") {
                athWebClient.get('/newchat/get-messages-for-room/' + obj.detail[0].room.roomId + '/' + this.currentUserId)
                .then((res) => {
                    this.messages = res.data;
                    this.messagesLoaded = true;

                    for (let i = 0; i < this.rooms.length; i++) {
                        if(this.rooms[i].roomId == currentRoomId) {
                            if(this.rooms[i].lastMessage != null) {
                                this.rooms[i].lastMessage.content = this.messages[this.messages.length - 1].content;
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.messages = [];
                });
            }
        },
    },
    data() {
        return {
            interval: null,
            stompClient: null,
            customStyles: {
                icons: {
                    search: "#9ca6af",
                    dropdownMessageBackground: "rgba(0, 0, 0, 1)",
                },
            },
            messageActions: [
                {
                    name: "replyMessage",
                    title: "Rispondi",
                },
                // {
                //     name: "editMessage",
                //     title: "Modifica",
                //     onlyMe: true,
                // },
                // {
                //     name: "deleteMessage",
                //     title: "Elimina",
                //     onlyMe: true,
                // },
                // {
                //     name: "selectMessages",
                //     title: "Seleziona",
                // },
            ],
            transl: {
                ROOMS_EMPTY: "Nessuna conversazione",
                ROOM_EMPTY: "Nessuna conversazione selezionata",
                NEW_MESSAGES: "Nuovi Messaggi",
                MESSAGE_DELETED: "Messaggio Cancellato",
                MESSAGES_EMPTY: "Nessun Messaggio",
                CONVERSATION_STARTED: "Conversazione iniziata il: ",
                TYPE_MESSAGE: "Scrivi il messaggio",
                SEARCH: "Cerca",
                // IS_ONLINE: "Online ",
                IS_ONLINE: " ",
                LAST_SEEN: "Ultimo accesso ",
                IS_TYPING: "Sta scrivendo...",
                CANCEL_SELECT_MESSAGE: "Annulla Selezione",
            },
            messagesLoaded: false,
            currentUserId: this.$store.state.user.loginId,
            rooms: [],
            messages: [],
            createRoomDialog: false,
            newChatUser: null,
            usersForChat: [],
            // disabled for now
            roomActions: [
                // { name: "inviteUser", title: "Invite User" },
                // { name: "removeUser", title: "Remove User" },
                // { name: "deleteRoom", title: "Delete Room" },
            ],
        };
    },
};
</script>