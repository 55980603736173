<template>
    <v-container>
        <v-row>
            <v-col cols="12"
                   md="10"
                   offset-md="1"></v-col>
        </v-row>
        <v-card>
            <v-card-title>Modifica Profilo</v-card-title>
            <v-card-text>
                <ath-edit-user :modal="false"
                               :user="user"></ath-edit-user>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import athEditUser from "../components/ath-edit-user/ath-edit-user.vue";
import AthWebClient from "../shared/ath-webclient";

export default {
    components: { athEditUser },
    name: "AthEditProfile",
    mounted() {
        AthWebClient.get("/users/me")
            .then((res) => {
                this.user = res.data;
            })
            .catch((err) => {
                err;
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Impossibile modificare il profilo! Riprovare",
                });
            });
    },
    data: () => ({
        user: null,
    }),
};
</script>