<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center"> Pazienti</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card>
                    <v-card-title>Filtri e Ricerca</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   md="6">
                                <ath-select-doctor v-if="doctorFilterEnabled"
                                                   :doctors="doctors"
                                                   v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="6">
                                <v-text-field prepend-inner-icon="mdi-account"
                                              outlined
                                              hint="Digita il paziente e premi filtra per ottenere i risultati"
                                              v-model="filters.firstLastName"
                                              label="Paziente"></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="3">
                                <v-text-field prepend-inner-icon="mdi-email"
                                              outlined
                                              clearable
                                              v-model="filters.email"
                                              label="Email"></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="3">
                                <v-text-field prepend-inner-icon="mdi-phone"
                                              outlined
                                              clearable
                                              v-model="filters.phone"
                                              label="Telefono"></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="3">
                                <v-text-field prepend-inner-icon="mdi-map-marker"
                                              outlined
                                              clearable
                                              v-model="filters.address"
                                              label="Indirizzo"></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="12"
                                   md="3">
                                <v-text-field prepend-inner-icon="mdi-calendar"
                                              outlined
                                              clearable
                                              v-model="filters.birthdate"
                                              label="Data di Nascita (GG/MM/AAAA)"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-row>
                        <!-- TODO -->
                        <!-- <v-col cols="12"
                               xs="12"
                               sm="6"
                               class="d-flex justify-start align-center">
                            <v-chip-group column
                                          multiple
                                          style="margin-left: 10px;">
                                <v-chip v-model="showOnlyGenerics"
                                        filter
                                        outlined
                                        class="mr-2"
                                        color="info"
                                        label>
                                    Solo Pazienti Generici
                                </v-chip>
                            </v-chip-group>
                        </v-col> -->
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               class="d-flex justify-end">
                            <v-card-actions>
                                <v-btn large
                                       @click="filter()"
                                       color="info"
                                       raised>Filtra</v-btn>
                                <v-btn large
                                       @click="reset()"
                                       color="error"
                                       raised>Reset Filtri</v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>
                <br />
                <v-card>
                    <v-data-table :headers="headers"
                                  :items="patients"
                                  :items-per-page="perPage"
                                  :server-items-length="patientsTotal"
                                  :options.sync="options"
                                  :page="page"
                                  :footer-props="{
                                      'items-per-page-options': [5, 10, 15, 25, 50],
                                      'show-current-page': true,
                                      'show-first-last-page': true,
                                  }"
                                  class="elevation-1"
                                  disable-filtering
                                  disable-sort
                                  hide-default-header
                                  mobile-breakpoint="100">
                        <!-- make headers bold -->
                        <template v-slot:header="heads">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th role="columnheader"
                                        scope="col"
                                        v-for="h in heads.props.headers"
                                        :key="h.text"
                                        style="
                                    font-size: 0.9rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                        class="text-start">
                                        {{ h.text }}
                                    </th>
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:[`item.doctor`]="{ item }">
                            {{ item.ufirstName || "PAZIENTE" }} &nbsp;
                            {{ item.ulastName || "GENERICO" }}
                        </template>

                        <template v-slot:[`item.pbirthdate`]="{ item }">
                            {{ getBirthdate(item.pbirthdate) }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu bottom
                                    left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info"
                                           outlined
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>mdi-menu</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn plain
                                                   @click="edit(item.puuid)">Modifica</v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-overlay color="white"
                               absolute
                               :value="loading"
                               light
                               opacity="0.7">
                        <v-progress-circular color="info"
                                             indeterminate
                                             size="128"></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="editDialog"
                  persistent
                  max-width="960px">
            <v-card>
                <v-card-title class="text-h4"
                              style="justify-content: space-between;">Modifica Paziente<span>
                        <v-icon large
                                @click="editDialog = false;">mdi-close</v-icon>
                    </span></v-card-title>
                <v-card-text>
                    <ath-edit-patient v-on:ath-edit-patient-success="editDialog = false; patientUUID = null; filter();"
                                      :patientUUID="patientUUID"
                                      v-on:ath-edit-patient-cancel="editDialog = false;"></ath-edit-patient>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// import moment from "moment";
import AthDoctorsService from "../../shared/ath-doctors.service";
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthPatientsService from "../../shared/ath-patients.service";
import AthEditPatient from "../ath-edit-patient/ath-edit-patient.vue";

export default {
    components: { athSelectDoctor, AthEditPatient },
    name: "AthPatients",
    watch: {
        options: {
            handler(opts) {
                this.handlePaginationUpdate({
                    page: opts.page,
                    perPage: opts.itemsPerPage,
                });
            },
            deep: true,
        },
    },
    mounted() {
        this.loading = true;

        // load patients
        AthPatientsService.getPatientsAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.patients = data.data;
                this.patientsTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
                this.doctors = [];
            });
    },
    methods: {
        edit: function(uuid) {
            this.patientUUID = uuid;
            this.editDialog = true;
        },
        reset: function () {
            this.filters.doctorUUID = null;
            this.filters.firstLastName = null;
            this.filters.email = null;
            this.filters.phone = null;
            this.filters.address = null;
            this.filters.birthdate = null;

            this.filter();
        },
        filter: function () {
            this.handlePaginationUpdate({
                page: 1,
                perPage: this.perPage,
            });
        },
        handleSelectDoctor(uuid) {
            if (uuid == null) {
                this.filters.doctorUUID = null;

                return;
            }

            this.filters.doctorUUID = this.doctors.filter(
                (d) => d.uuid == uuid
            )[0].uuid;
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            let allFiltersEmpty = true;

            for(const val in this.filters) {
                if (this.filters[val] != null && this.filters[val] != "") {
                    allFiltersEmpty = false;
                    break;
                }
            }

            // not searching for patient, get all
            if (allFiltersEmpty) {
                AthPatientsService.getPatientsAuto(
                    this.page,
                    this.perPage,
                    this.filters
                )
                    .then((data) => {
                        this.patients = data.data;
                        this.patientsTotal = data.total;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.loading = false;
                    });
            }
            // searching for patient, do the actual search
            else {
                AthPatientsService.searchPatientsAuto(
                    this.page,
                    this.perPage,
                    this.filters
                )
                    .then((data) => {
                        this.patients = data.data;
                        this.patientsTotal = data.total;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.loading = false;
                    });
            }
        },
        getBirthdate(d) {
            // let md = moment(d, "YYYY-MM-DD");

            // if (md.isValid) return md.format("DD/MM/YYYY");
            // else return md;
            return d;
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({
                page: this.page,
                perPage: this.perPage,
            });
        },
    },
    data: () => ({
        // data for the patients table
        patients: [],
        patientsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Nome", value: "pfirstName" },
            { text: "Cognome", value: "plastName" },
            { text: "Dottore", value: "doctor" },
            { text: "Data di nascita", value: "pbirthdate" },
            { text: "Indirizzo", value: "paddress" },
            { text: "Telefono", value: "pphone" },
            { text: "Email", value: "pemail" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            doctorUUID: null,
            firstLastName: null,
            email: null,
            phone: null,
            address: null,
            birthdate: null
        },

        options: {},

        showOnlyGenerics: false,

        editDialog: false,

        patientUUID: null,
    }),
};
</script>
