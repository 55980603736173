<template>
    <div style="margin-right: 1.5rem; margin-top: 1rem;">
        <v-container class="anim"
                     v-if="isChatDetailsOpened == false">
            <v-row class="d-flex" style="justify-content: space-around;">
                <v-col cols="5" xs="5"><h2>Lista Conversazioni </h2> </v-col>
                <v-col cols="3" xs="3"><v-btn @click="openCreateChatDialog()"
                       color="success">Crea Nuova</v-btn></v-col>
                <v-col cols="1" xs="1"><v-btn outlined color="info"><v-icon @click="getChats()">mdi-refresh</v-icon></v-btn></v-col>
            </v-row>
            <br />
            <v-row class="chat-row"
                   v-for="(chat) in chats"
                   :key="chat.uuid"
                   style="border: 1px solid lightgray; border-radius: 1rem; padding: 0.5rem; margin-bottom: 1rem;">
                <v-col cols="12"
                       sm="10"
                       class="chat-preview"
                       @click="openChat(chat)">
                    <v-badge :value="unreadForChat[chat.uuid] > 0"
                             :content="unreadForChat[chat.uuid]"
                             bordered
                             class="white--text pb-8"
                             color="error">
                        <span style="color: rgb(33, 150, 243);"><u>{{ getUserFrom(chat) }}</u></span>
                    </v-badge><br />
                    <span v-text="chat.lastMessage"></span><br />
                    <span style="
                                font-size: 0.8rem;
                                min-width: 120px;"
                          v-text="chat.lastMessageOn"></span>
                </v-col>
                <v-col style="align-self: end;"
                       cols="12"
                       sm="1">

                    <v-btn icon
                           outlined
                           color="primary"
                           @click="openChat(chat)">
                        <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                    &nbsp;
                    <v-btn icon
                           outlined
                           color="warning"
                           @click="archiveChat(chat.uuid)">
                        <v-icon>mdi-archive</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
            &nbsp;
        </v-container>
        <v-container v-if="isChatDetailsOpened == true"
                     class="anim">
            <v-toolbar flat>
                <v-btn icon
                       color="primary"
                       outlined
                       @click="closeChatDetails()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{ chatName }}</v-toolbar-title>
            </v-toolbar>
            <div ref="messageHolder"
                 style="overflow-y: scroll; max-height: 700px !important;">
                <div class="imessage"
                     v-for="message in chatMessages"
                     :key="message.uuid">
                    <p :class="{'from-me': message.isentIt, 'from-them': !message.isentIt}">
                        {{ message.message }}
                        <span style="
                                font-size: 0.9rem;
                                position: absolute;
                                bottom: -20px;
                                color: black;
                                min-width: 120px;"
                              :class="{'left-align': !message.isentIt, 'right-align': message.isentIt}">{{ message.messageTimestamp }}</span>
                    </p>
                </div>
            </div>
            <v-row>
                <v-col cols="10">&nbsp;
            <v-textarea
                        rows="3"
                        outlined
                        label="Messaggio"
                        v-model="messageToSend"
                        @keyup.enter="sendMessage()"
                        color="primary">

            </v-textarea></v-col>
                <v-col cols="2">&nbsp;
            <v-btn fab
                   large
                   color="success"
                   class="success v-text-field"
                   :disabled="messageToSend == null || messageToSend.length < 1"
                   @click="sendMessage()"><v-icon>mdi-send</v-icon></v-btn></v-col>
            </v-row>
            &nbsp;
        </v-container>
        <v-dialog v-model="createChatDialog"
                  width="600px">
            <v-card>
                <v-card-title style="justify-content: center">
                    Crea Chat
                </v-card-title>
                <v-card-text>
                    <v-autocomplete prepend-inner-icon="mdi-account"
                                    outlined
                                    :items="usersForChat"
                                    :item-text="item => item.firstName + ' ' + item.lastName"
                                    :item-value="item => item.uuid"
                                    label="Destinatario"
                                    hint="Utente a cui inviare il messaggio"
                                    :persistent-hint="true"
                                    v-model="newChatUser"></v-autocomplete>
                    <br />
                    <v-textarea rows="3"
                                outlined
                                label="Messaggio"
                                v-model="newMessageToSend"
                                color="primary"></v-textarea>
                    <v-btn block
                           class="success"
                           :disabled="newMessageToSend == null || newMessageToSend.length < 1"
                           @click="createChatAndMessage()">Invia</v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-btn block
                           color="error"
                           @click.stop="createChatDialog = false;">
                        Annulla
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";
import goTo from 'vuetify/lib/services/goto';

export default {
    name: "AthChat",
    data: () => ({
        chats: [],
        chatUUID: null,
        chatName: null,
        chatMessages: [],
        messageToSend: null,
        isChatDetailsOpened: false,
        drawer: false,
        createChatDialog: false,
        usersForChat: [],
        newChatUser: null,
        newMessageToSend: null,
        unreadNotifications: null,
        unreadForChat: {},
        intervalForSingleChat: [],
    }),
    mounted: function () {
        this.getChats();
        const interval = setInterval(() => this.getUnreadNotifications(), 5000);

        this.$store.commit("ui.chat.set.refreshInterval", interval);
    },
    watch: {
        "$store.state.ui.chat.open": {
            handler: function(open) {
                if(open == false) {
                    for(let i = 0; i < this.intervalForSingleChat.length; i++) clearInterval(this.intervalForSingleChat[i]);
                }
            }
        }
    },
    methods: {
        archiveChat: function(uuid) {
            const url = "/chats/archive/" + uuid;

            return AthWebClient.post(url)
                .then((res) => {
                    res;
                    this.getChats();
                })
                .catch((err) => {
                    console.info(err);
                });
        },
        getUserFrom: function (chat) {
            if (chat.userFrom.uuid == this.$store.state.user.loginId) {
                return chat.userTo.firstName + " " + chat.userTo.lastName;
            } else {
                return chat.userFrom.firstName + " " + chat.userFrom.lastName;
            }
        },
        openCreateChatDialog: function () {
            let url = "/users/for/chat";

            return AthWebClient.get(url)
                .then((res) => {
                    this.usersForChat = res.data;
                    this.createChatDialog = true;
                })
                .catch((err) => {
                    // this.chats = [];
                    // this.chatMessages = [];
                    console.info(err);
                });
        },
        createChatAndMessage: function () {
            let url = "/chats/";

            if(this.newMessageToSend == null || this.newChatUser == null) {
                console.error("Invalid chat object");

                return;
            }

            let newChatObj = {
                message: this.newMessageToSend,
                userFrom: this.$store.state.user.loginId,
                userTo: this.newChatUser,
            };

            console.info(newChatObj);

            return AthWebClient.post(url, newChatObj)
                .then((res) => {
                    res;
                    this.createChatDialog = false;
                    this.newMessageToSend = null;        
                    this.newChatUser = null;
                    this.getChats();
                })
                .catch((err) => {
                    // this.chats = [];
                    // this.chatMessages = [];
                    console.info(err);
                });
        },
        getChats: function () {
            let url = "/chats/mine";

            return AthWebClient.get(url)
                .then((res) => {
                    this.chats = res.data;
                })
                .catch((err) => {
                    // this.chats = [];
                    // this.chatMessages = [];
                    console.info(err);
                });
        },
        getUnreadNotifications: function () {
            let url = "/chats/messages/notifications/mine";

            return AthWebClient.get(url)
                .then((res) => {
                    this.unreadNotifications = res.data;
                    if (
                        this.unreadNotifications &&
                        this.unreadNotifications.length > 0
                    ) {
                        let totalUnreads = 0;
                        for (
                            let i = 0;
                            i < this.unreadNotifications.length;
                            i++
                        )
                            totalUnreads +=
                                this.unreadNotifications[i].unreadMessages;

                        for(let i = 0; i < this.unreadNotifications.length; i++) {
                            this.unreadForChat[this.unreadNotifications[i].chatUUID] = this.unreadNotifications[i].unreadMessages;
                            console.info()
                        }

                        this.$store.commit("ui.chat.set.unreads", totalUnreads);
                        this.getChats();
                    }
                    else
                    {
                        this.unreadForChat = {};
                        this.$store.commit("ui.chat.set.unreads", 0);
                    }
                })
                .catch((err) => {
                    // this.chats = [];
                    // this.chatMessages = [];
                    console.info(err);
                });
        },
        openChat: function (chat) {
            this.chatUUID = chat.uuid;

            // some magic to get the chat name
            if (this.$store.state.user.loginId == chat.userFrom.uuid) {
                this.chatName =
                    chat.userTo.firstName + " " + chat.userTo.lastName;
            } else {
                this.chatName =
                    chat.userFrom.firstName + " " + chat.userFrom.lastName;
            }

            // clear previously-typed message
            this.messageToSend = null;

            this.getMessagesForChat(this.chatUUID);

            for(let i = 0; i < this.intervalForSingleChat.length; i++) clearInterval(this.intervalForSingleChat[i]);
            
            let interval = setInterval(() => this.getMessagesForChat(this.chatUUID), 500);

            this.intervalForSingleChat.push(interval);
        },
        closeChatDetails: function() {
            this.isChatDetailsOpened = false;

            for(let i = 0; i < this.intervalForSingleChat.length; i++) clearInterval(this.intervalForSingleChat[i]);
        },
        getMessagesForChat: function(chatId) {
            let url = "/chats/messages/" + chatId;

            return AthWebClient.get(url)
                .then((res) => {
                    this.chatMessages = res.data;
                    this.isChatDetailsOpened = true;
                })
                .catch((err) => {
                    this.chatMessages = [];
                    throw err;
                });
        },
        sendMessage: function () {
            let url = "/chats/" + this.chatUUID;

            return AthWebClient.post(url, { message: this.messageToSend })
                .then((res) => {
                    this.chatMessages.push(res.data);
                    this.messageToSend = null;
                    
                    goTo('.imessage');
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
};
</script>

<style scoped>
.left-align {
    left: 0px;
}

.right-align {
    right: -10px;
}

#app
    > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    background-color: white !important;
}

.chat-row:hover {
    /*background-color: rgba(33, 150, 243, 0.3);*/
    border-radius: 5px;
    /* color: white; */
}
.chat-preview:hover {
    /*background-color: rgba(33, 150, 243, 0.3);*/
    background-color: rgba(200, 228, 251, 0.6);
    border-radius: 5px;

    /* color: white; */
}

* {
    scrollbar-width: auto;
    scrollbar-color: #2196f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.anim {
    animation-duration: 0.7s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

#app
    > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    box-shadow: rgb(0 0 0) 5px 5px 35px 5px !important;
}

/*========= IMESSAGE =============*/
.imessage {
    background-color: #fff;
    /* border: 1px solid #e5e5ea; */
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    font-family: "SanFrancisco";
    font-size: 1.25rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.5rem 1.5rem;
}

.imessage p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
}

p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff;
}

p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
}

p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
}

p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
}

p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
    content: none;
}

.no-tail::before {
    display: none;
}

.margin-b_none {
    margin-bottom: 0 !important;
}

.margin-b_one {
    margin-bottom: 1rem !important;
}

.margin-t_one {
    margin-top: 1rem !important;
}

/* general styling */
@font-face {
    font-family: "SanFrancisco";
    src: url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2")
            format("woff2"),
        url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff")
            format("woff");
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: normal;
    margin: 0;
}

.container {
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
}

h1 {
    font-weight: normal;
    margin-bottom: 0.5rem;
}

h2 {
    border-bottom: 1px solid #e5e5ea;
    color: #666;
    font-weight: normal;
    margin-top: 0;
    padding-bottom: 1.5rem;
}

.comment {
    color: #222;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    max-width: 100%;
    padding: 0;
}

@media screen and (max-width: 800px) {
    body {
        margin: 0 0.5rem;
    }

    .container {
        padding: 0.5rem;
    }

    .imessage {
        font-size: 1.05rem;
        margin: 0 auto 1rem;
        max-width: 600px;
        padding: 0.25rem 0.875rem;
    }

    .imessage p {
        margin: 0.5rem 0;
    }
}
</style>