<template>
    <v-container fluid>
        <v-card hover>
            <v-card-title class="text-h4" style="justify-content: center;">Creazione Orari  <br class="d-sm-none"> Ambulatori</v-card-title>
        </v-card>

    <v-card>
            <v-card-text style="overflow-x: scroll;">
                <ath-create-location-metadata></ath-create-location-metadata>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import AthCreateLocationMetadata from '../components/ath-create-location-metadata/ath-create-location-metadata.vue';
export default {
    name: "AthSubstitutionsView",
    components: { AthCreateLocationMetadata  },
};
</script>