<template>
    <div>
        <v-card hover>
            <v-data-table :headers="headers"
                          :items="events"
                          :items-per-page="perPage"
                          :server-items-length="total"
                          :options.sync="options"
                          :page="page"
                          :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50],
                            'show-current-page': true,
                            'show-first-last-page': true,
                          }"
                          class="elevation-1"
                          disable-filtering
                          disable-sort
                          hide-default-header
                          mobile-breakpoint="100"
                          @click:row="handleRowClick($event)">
                <!-- make headers bold -->
                <template v-slot:header="heads">
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader"
                                scope="col"
                                v-for="h in heads.props.headers"
                                :key="h.text"
                                style="
                                    font-size: 1rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                class="text-start">
                                {{ h.text }}
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:top>
                    <slot name="filters"></slot>
                </template>

                <template v-slot:[`item.patient`]="{ item }">
                    <b style="font-size: large;">{{ item.pfirstName }} {{ item.plastName }}</b>
                </template>

                <template v-slot:[`item.doctor`]="{ item }">
                    {{ item.ufirstName }}
                    {{ item.ulastName }}
                </template>

                <template v-slot:[`item.type`]="{ item }">
                    <v-chip class="ma-2"
                            :color="getChipColor(item.atype)"
                            text-color="white">
                        {{ item.atype | getType }}
                    </v-chip>
                </template>

                <template v-slot:[`item.date`]="{ item }">
                    {{ item.adate | extractDate }}
                </template>

                <template v-slot:[`item.duration`]="{ item }">
                    <v-tooltip top
                               color="blue-grey darken-4">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs"
                                  v-on="on">{{ item.adurationMins }} Minuti</span>
                        </template>
                        <span>Termina alle
                            <b>{{ item.aend | extractTime }}</b></span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.done`]="{ item }">
                    <v-btn color="success"
                           v-if="item.acompleted">
                        <v-icon left> mdi-check-bold </v-icon>
                        Si
                    </v-btn>
                    <v-btn class="white--text"
                           color="red lighten-1"
                           v-if="!item.acompleted">
                        <v-icon left> mdi-close-thick </v-icon>
                        No
                    </v-btn>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom
                            left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="info"
                                   outlined
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn small
                                           color="primary"
                                           @click="$emit('ath-edit-prescription', item.auuid)">
                                        <v-icon>mdi-pencil</v-icon> Modifica
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn small
                                           color="error"
                                           @click="$emit('ath-delete-prescription-with-reason', item.auuid)">
                                        <v-icon>mdi-delete</v-icon> Elimina
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:[`item.reuse`]="{ item }">
                    <v-btn raised
                           color="primary"
                           @click.stop="$emit('ath-reuse-prescription-ev', item)">
                        <v-icon>mdi-clipboard-account-outline</v-icon> Usa come modello
                    </v-btn>
                </template>
                <template v-if="reusePrescription"
                          v-slot:[`item.drugs`]="{ item }">
                    <ath-drugs-for-prescription :prescriptionUUID="item.auuid"></ath-drugs-for-prescription>
                </template>
            </v-data-table>
            <v-overlay color="white"
                       absolute
                       :value="loading"
                       light
                       opacity="0.7">
                <v-progress-circular color="primary"
                                     indeterminate
                                     size="128"></v-progress-circular>
            </v-overlay>
        </v-card>
    </div>
</template>

<script>
/**
 * Events table
 *
 * @props
 *  - headers:Array<v-header> contains the headers for the table
 *  - events:Array<any> contains the rows to be disaplyed in the table
 *  - loading:Boolean indicates if the table is loading or not
 *  - page:Number page to display
 *  - perPage:Number how many rows to display
 *  - total:Number total number of elements that the table will display. Total means total, since the table is paginated. Server-side returned total
 * @events
 *  - ath-events-table-set-done-status:Boolean event that indicates that the user clicked on the "mark as (un)done" button
 */

import moment from "moment";
import athDrugsForPrescription from "../ath-drugs-for-prescription/ath-drugs-for-prescription.vue";
// import athViewPrescription from "../ath-view-prescription/ath-view-prescription.vue";

export default {
    components: { athDrugsForPrescription },
    // components: { athViewPrescription },
    name: "ath-prescriptions-table",
    props: [
        // dataSource
        "headers",
        "events",
        "loading",

        // pagination
        "page",
        "perPage",
        "total",

        // additional action to reuse prescription
        "reusePrescription",
    ],
    watch: {
        options: {
            handler(opts) {
                this.$emit("ath-pagination-update", {
                    page: opts.page,
                    perPage: opts.itemsPerPage,
                });
            },
            deep: true,
        },
    },
    filters: {
        extractDate: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
        extractTime: function (date) {
            return moment(date).format("HH:mm");
        },
        getType(type) {
            if (type == "WITH_PATIENT") return "Paziente";
            else if (type == "WITH_REPRESENTATIVE") return "Rappresentante";
            else return "TIPO SCONOSCIUTO";
        },
    },
    data() {
        return {
            options: {},
            deafultReason: "Regolare",
            dialog: false,
            prescriptionDetail: {},
        };
    },
    methods: {
        deleteWithReason(uuid) {
            alert(uuid);
        },
        editPrescription(uuid) {
            alert(uuid);
        },
        getDuration(start, end) {
            return moment(end).diff(moment(start), "minutes");
        },
        alert(s) {
            console.info(s);
        },
        getChipColor(type) {
            if (type == "WITH_PATIENT") return "primary";
            else if (type == "WITH_REPRESENTATIVE") return "green";
            else return "default";
        },
        handleRowClick($event) {
            this.$emit("ath-view-prescription-details", $event);
            //this.prescriptionDetail = $event;
            //console.info(this.prescriptionDetail.auuid);
            //this.dialog = true;
        },
        getReasons(acloseReason) {
            if (acloseReason == null) acloseReason = this.deafultReason;
            return [
                this.deafultReason,
                acloseReason,
                "Non completata",
                "Paziente non pervenuto",
                "Problema generico",
            ];
        },
    },
};
</script>
