<template>
    <v-card elevation="2">
        <v-card-text>
            <v-row v-for="row in rows"
                   v-bind:key="row.id"
                   style="align-content: center; align-items: center; font-size: 1.1rem"
                   v-bind:class="{ 'twhite': highlightday === row.name, 'bprimary': highlightday === row.name }">
                <v-col cols="12"
                       sm="3"
                       style="text-align: center;"><b>{{ row.displayed }}</b></v-col>
                <v-col cols="12"
                       sm="3">
                    <v-row style="margin: 0;">
                        <v-col cols="12"
                               sm="12"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][0].location || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][0].startingHour || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][0].endingHour || '-' }}</v-col>
                    </v-row>
                </v-col>
                <v-col cols="12"
                       sm="3">
                    <v-row style="margin: 0;">
                        <v-col cols="12"
                               sm="12"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][1].location || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][1].startingHour || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][1].endingHour || '-' }}</v-col>
                    </v-row>
                </v-col>
                <v-col cols="12"
                       sm="3">
                    <v-row style="margin: 0;">
                        <v-col cols="12"
                               sm="12"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][2].location || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][2].startingHour || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][2].endingHour || '-' }}</v-col>
                    </v-row>
                </v-col>
                <!-- <v-col cols="12"
                       sm="2">
                    <v-row style="margin: 0;">
                        <v-col cols="12"
                               sm="12"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][3].location || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][3].startingHour || '-' }}</v-col>
                        <v-col cols="12"
                               sm="6"
                               style="text-align: center;"
                               class="tcell">{{ weekdaysAllocation[row.name][3].endingHour || '-' }}</v-col>
                    </v-row>
                </v-col> -->
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "AthViewLocationMetadataComponent",
    props: ["weekdaysAllocation", "highlightday"],
    data: function () {
        return {
            rows: [
                { id: 1, name: "lunedi", displayed: "Lunedì", enabled: true },
                { id: 2, name: "martedi", displayed: "Martedì", enabled: true },
                {
                    id: 3,
                    name: "mercoledi",
                    displayed: "Mercoledì",
                    enabled: true,
                },
                { id: 4, name: "giovedi", displayed: "Giovedì", enabled: true },
                { id: 5, name: "venerdi", displayed: "Venerdì", enabled: true },
            ],
            cols: [
                { id: 0, name: "Giorno" },
                { id: 1, name: "Orario" },
                { id: 2, name: "Orario" },
                { id: 3, name: "Orario" },
                // { id: 4, name: "Orario" },
            ],
            // weekdaysAllocation: {
            //     "lunedi": [{location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}],
            //     "martedi": [{location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}],
            //     "mercoledi": [{location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}],
            //     "giovedi": [{location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}],
            //     "venerdi": [{location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}, {location: "", startingHour: "", endingHour: ""}]
            // }
        };
    },
};
</script>

<style scoped>
.twhite {
    color: black !important;
    border-radius: 1rem;
    font-weight: bold;
}

.bprimary {
    border: #75bff5 2px solid;
    background-color: #E3F2FD;
}

.tcell {
    border-bottom: 3px solid lightgray;
}
</style>