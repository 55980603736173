<template>
    <v-row>
        <v-col cols="12"
               md="9">
            <v-card color="blue lighten-5">
                <v-card-text>
                    <b>La ricerca funziona per parole intere, quindi scrivere "ecografia" per trovare ecografia, e non "eco".
                        Esempio: ecografia polmonare </b>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12"
               md="9">
            <v-autocomplete outlined
                            clearable
                            color="warning"
                            v-on:change="handleSelectVisitType"
                            :items="visits"
                            :item-text="itemText"
                            :item-value="itemValue"
                            :loading="isLoading"
                            :search-input.sync="search"
                            :filter="filter"
                            prepend-inner-icon="mdi-account"
                            label="Ricerca Impegnativa"
                            no-data-text="Scrivi per cercare"
                            ref="selectVisitTypeRef">
                <template v-slot:selection="{ item }">
                    <span><b class="primary--text">{{ item.description }}</b></span>
                </template>
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title><b>{{ item.description }}</b></v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12"
               md="3">
            <v-select :items="['Tutte', 'Non Specificata', 'Abruzzo', 'Sicilia', 'Lazio', 'Umbria', 'Molise', 'Trentino Alto Adige', 'Friuli Venezia Giulia']"
                      :menu-props="{ maxHeight: '400' }"
                      label="Regione"
                      outlined
                      multiple
                      clearable
                      hint="Seleziona Regione"
                      persistent-hint></v-select>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthSelectVisitType",
    props: {
        toggleClear: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        // looks okay as it is, because search is done server-side.
        // in case, refer to: https://vuetifyjs.com/en/api/v-autocomplete/#props-filter
        // and: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40
        filter: (item, queryText, itemText) => {
            // console.info({item, queryText, itemText});
            item;
            queryText;
            itemText;
            // return itemText.toUpperCase().replace(/ /g,'').indexOf(queryText.toUpperCase().replace(/ /g,'')) > -1
            return true;
        },
        // these two cannot be inlined as in the other component because of https://github.com/vuetifyjs/vuetify/issues/6635#issuecomment-528444479
        itemText: function (item) {
            return item.description;
        },
        // the return type must be a primitive and apparenty an array or an object are not okay
        // so we return a json string containing the whole element
        itemValue: function (item) {
            return JSON.stringify(item);
        },
        handleSelectVisitType($event) {
            this.$emit("ath-select-visit-type", $event);
            return $event;
        },
        handleInput(ev) {
            ev;
        },
    },
    data: () => ({
        isLoading: false,
        visits: [],
        search: null,
    }),
    watch: {
        search(val) {
            if (this.isLoading) return;

            let formattedVal = String(val);

            // remove last space to prevent issues like "tac,"
            if (formattedVal[formattedVal.length - 1] == " ")
                formattedVal = formattedVal.slice(0, length - 2);

            formattedVal = formattedVal.replaceAll(" ", ",");

            if (val && val.length >= 3) {
                this.isLoading = true;
                AthWebClient.get("/visits/search?terms=" + formattedVal)
                    .then((res) => {
                        this.visits = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => (this.isLoading = false));
            }
        },
        toggleClear: {
            handler: function (t) {
                t;
                this.$refs.selectVisitTypeRef.reset();
            },
        },
    },
};
</script>
