<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   :md="dashboardMode ? 12 : 8"
                   :offset-md="dashboardMode ? 0 : 2">
                <v-card hover>
                    <p class="text-h4 text-center text--primary"
                       style="padding: 10px 0px 10px 0px;">Prescrizioni Cancellate</p>
                </v-card>
                <ath-appointments-filters :dashboard-mode="dashboardMode"
                                          :doctors="doctors"
                                          :doctor-filter-enabled="doctorFilterEnabled"
                                          v-on:ath-appointemnts-filter-apply-filters="
                        handleFilterUpdate($event)
                    "></ath-appointments-filters>
                <br />
                <ath-prescriptions-table :headers="headers"
                                         :events="prescriptions"
                                         :total="prescriptionsTotal"
                                         :loading="loading"
                                         v-on:ath-pagination-update="handlePaginationUpdate"
                                         :page="page">
                </ath-prescriptions-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog"
                  max-width="600px">
            <ath-view-prescription :item="prescriptionDetail"
                                   v-on:ath-close-view-prescription="dialog = false;">
            </ath-view-prescription>
        </v-dialog>
    </v-container>
</template>

<script>
import AthPrescriptionsTable from "../ath-prescriptions/ath-prescriptions-table.vue";
import AthPrescriptionsService from "../ath-prescriptions/ath-prescriptions.service";
import AthAppointmentsFilters from "../ath-appointments/ath-appointments-filters/ath-appointments-filters.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthViewPrescription from "../ath-view-prescription/ath-view-prescription.vue";

export default {
    name: "AthDeletedPrescriptions",
    components: {
        AthPrescriptionsTable,
        AthAppointmentsFilters,
        AthViewPrescription,
    },
    props: {
        dashboardMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.loading = true;

        if (this.dashboardMode) {
            this.filters.showOnlyToday = true;
        }

        AthPrescriptionsService.getPrescriptionsDeletedAuto(
            this.page,
            this.perPage,
            this.filters
        ).then((data) => {
            this.prescriptions = data.data;
            this.prescriptionsTotal = data.total;
            this.loading = false;
        });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        handleViewDetails(prescription) {
            this.prescriptionDetail = prescription;
            this.dialog = true;
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthPrescriptionsService.getPrescriptionsDeletedAuto(
                this.page,
                this.perPage,
                this.filters
            ).then((data) => {
                this.prescriptions = data.data;
                this.prescriptionsTotal = data.total;
                this.loading = false;
            });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
    },
    data: () => ({
        // data for the appointments table
        prescriptions: [],
        prescriptionsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Paziente", value: "patient" },
            { text: "Dottore", value: "doctor" },
            { text: "Data", value: "date" },
            { text: "Completata", value: "done" },
            // { text: "Chiusura Veloce", value: "closeReason" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            from: null,
            end: null,
            doctorId: null,
            sortByDate: null,
            showOnlyToday: null,
            showOnlyCompleted: null,
        },

        // details
        prescriptionDetail: null,

        // details dialog
        dialog: false,

        // dummy for change detection
        dummy: false,

        prescriptionToEdit: false,

        editDialog: false,

        successEditMessage: false,

        deleteModal: false,

        deleteReason: null,

        prescriptionToDelete: null,
    }),
};
</script>
