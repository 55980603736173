<template>
    <v-container fluid
                 style="padding: 1rem;"
                 v-if="patient">
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Paziente modificato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Nome"
                              prepend-inner-icon="mdi-account"
                              outlined
                              v-model="patient.firstName">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Cognome"
                              prepend-inner-icon="mdi-account"
                              outlined
                              v-model="patient.lastName">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Data di Nascita"
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              v-model="patient.birthdate">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Indirizzo"
                              prepend-inner-icon="mdi-map-marker"
                              outlined
                              v-model="patient.address">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Email"
                              prepend-inner-icon="mdi-email"
                              outlined
                              v-model="patient.email">
                </v-text-field>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-text-field label="Telefono"
                              prepend-inner-icon="mdi-phone"
                              outlined
                              v-model="patient.phone">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-textarea outlined
                            prepend-inner-icon="mdi-note"
                            label="Note"
                            rows="2"
                            v-model="patient.notes"></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-text-field label="Medico Curante"
                              readonly
                              outlined
                              prepend-inner-icon="mdi-stethoscope"
                              :value="((doctor ? (doctor.firstName || 'PAZIENTE') : '') + ' ' + (doctor ? (doctor.lastName || 'GENERICO') : ''))"
                              background-color="rgba(113,124,141,0.14)">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-switch
                    color="info"
                    inset
                    v-model="changeDoctor"
                            label="Associare un nuovo dottore?"></v-switch>
            </v-col>
            <v-col v-if="changeDoctor"
                   cols="12"
                   md="12">
                <ath-select-doctor v-on:ath-select-doctor="patient.doctorUUID = $event"
                                   :doctors="doctors"></ath-select-doctor>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="12">
                <v-btn @click="editPatient()"
                       color="primary">Salva Modifiche</v-btn>
                &nbsp;
                <v-btn raised @click="$emit('ath-edit-patient-cancel')">Annulla</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthPatientsService from "../../shared/ath-patients.service";
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import moment from "moment";

export default {
    components: { athSelectDoctor },
    name: "AthEditPatient",
    props: {
        patientUUID: {
            type: String,
            required: false,
        },
    },
    watch: {
        patientUUID: {
            handler: function (p) {
                if (p == null) return;
                AthPatientsService.getPatientByUUID(p)
                    .then((res) => {
                        this.patient = res;

                        this.patient.birthdate = this.getBirthdate(this.patient.birthdate);

                        AthPatientsService.getDoctorByPatientUUID(p)
                            .then((res2) => {
                                this.doctor = res2;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    },
    mounted() {
        AthPatientsService.getPatientByUUID(this.patientUUID)
            .then((res) => {
                this.patient = res;

                this.patient.birthdate = this.getBirthdate(this.patient.birthdate);

                AthPatientsService.getDoctorByPatientUUID(this.patientUUID)
                    .then((res2) => {
                        this.doctor = res2;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        editPatient() {
            let payload = Object.assign({}, this.patient);

            if (
                this.patient.doctor &&
                !this.patient.isGeneric &&
                !this.patient.doctorUUID
            ) {
                payload.doctorUUID = this.patient.doctor.uuid;
            }

            payload.birthdate = moment(payload.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");

            AthPatientsService.edit(payload, this.patient.uuid)
                .then((res) => {
                    this.successMessage = true;
                    this.$emit("ath-edit-patient-success", res);
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la modifica del paziente",
                    });
                });
        },
        getBirthdate(d) {
            let md = moment(d, "YYYY-MM-DD");

            if(md.isValid) 
                return md.format("DD/MM/YYYY");
            else
                return md;
        },
    },
    data: () => ({
        patient: {},
        doctor: null,
        doctors: [],
        changeDoctor: false,
        successMessage: false,
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
