import axios from 'axios';
import store from '../store/store';

const instance = axios.create();

instance.defaults.baseURL = process.env.VUE_APP_API_URL;
instance.defaults.headers.common['X-Athena-Client-Version'] = "Athena v2.0";
instance.defaults.headers.common['Authorization'] = "Bearer " + store.state.user.loginToken;
instance.defaults.timeout = 15000;

instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response && error.response.status == 401) {
        // the request was made and the server responded with a status code
        // that indicates an expired token
        store.commit("user.logout");

        // you little bastard, refresh loop
        if(window.location.pathname != "/login") window.location.href = "/login";
      }
      else return Promise.reject(error);
  });

export default instance;