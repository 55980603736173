<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Blocco Calendario creato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               sm="12"
               md="10"
               offset-md="1">
            <v-container>
                <v-card hover
                        class="text-center">
                    <v-card-title class="text-h4"
                                  style="justify-content: center;">Crea Blocco <br class="d-sm-none"> Calendario</v-card-title>
                    <v-card-subtitle>Riserva un intervallo di tempo in cui non possono essere creati appuntamenti/prescrizioni.</v-card-subtitle>
                </v-card>
            </v-container>
            <v-container>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   class="text-h5 text-center info--text mt-2"><span>Seleziona Dottore e Motivazione</span>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12"
                                   sm="6">
                                <ath-select-doctor label="Ricerca Dottore"
                                                   :doctors="doctors"
                                                   v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                            </v-col>
                            <v-col cols="12"
                                   sm="6">
                                <v-select outlined
                                          label="Seleziona Tipologia Blocco"
                                          :items="[
                                        { text: 'Vacanza', value: 'HOLIDAYS' },
                                        { text: 'Ragione Generica', value: 'GENERIC_REASON' },
                                        { text: 'Pausa', value: 'PAUSE' },
                                    ]"
                                          v-model="type">
                                </v-select>
                            </v-col>
                            <v-col cols="12"
                                   sm="12">
                                <v-textarea rows="2"
                                            outlined
                                            label="Inserisci Motivazione"
                                            prepend-inner-icon="mdi-note"
                                            v-model="reason"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12">
                                <v-stepper v-model="step">
                                    <v-stepper-header>
                                        <v-stepper-step :complete="step > 1"
                                                        step="1">
                                            Seleziona Date
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step step="2"
                                                        :complete="step >= 2">
                                            Riepilogo & Conferma
                                        </v-stepper-step>
                                    </v-stepper-header>

                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <v-card >
                                                <v-card-text class="text-h5">Data Inizio</v-card-text>
                                                <v-date-picker v-model="startDate"
                                                               full-width
                                                               class="mt-4 cst mb-4"
                                                               no-title
                                                               color="#2196F3"
                                                               first-day-of-week="1"
                                                               style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>



                                                <v-card-text class="text-h5">Data Fine</v-card-text>
                                                <v-date-picker v-model="endDate"
                                                               full-width
                                                               class="mt-4 cst"
                                                               no-title
                                                               :allowed-dates="allowedDates"
                                                               color="#2196F3"
                                                               first-day-of-week="1"
                                                               style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>
                                                <br />
                                                <v-card class="text-center"
                                                        color="blue lighten-5">
                                                    <v-card-text>
                                                            I giorni in grigio sono disabilitati ed indicano che bisogna selezionare una data di fine superiore alla data di inzio
                                                    </v-card-text>
                                                </v-card>
                                            </v-card>

                                            <v-divider></v-divider>

                                            &nbsp;

                                            <v-row>
                                                <v-col cols="12"
                                                       sm="12"
                                                       md="6">
                                                    <v-card hover>
                                                        <v-card-text>
                                                    <h3 class="text-center">Ora Inizio</h3>
                                                        </v-card-text>
                                                    <br />
                                                    <ath-timeslots-component v-if="doctor"
                                                                             :day="startDate"
                                                                             :timeDivision="doctor.preferredIntervalTimeMins"
                                                                             :occupiedSlots="doctorOccupiedTimeSlotsStart"
                                                                             :startingTime="doctor.startTime"
                                                                             :endingTime="doctor.endTime"
                                                                             v-on:ath-select-time="handleSelectStartTime($event)">
                                                    </ath-timeslots-component>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12"
                                                       sm="12"
                                                       md="6">
                                                    <v-card hover>
                                                    <v-card-text>
                                                    <h3 class="text-center">Ora Fine</h3>
                                                    </v-card-text>
                                                    <br />
                                                    <ath-timeslots-component v-if="doctor"
                                                                             :day="endDate"
                                                                             :timeDivision="doctor.preferredIntervalTimeMins"
                                                                             :occupiedSlots="doctorOccupiedTimeSlotsEnd"
                                                                             :startingTime="doctor.startTime"
                                                                             :endingTime="doctor.endTime"
                                                                             v-on:ath-select-time="handleSelectEndTime($event)">
                                                    </ath-timeslots-component>
                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                            <v-btn color="primary"
                                                   @click="step = 2"
                                            class="mt-5">
                                                Continua
                                                <v-icon right>
                                                    mdi-arrow-right
                                                </v-icon>
                                            </v-btn>
                                        </v-stepper-content>

                                        <v-stepper-content step="2">
                                            <h3 class="text-h3">Riepilogo</h3>
                                            <br />
                                            <div v-if="doctor">
                                                <p>
                                                    Dottore:
                                                    <b style="font-size: 1.2rem;"
                                                       class="primary--text">{{
                                                            doctor.firstName +
                                                                " " +
                                                                doctor.lastName
                                                        }}</b>
                                                </p>

                                                <p>
                                                    Data Inizio:
                                                    <b style="font-size: 1.2rem;"
                                                       class="primary--text">{{formatDate(startDate)}}
                                                        {{ startTime }}</b>
                                                </p>

                                                <p>
                                                    Data Fine:
                                                    <b style="font-size: 1.2rem;"
                                                       class="primary--text">{{formatDate(endDate)}}
                                                        {{ endTime }}</b>
                                                </p>

                                                <p>
                                                    Motivazione:
                                                    <b style="font-size: 1.2rem;"
                                                       class="primary--text">{{ getText(type) }}
                                                    </b>
                                                </p>

                                                <p>
                                                    Note:
                                                    <b style="font-size: 1.2rem;"
                                                       class="primary--text">{{ reason }}
                                                    </b>
                                                </p>
                                            </div>

                                            <v-btn color="error"
                                                   @click="step = 1">
                                                <v-icon left>
                                                    mdi-arrow-left
                                                </v-icon>
                                                Indietro
                                            </v-btn>

                                            &nbsp;

                                            <v-btn color="primary"
                                                   @click="create()">
                                                Conferma
                                            </v-btn>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
// TODO FIXME: Add already-present blocks to show into the timeslots
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthTimeslotsComponent from "../ath-timeslots-component/ath-timeslots-component.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthWebClient from "../../shared/ath-webclient";
import moment from "moment";

export default {
    name: "AthCreateCalendarBlock",
    components: { athSelectDoctor, AthTimeslotsComponent },
    watch: {
        startDate: {
            handler: function (newStartDate) {
                if (!this.doctor) return;
                if (!newStartDate) return;

                this.updateTimeslots(newStartDate, this.endDate);
            },
        },
        endDate: {
            handler: function (newEndDate) {
                if (!this.doctor) return;
                if (!newEndDate) return;

                this.updateTimeslots(this.startDate, newEndDate);
            },
        },
        doctor: {
            handler: function (newDoctor) {
                if (!newDoctor) return;

                this.updateTimeslots(this.startDate, this.endDate);
            },
        },
    },
    methods: {
        allowedDates(val) {
            return moment(val).isSameOrAfter(moment(this.startDate));
        },
        updateTimeslots(start, end) {
            AthDoctorsService.getDoctorsOccupiedTimeSlot(
                this.doctor.uuid,
                start
            )
                .then((data) => {
                    this.doctorOccupiedTimeSlotsStart = data;
                })
                .catch((err) => {
                    console.error(err);
                });

            AthDoctorsService.getDoctorsOccupiedTimeSlot(this.doctor.uuid, end)
                .then((data) => {
                    this.doctorOccupiedTimeSlotsEnd = data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getText(t) {
            if (t == null) return;
            if (t.text) return t.text;

            if (t == "HOLIDAYS") return "Vacanza";
            if (t == "GENERIC_REASON") return "Ragione Generica";
            if (t == "PAUSE") return "Pausa";
        },
        formatDate(d) {
            return moment(d).format("DD-MM-YYYY");
        },
        handleSelectDoctor(uuid) {
            this.doctor = this.doctors.filter((d) => d.uuid == uuid)[0];
        },
        handleSelectStartTime(time) {
            this.startTime = time;
        },
        handleSelectEndTime(time) {
            this.endTime = time;
        },
        create() {
            let url = "/calendar-blocks/";

            // if (
            //     !this.dates ||
            //     this.dates.length < 2 ||
            //     !this.doctor ||
            //     !this.doctor.uuid
            // ) {
            //     this.$swal({
            //         icon: "error",
            //         title: "Errore",
            //         text: "Compilare tutti i campi prima di creare il blocco",
            //     });
            // }

            let fmtStart = this.startDate + " " + this.startTime;
            let fmtEnd = this.endDate + " " + this.endTime;

            console.info(this.doctor);

            let data = {
                blockType: this.type,
                doctorUUID: this.doctor.uuid,
                startDateUtc: moment(fmtStart, "YYYY-MM-DD HH:mm").format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                ),
                endDateUtc: moment(fmtEnd, "YYYY-MM-DD HH:mm").format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                ),
                reason: this.reason,
            };

            AthWebClient.post(url, data)
                .then((res) => {
                    res;
                    this.successMessage = true;

                    this.$router.push("/calendar-blocks");
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Impossibile creare il blocco richiesto! Riprovare",
                    });
                });
        },
    },
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    data: () => ({
        doctor: null,
        doctors: [],
        dates: [],
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        startTime: null,
        endTime: null,
        reason: null,
        step: 1,
        type: null,
        doctorOccupiedTimeSlotsStart: [],
        doctorOccupiedTimeSlotsEnd: [],
        successMessage: false,
    }),
};
</script>

<style>
.cst button.v-btn--disabled {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.cst {
    color: white !important;
}
</style>