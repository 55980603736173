<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-container fluid>
                    <v-card hover>
                        <v-card-title class="text-h4 text-center"
                                      style="justify-content: center; margin-top: 2rem; color: #253c99">
                            Invia una <br class="d-sm-none"> segnalazione
                        </v-card-title>
                    </v-card>
                </v-container>
                <v-container>
                <v-card>
                    <v-card-title>
                        Segnalazione Problemi <br class="d-sm-none"> e Migliorie
                    </v-card-title>
                    <v-card-text class="black--text">
                        Il tuo feedback è per noi molto prezioso: utilizza quest'area per condividere opinioni, segnalare problemi, e richiedere nuove funzioni che vorresti vedere all'interno del software.
                        <br />
                        <br />
                        Ogni segnalazione verrà analizzata con attenzione e se coerente con la logica del software e con il metodo di lavorare che hanno anche altri nostri clienti la inseriremo nella lista delle revisioni future.
                        <br />
                        <br />
                        Gli aggiornamenti dedicati alla risoluzione problemi e malfuzionamenti verranno effettuati in maniera totalmente automatizzata: qualora sia necessario, ti invitiamo comunque ad aggiornare periodicamente la cache del browser tramite la combinazione di tasti <u>Ctrl + F5.</u>
                        <br />
                        <br />
                        <b>ATTENZIONE: In caso di problematiche di natura <u>bloccante</u> ti invitiamo a contattarci telefonicamente per una risoluzione tempestiva.</b>
                        <br />
                        <br />
                        <v-divider></v-divider>
                        <br />
                        <v-container>
                            <v-form ref="form">
                                <v-row>
                                    <v-col cols="12"
                                           sm="12">
                                        <h3>Dettagli Segnalazione</h3>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="12"
                                           md="12">
                                        <v-select prepend-inner-icon="mdi-clipboard-text-outline"
                                                  outlined
                                                  label="Tipologia"
                                                  :items="[{text: 'Malfunzionamento', value: 'BUG'}
                                            , {text: 'Problema Generale', value: 'GENERAL_PROBLEM'}
                                            , {text: 'Miglioria', value: 'ENHANCEMENT'}
                                            , {text: 'Altro', value: 'OTHER'}]">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="12"
                                           md="12">
                                        <v-textarea outlined
                                                    prepend-inner-icon="mdi-message"
                                                    label="Messaggio">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12"
                                   sm="2"
                                   offset-sm="5">
                                <v-btn block
                                       color="info">Invia Segnalazione</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "AthCreateBugReport",
    data: () => ({}),
    mounted() {},
    methods: {},
    watch: {},
};
</script>

<style scoped>
</style>