<template>
    <v-container fluid>
        <v-row class="pt-15"
               dense
               no-gutters>
            <v-col cols="12"
                   md="6">
                <ath-appointments :dashboard-mode="true"></ath-appointments>
            </v-col>
            <v-col cols="12"
                   md="6">
                <ath-prescriptions :dashboard-mode="true"></ath-prescriptions>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import AthAppointments from "../components/ath-appointments/ath-appointments.vue";
import AthPrescriptions from "../components/ath-prescriptions/ath-prescriptions.vue";
export default {
    name: "AthDashboardView",
    components: { AthAppointments, AthPrescriptions },
    data: () => ({
        fab: false,
    }),
    methods: {},
};
</script>