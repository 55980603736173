import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import athWebClient from "./shared/ath-webclient.js";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);

import store from './store/store';

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        this.$store.commit('store.load.saved');
        athWebClient.defaults.headers.common['Authorization'] = "Bearer " + this.$store.state.user.loginToken;
    }
}).$mount('#app')
