<template>
    <v-container fluid>
        <v-container fluid
                     class="text-h6">
            <span v-if="!loading">
                <v-alert v-if="notification"
                         type="info">{{ notification }}</v-alert>

                <!--                <v-card-text-->
                <!--                      class="text-h5">Riepilogo</v-card-text>-->

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    {{ atype == "WITH_REPRESENTATIVE" ? "Informatore" : "Paziente" }}
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Dottore
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Data
                                </th>
                                <th class="text-left"
                                    style="font-size: 1.1rem">
                                    Ora
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b v-bind:class="{'success--text': atype == 'WITH_REPRESENTATIVE'}">
                                        {{ (old.tmpPatient ? old.tmpPatient.firstName : '')  + " " + (old.tmpPatient ? old.tmpPatient.lastName : '') }}
                                    </b></td>
                                <td><b>{{ (old.doctor ? old.doctor.firstName : '') + " " + (old.doctor ? old.doctor.lastName : '') }}</b></td>
                                <td><b>{{ appointmentDate }}</b></td>
                                <td><b>{{ appointmentTime }}</b></td>
                            </tr>
                            <v-card-text class="mt-10">
                                <h4 class="d-flex text-center justify-center">Cosa si desidera modificare? (Scelta Multipla)</h4>
                            </v-card-text>
                            <tr>
                                <td>
                                    <v-switch color="info"
                                              inset
                                              label="Paziente"
                                              v-if="atype == 'WITH_PATIENT'"
                                              v-model="edit.patient"></v-switch>
                                    <v-switch color="info"
                                              inset
                                              label="Informatore"
                                              v-if="atype == 'WITH_REPRESENTATIVE'"
                                              v-model="edit.representative"></v-switch>
                                </td>
                                <td>
                                    <v-switch color="info"
                                              inset
                                              label="Dottore"
                                              v-if="$store.state.user.loginRole >= 5"
                                              v-model="edit.doctor"></v-switch>
                                </td>
                                <td>
                                    <v-switch color="info"
                                              inset
                                              label="Data"
                                              v-model="edit.date"></v-switch>
                                </td>
                                <td>
                                    <v-switch color="info"
                                              inset
                                              label="Ora"
                                              v-model="edit.time"></v-switch>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <br />

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.patient">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Paziente</div>
                    <br />
                    <ath-select-patient style="min-width: 50%"
                                        :doctorUUID="doctorUUID"
                                        v-on:ath-select-patient="handleSelectPatient($event)"></ath-select-patient>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.representative">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Informatore</div>
                    <br />
                    <ath-select-representative style="min-width: 50%"
                                               v-on:ath-select-representative="handleSelectRepresentative($event)"></ath-select-representative>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.doctor && $store.state.user.loginRole >= 5">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Dottore</div>
                    <br />
                    <ath-select-doctor :doctors="doctors"
                                       v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                </v-container>

                <v-container fluid
                             style="padding: 0px"
                             v-if="edit.date">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Data</div>
                    <br />
                    <v-date-picker v-model="modified.appointmentDate"
                                   full-width
                                   class="mt-4"
                                   no-title
                                   color="#2196f3"
                                   first-day-of-week="1"
                                   style="border: 1px solid lightgray; border-radius: 0.3rem"></v-date-picker>
                </v-container>

                <v-container fluid
                             style="padding-top: 30px"
                             v-if="edit.time">

                    <div style="text-decoration: underline 3px rgb(33, 150, 243);"
                         class="text-h5">Modifica Orario</div>
                    <br />
                    <ath-timeslots-component :day="modified.appointmentDate"
                                             :timeDivision="modified.doctorPreferredIntervalTime"
                                             :occupiedSlots="doctorOccupiedTimeSlots"
                                             :startingTime="doctorStartTime"
                                             :endingTime="doctorEndTime"
                                             v-on:ath-select-time="modified.appointmentTime = $event"></ath-timeslots-component>
                </v-container>

                <v-container fluid
                             style="padding: 0px">

                    <div style="text-decoration: underline 3px #ff5252;"
                         class="text-h5">Motivazione</div>
                    <br />
                    <v-text-field label="Motivazione Modifica"
                                  outlined
                                  v-model="modified.editReason"></v-text-field>
                </v-container>

                <br />
                <br />
                <br />
            </span>
            <div class="d-flex justify-space-between">
                <v-btn raised
                       color="error"
                       @click="$emit('ath-cancel-edit-appointment', true)">
                    Annulla
                </v-btn>
                &nbsp;
                <v-btn color="success"
                       @click="editAppointment()"
                       :disabled="!modified.editReason || modified.editReason.length < 3"
                       raised>
                    Conferma Modifiche
                </v-btn>
            </div>
            <v-overlay color="white"
                       absolute
                       :value="loading"
                       light
                       opacity="0.7">
                <v-progress-circular color="info"
                                     indeterminate
                                     size="128">
                </v-progress-circular>
            </v-overlay>
        </v-container>
    </v-container>
</template>

<script>
import moment from "moment";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthSelectPatient from "../ath-select-patient/ath-select-patient.vue";
import AthTimeslotsComponent from "../ath-timeslots-component/ath-timeslots-component.vue";
import AthSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import athAppointmentsService from "../ath-appointments/ath-appointments.service";
import AthSelectRepresentative from "../ath-select-representative/ath-select-representative.vue";

export default {
    components: {
        AthSelectPatient,
        AthTimeslotsComponent,
        AthSelectDoctor,
        AthSelectRepresentative,
    },
    name: "AthEditAppointment",
    props: ["appointmentUUID"],
    data: () => ({
        doctors: [],
        clearSelectDoctor: false,
        doctorUUID: null,
        doctor: null,

        doctorStartTime: null,
        doctorEndTime: null,
        doctorOccupiedTimeSlots: [],

        loading: false,

        successMessage: false,

        atype: null,

        old: {
            doctorUUID: null,
            doctor: null,
            patientUUID: null,
            representativeUUID: null,
            doctorPreferredIntervalTime: null,
            startDatetimeUtc: null,
            endDatetimeUtc: null,
            notes: null,
            tmpPatient: {
                firstName: null,
                lastName: null,
            },
            appointmentDate: null,
            appointmentTime: null,
        },

        modified: {
            doctorUUID: null,
            doctor: null,
            patientUUID: null,
            representativeUUID: null,
            doctorPreferredIntervalTime: null,
            startDatetimeUtc: null,
            endDatetimeUtc: null,
            notes: null,
            tmpPatient: {
                firstName: null,
                lastName: null,
            },
            appointmentDate: null,
            appointmentTime: null,
            editReason: null,
        },

        edit: {
            patient: false,
            doctor: false,
            date: false,
            time: false,
        },

        isValid: false,

        notification: null,
    }),
    computed: {
        appointmentDate: {
            get() {
                return moment(this.old.startDatetimeUtc).format("DD-MM-YYYY");
            },
            set() {},
        },
        appointmentTime: {
            get() {
                return moment(this.old.startDatetimeUtc).format("HH:mm");
            },
            set() {},
        },
    },
    mounted: function () {
        this.loading = true;

        // load doctors
        AthDoctorsService.getDoctorsV2()
            .then((data) => {
                this.doctors = data;

                athAppointmentsService
                    .getAppointment(this.appointmentUUID)
                    .then((res) => {
                        // console.info(res);
                        this.setAData(res);

                        AthDoctorsService.getDoctorStartEndTime(
                            this.modified.doctorUUID
                        )
                            .then((data) => {
                                this.doctorStartTime = data.startTime;
                                this.doctorEndTime = data.endTime;

                                AthDoctorsService.getDoctorsOccupiedTimeSlot(
                                    this.modified.doctorUUID,
                                    this.modified.appointmentDate
                                )
                                    .then((data2) => {
                                        this.doctorOccupiedTimeSlots = data2;
                                        this.loading = false;
                                        this.$forceUpdate();
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.loading = false;
                            });

                        this.loading = false;
                    })
                    .catch((err) => {
                        err;
                        this.loading = false;
                        this.$swal({
                            icon: "error",
                            title: "Errore",
                            text: "Impossibile caricare l'appuntamento da modificare! Riprovare",
                        });
                    });
            })
            .catch((err) => {
                err;
                this.loading = false;
                this.doctors = [];
            });
    },
    watch: {
        "modified.appointmentDate": {
            handler: function(nd) {
                AthDoctorsService.getDoctorsOccupiedTimeSlot(
                                    this.modified.doctorUUID,
                                    nd
                                )
                                    .then((data2) => {
                                        this.doctorOccupiedTimeSlots = data2;
                                        this.loading = false;
                                        this.$forceUpdate();
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
            }
        }
    },
    methods: {
        setAData(res) {
            this.atype = res.atype;
            if (res.atype == "WITH_PATIENT") {
                this.old.patientUUID = res.puuid;
                this.old.doctorUUID = res.uuuid;
                this.old.doctorPreferredIntervalTime = res.adurationMins;

                this.old.doctor = this.doctors.filter(
                    (d) => d.uuid == this.old.doctorUUID
                )[0];

                this.old.tmpPatient.firstName = res.pfirstName;
                this.old.tmpPatient.lastName = res.plastName;
                this.old.startDatetimeUtc = moment(res.astart).format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                );

                let endDateTime = moment(res.astart).clone();

                endDateTime = endDateTime.add(
                    this.old.doctorPreferredIntervalTime,
                    "minutes"
                );

                this.old.endDatetimeUtc = endDateTime.format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                );

                // variables for date and time pickers
                this.old.appointmentDate = moment(res.astart).format(
                    "YYYY-MM-DD"
                );

                this.old.appointmentTime = moment(res.astart).format("HH:mm");

                this.modified = Object.assign({}, this.old);
            } else if (res.atype == "WITH_REPRESENTATIVE") {
                this.old.representativeUUID = res.ruuid;
                this.old.doctorUUID = res.uuuid;
                this.old.doctorPreferredIntervalTime = res.adurationMins;

                this.old.doctor = this.doctors.filter(
                    (d) => d.uuid == this.old.doctorUUID
                )[0];

                this.old.tmpPatient.firstName = res.rfirstName;
                this.old.tmpPatient.lastName = res.rlastName;
                this.old.startDatetimeUtc = moment(res.astart).format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                );

                let endDateTime = moment(res.astart).clone();

                endDateTime = endDateTime.add(
                    this.old.doctorPreferredIntervalTime,
                    "minutes"
                );

                this.old.endDatetimeUtc = endDateTime.format(
                    "YYYY-MM-DD[T]HH:mm:ssZ"
                );

                // variables for date and time pickers
                this.old.appointmentDate = moment(res.astart).format(
                    "YYYY-MM-DD"
                );

                this.old.appointmentTime = moment(res.astart).format("HH:mm");

                this.modified = Object.assign({}, this.old);
            }
        },
        editAppointment() {
            // check for issues
            if (this.modified.patientUUID == null || !this.edit.patient) {
                this.modified.patientUUID = this.old.patientUUID;
            }

            if (this.modified.doctorUUID == null || !this.edit.doctor) {
                this.modified.doctorUUID = this.old.doctorUUID;
            }

            this.modified.startDatetimeUtc = moment(
                this.modified.appointmentDate
            )
                .set("hour", this.modified.appointmentTime.split(":")[0])
                .set("minute", this.modified.appointmentTime.split(":")[1])
                .format("YYYY-MM-DD[T]HH:mm:ssZ");

            this.modified.endDatetimeUtc = moment(
                this.modified.startDatetimeUtc,
                "YYYY-MM-DD[T]HH:mm:ssZ"
            )
                .clone()
                .add(this.old.doctorPreferredIntervalTime, "minutes")
                .format("YYYY-MM-DD[T]HH:mm:ssZ");

            athAppointmentsService
                .edit(this.appointmentUUID, this.modified)
                .then((res) => {
                    this.$emit("ath-appointment-edit-success", res);
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la modifica dell'appuntamento!",
                    });
                });
        },
        handleSelectDoctor(doctorUUID) {
            this.modified.doctorUUID = doctorUUID;

            AthDoctorsService.getDoctorsOccupiedTimeSlot(
                                    this.modified.doctorUUID,
                                    this.modified.appointmentDate
                                )
                                    .then((data2) => {
                                        this.doctorOccupiedTimeSlots = data2;
                                        this.loading = false;
                                        this.$forceUpdate();
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
        },
        handleSelectPatient(payload) {
            let parsed = JSON.parse(payload);

            if (parsed == null) {
                this.modified.patientUUID = null;
                this.notification = null;
                return;
            }

            // patient is not generic, has an associated doctor
            if (parsed.pisGeneric == false) {
                if (parsed.uuuid != this.doctorUUID) {
                    this.notification =
                        "Questo paziente non appartiene a questo medico";
                }
                this.modified.patientUUID = parsed.puuid;
            }
            // patient is generic, no doctor associated, show the dropdown
            else {
                this.modified.patientUUID = parsed.puuid;
            }
        },
        handleSelectRepresentative(payload) {
            let parsed = JSON.parse(payload);
            this.modified.representativeUUID = parsed.uuid;
        },
    },
};
</script>

<style>
.v-date-picker-table .v-btn {
    font-size: 1rem !important;
}

.v-date-picker-table th {
    font-size: 1rem !important;
}

.v-date-picker-header__value button {
    text-transform: uppercase;
    font-size: 1.1rem !important;
}

.v-picker__body {
    padding-bottom: 1rem;
}

#quick-actions {
    display: flex;
}
@media (min-width: 960px) and (max-width: 1264px) {
    #quick-actions {
        display: flex;
        flex-direction: column;
        align-content: space-around;
    }
}
</style>
