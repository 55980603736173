<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Utente eliminato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               sm="12"
               md="8"
               offset-md="2">
            <v-card>
                <v-card-title>Utenti</v-card-title>
                <v-container>
                    <v-data-table :headers="headers"
                                  :items="users"
                                  :disable-sort="true"
                                  class="elevation-1">
                        <template v-slot:[`item.role`]="{ item }">
                            <v-chip :color="getColor(item.role.name)">
                                {{ getName(item.role.name) }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu bottom
                                    left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info"
                                           outlined
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>mdi-menu</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn color="primary"
                                                   @click="edit(item)">
                                                <v-icon>mdi-pencil</v-icon> Modifica
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn color="error"
                                                   @click="deleteUser(item.uuid)">
                                                <v-icon>mdi-delete</v-icon> Elimina
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-col>
        <v-dialog v-model="editDialog"
                  max-width="960px">
            <v-card>
                <v-card-title style="justify-content: space-between">
                    <span class="text-h5">Modifica Utente</span>
                    <v-icon large
                            @click="editDialog = false">mdi-close</v-icon>
                </v-card-title>
                <br />
                <v-card-text>
                    <ath-edit-user v-if="userToEdit"
                                   :modal="true"
                                   :user="userToEdit"
                                   v-on:ath-user-modified="refresh()"
                                   ></ath-edit-user>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";
import AthEditUser from "../ath-edit-user/ath-edit-user.vue";

export default {
    components: { AthEditUser },
    name: "AthUsers",
    props: ["modal"],
    methods: {
        getName(n) {
            if (n == "Doctor") return "Dottore";
            if (n == "Secretary") return "Segretaria";
            if (n == "Admin") return "Amministratore";
        },
        getColor(n) {
            if (n == "Doctor") return "primary";
            if (n == "Secretary") return "success";
            if (n == "Admin") return "error";
        },
        edit(u) {
            this.userToEdit = u;
            this.editDialog = true;
        },
        refresh() {
            this.editDialog = false;
            // hack
            location.reload();
        },
        deleteUser(u) {
            AthWebClient.delete("/users/" + u)
                .then((res) => {
                    res;
                    this.successMessage = true;

                    AthWebClient.get("/users/")
                        .then((data) => {
                            this.users = data.data;
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                })
                .catch((err) => {
                    err;
                    this.successMessage = false;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la cancellazione dell'utente",
                    });
                });
        },
    },
    mounted() {
        AthWebClient.get("/users/")
            .then((data) => {
                this.users = data.data;
            })
            .catch((err) => {
                console.error(err);
            });
    },
    data: () => ({
        users: [],
        userToEdit: null,
        editDialog: false,
        headers: [
            { text: "Nome", value: "firstName" },
            { text: "Cognome", value: "lastName" },
            { text: "email", value: "email" },
            { text: "Ruolo", value: "role" },
            { text: "Telefono", value: "phone" },
            { text: "Azione", value: "actions" },
        ],
        successMessage: false,
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
