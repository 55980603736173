<template>
    <v-card hover>
        <v-card-title>Filtri</v-card-title>
        <v-form v-model="valid">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="12"
                           xs="12"
                           sm="6"
                           :md="dashboardMode ? 6 : 6"
                           :lg="dashboardMode ? 6 : 3"
                           :xl="dashboardMode ? 2 : 4">
                        <v-menu v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="showOnlyToday">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formattedFrom"
                                              label="Dal"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                              outlined
                                              clearable
                                              :disabled="showOnlyToday"
                                              v-on:click:clear.stop="clearStart()"></v-text-field>
                            </template>
                            <v-date-picker v-model="from"
                                           no-title
                                           @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12"
                           xs="12"
                           sm="6"
                           :md="dashboardMode ? 6 : 6"
                           :lg="dashboardMode ? 6 : 3"
                           :xl="dashboardMode ? 2 : 4">
                        <v-menu v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="showOnlyToday">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formattedEnd"
                                              label="Al"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                              outlined
                                              clearable
                                              :disabled="showOnlyToday"
                                              v-on:click:clear.stop="clearEnd()"></v-text-field>
                            </template>
                            <v-date-picker v-model="end"
                                           no-title
                                           @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12"
                           xs="12"
                           sm="6"
                           :md="dashboardMode ? 6 : 6"
                           lg="6"
                           :xl="dashboardMode ? 2: 4">
                        <v-select outlined
                                  clearable
                                  :items="[{ text: 'Ascendente', value: 'asc' },{ text: 'Discendente', value: 'desc' },]"
                                  label="Ordina per data"
                                  :hide-details="true"
                                  v-model="sortByDate"></v-select>
                    </v-col>

                    <!-- doctor selection -->
                    <v-col cols="12"
                           xs="12"
                           sm="6"
                           :md="dashboardMode ? 6 : 6"
                           :lg="dashboardMode ? 6 : 12"
                           :xl="dashboardMode ? 6 : 12"
                           v-if="doctorFilterEnabled">
                        <ath-select-doctor :doctors="doctors"
                                           :clear-input-toggle="clearSelectDoctor"
                                           v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                    </v-col>

                    <v-col cols="12"
                           xs="12"
                           sm="6"
                           :md="dashboardMode ? 6 : 6"
                           :lg="dashboardMode ? 6 : 12"
                           :xl="dashboardMode ? 6 : 12">
                        <ath-select-patient :toggleClear="toggleClear"
                                            v-on:ath-select-patient="handleSelectPatient($event)"
                                            :doctorUUID="doctorId"></ath-select-patient>
                    </v-col>
                </v-row>
            </v-container>
            <v-card elevation="0">
                <v-container>
                    <v-row no-gutters
                           dense>
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               style="display: flex; justify-content: center;">
                            <v-switch v-model="showOnlyToday"
                                      filter
                                      inset
                                      outlined
                                      label="Solo Oggi"
                                      color="info">
                            </v-switch> &nbsp;&nbsp;&nbsp;
                            <v-switch v-model="showOnlyCompleted"
                                      filter
                                      inset
                                      outlined
                                      label="Solo Completati"
                                      color="info">
                            </v-switch> &nbsp;&nbsp;&nbsp;
                            <v-switch v-model="showOnlyNotCompleted"
                                      filter
                                      inset
                                      outlined
                                      label="Solo NON Completati"
                                      color="info">
                            </v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               :md="dashboardMode ? 6 : 6"
                               style="
                                display: flex;
                                align-content: flex-start;
                                justify-content: flex-end;
                                align-items: center;">
                            <v-btn large
                                   raised
                                   color="info"
                                   @click="filter()"
                                   class="mr-2">
                                Filtra
                            </v-btn>

                            <v-btn @click="resetFilters()"
                                   raised
                                   large
                                   color="red lighten-1"
                                   class="white--text">
                                Reset filtri
                            </v-btn>
                        </v-col>
                        <v-col cols="12"
                               sm="12"
                               xl="6">
                            <v-card rounded
                                    color="blue lighten-5">
                                <v-card-text class="d-flex justify-center">
                                    <span v-if="showOnlyToday">Stai visualizzando <b>solo</b> gli eventi di oggi</span>
                                    <span v-if="!showOnlyToday">Stai visualizzando <b>tutti</b> gli eventi esistenti, utilizza i filtri per raffinare la ricerca</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-form>
    </v-card>
</template>

<script>
/**
 * Appointment filter
 *
 * @props
 *  - doctors:Array<Doctors> array of doctor objects to pass to the ath-select-doctor component
 *  - doctorFilterEnabled:Boolean boolean that checks if the ath-select-doctor should be displayed
 *  - dashboardMode:Boolean indicates whether the component is displayed inside the dashboard and adjusts accordingly
 *
 * @events
 *  - ath-appointemnts-filter-apply-filters:void event that indicates that we should refresh the datasource with the appropriate filters selected
 *  - ath-appointemnts-filter-reset-filters:void event that indicates that we should refresh the datasource with no filters selected
 *  - INTERNAL: ath-select-doctor: id|null event that contains the doctor id used to filter for. This event is broadcasted but IS NOT TO BE USED outside of this component
 */

import moment from "moment";
import AthSelectDoctor from "../../ath-select-doctor/ath-select-doctor.vue";
import models from "../../../shared/ath-models";
import AthSelectPatient from "../../ath-select-patient/ath-select-patient.vue";

export default {
    components: { AthSelectDoctor, AthSelectPatient },
    name: "AthAppointmentsFilters",
    props: {
        doctors: {
            type: Array,
            required: true,
        },
        doctorFilterEnabled: {
            type: Boolean,
            default: false,
        },
        dashboardMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        if (this.dashboardMode) {
            this.showOnlyToday = true;
            this.initialized = [0];
        }
    },
    computed: {
        formattedFrom: {
            get() {
                return this.from ? moment(this.from).format("DD/MM/YYYY") : "";
            },
            set() {},
        },
        formattedEnd: {
            get() {
                return this.end ? moment(this.end).format("DD/MM/YYYY") : "";
            },
            set() {},
        },
    },
    watch: {
        showOnlyToday: {
            handler: function (nf) {
                nf;

                this.filter();
            },
        },
        showOnlyNotCompleted: {
            handler: function (sonc) {
                if (sonc) this.showOnlyCompleted = false;

                this.filter();
            },
        },
        showOnlyCompleted: {
            handler: function (soc) {
                if (soc) this.showOnlyNotCompleted = false;

                this.filter();
            },
        },
    },
    methods: {
        filter() {
            let filters = this.$_prepareFilters();

            this.$emit("ath-appointemnts-filter-apply-filters", filters);
        },
        resetFilters() {
            this.from = null;
            this.end = null;
            this.menu = false;
            this.menu2 = false;
            this.showOnlyToday = false;
            this.showOnlyCompleted = false;
            this.showOnlyNotCompleted = false;
            this.sortByDate = null;
            this.doctorId = null;
            this.patientId = null;

            // hack: toggle it (the value is not relevant) so the clear function gets called in the child component
            this.clearSelectDoctor = !this.clearSelectDoctor;

            this.toggleClear = !this.toggleClear;

            let filters = this.$_prepareFilters();

            this.$emit("ath-appointemnts-filter-reset-filters", filters);

            this.filter();
        },
        clearStart() {
            this.from = null;
        },
        clearEnd() {
            this.end = null;
        },
        handleSelectDoctor(doctorId) {
            this.doctorId = doctorId;
        },
        handleSelectPatient(patient) {
            if (patient == null) {
                this.patientId = null;
                return;
            }
            this.patientId = JSON.parse(patient).puuid;
        },
        $_prepareFilters() {
            let filters = models.filters;

            filters.from = this.from;
            filters.end = this.end;
            filters.doctorId = this.doctorId;
            filters.showOnlyToday = this.showOnlyToday;
            filters.showOnlyCompleted = this.showOnlyCompleted;
            filters.showOnlyNotCompleted = this.showOnlyNotCompleted;
            filters.sortByDate = this.sortByDate;
            filters.patientId = this.patientId;

            return filters;
        },
    },
    data: () => ({
        // dummy
        valid: true,

        // "starting from" and "ending on" date
        from: null,
        end: null,

        // ui-related
        menu: false,
        menu2: false,

        // doctor being filtered
        doctorId: null,

        // disable date selection when "showOnlyToday" switch is selected
        showOnlyToday: false,

        // "completed" switch
        showOnlyCompleted: false,

        // option that checks if the table should be sorted by date
        sortByDate: null,

        // toggle this value to trigger a clear of the doctor-select-component
        clearSelectDoctor: false,

        // "not completed" switch
        showOnlyNotCompleted: false,

        initialized: [],

        toggleClear: false,

        patientId: null,
    }),
};
</script>
