<template>
    <v-container fluid style="padding: 1rem;">
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    label="Nome"
                    prepend-inner-icon="mdi-account"
                    readonly
                    outlined
                    :value="representative.firstName"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    label="Cognome"
                    prepend-inner-icon="mdi-account"
                    readonly
                    outlined
                    :value="representative.lastName"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-text-field
                    label="Indirizzo"
                    prepend-inner-icon="mdi-map-marker"
                    readonly
                    outlined
                    :value="representative.address"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    label="Email"
                    prepend-inner-icon="mdi-email"
                    readonly
                    outlined
                    :value="representative.email"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    label="Telefono"
                    prepend-inner-icon="mdi-phone"
                    readonly
                    outlined
                    :value="representative.phone"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-textarea
                    outlined
                    readonly
                    prepend-inner-icon="mdi-note"
                    label="Note"
                    rows="2"
                    :value="representative.notes"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-text-field
                    label="Azienda Farmaceutica"
                    readonly
                    outlined
                    prepend-inner-icon="mdi-domain"
                    :value="representative.phCompany ? (representative.phCompany.name + ' | ' + representative.phCompany.address) : ''"
                    background-color="rgba(113,124,141,0.14)"
                >
                </v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthRepresentativeService from "../../shared/ath-representatives.service";

export default {
    name: "AthViewRepresentative",
    props: {
        representativeUUID: {
            type: String,
            required: false,
        },
    },
    mounted() {
        if (this.representativeUUID == null || this.representativeUUID == undefined) return;

        AthRepresentativeService.getByUUID(this.representativeUUID)
            .then((res) => {
                this.representative = res;
            })
            .catch((err) => {
                console.log(err);
            });
        //.finally(() => (this.isLoading = false));
    },
    methods: {
        getBirthdate: function(date) {
            //return moment(date).format("DD/MM/YYYY");
            return date;
        },
    },
    watch: {
        representativeUUID: function(p) {
            if (p == null || p == undefined) return;

            AthRepresentativeService.getByUUID(p)
                .then((res) => {
                    this.representative = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    data: () => ({
        representative: {},
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
