<template>
    <v-container>
        <v-row>
            <v-col cols="12"
                   md="10"
                   offset-md="1">
                <v-card>
                    <v-card-title>Invio Notifiche</v-card-title>
                    <v-card-text>
                        <v-tabs v-model="tab"
                                align-with-title>
                            <v-tabs-slider color="info"></v-tabs-slider>

                            <v-tab>
                                <v-icon>mdi-account</v-icon> &nbsp; Ad Un Utente Singolo
                            </v-tab>
                            <v-tab>
                                <v-icon>mdi-account-multiple</v-icon> &nbsp; Ad Utenti Multipli
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text class="black--text">
                                        <ath-select-doctor label="Ricerca Utente"
                                                           :doctors="doctors"
                                                           v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
                                        <v-textarea label="Testo notifica"
                                                    clearable
                                                    outlined
                                                    rows="4"
                                                    v-model="notificationText"></v-textarea>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn block
                                               color="primary"
                                               :disabled="!this.doctorUUID || (!this.notificationText || this.notificationText.length < 1)"
                                               @click="createSingle()">Invia Notifica</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <v-select v-model="docsUUID"
                                                  outlined
                                                  :items="doctors"
                                                  :item-text="item => item.firstName + ' ' + item.lastName"
                                                  :item-value="item => item.uuid"
                                                  label="Seleziona Utenti"
                                                  multiple
                                                  clearable
                                                  chips></v-select>
                                        <v-textarea label="Testo notifica"
                                                    clearable
                                                    outlined
                                                    rows="4"
                                                    v-model="notificationText2"></v-textarea>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn block
                                               color="primary"
                                               :disabled="(!this.docsUUID || this.docsUUID.length < 1) || (!this.notificationText || this.notificationText.length < 1)"
                                               @click="createMultiple()">Invia Notifica</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthCreateNotification",
    components: { athSelectDoctor },
    mounted() {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        handleSelectDoctor(uuid) {
            this.doctorUUID = this.doctors.filter((d) => d.uuid == uuid)[0];
        },
        createSingle() {
            AthWebClient.post(
                "/notifications/single/for-user/" + this.doctorUUID.uuid,
                {
                    message: this.notificationText,
                }
            )
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Notifica inviata con successo!",
                    });
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante l'invio della notifica",
                    });
                });
        },
        createMultiple() {
            AthWebClient.post("/notifications/mass", {
                message: this.notificationText2,
                users: this.docsUUID,
            })
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Notifiche inviate con successo!",
                    });
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante l'invio delle notifiche",
                    });
                });
        },
    },
    data: () => ({
        tab: null,
        doctors: [],
        doctorUUID: null,
        docsUUID: [],
        notificationText: null,
        notificationText2: null,
    }),
};
</script>