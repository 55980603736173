<template>
    <v-row>
        <v-col cols="12"
               sm="12"
               md="8"
               offset-md="2">
            <v-card>
                <v-card-title>Utenti Eliminati</v-card-title>
                <v-container>
                    <v-data-table :headers="headers"
                                  :items="users"
                                  class="elevation-1">
                        <template v-slot:[`item.role`]="{ item }">
                            <v-chip :color="getColor(item.role.name)">
                                {{ getName(item.role.name) }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="success"
                                   @click="confirm(item.uuid)">
                                <v-icon>mdi-check</v-icon> Ripristina
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-col>
        <v-dialog v-model="dialog"
                  max-width="480px">
            <v-card>
                <v-card-title><span style="justify-content: end">
                        Conferma
                    </span></v-card-title>
                <v-card-text>
                    Sei sicuro di voler ripristinare questo utente?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="success"
                           @click="recover()">Conferma</v-btn>
                    <v-btn color="error"
                           @click="dialog = false;">Annulla</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    components: {},
    name: "AthRecoverUsers",
    props: ["modal"],
    methods: {
        recover() {
            console.info("Recovering user: " + this.userToRecover);
            this.dialog = false;
            AthWebClient.get("/users/deleted/")
                .then((data) => {
                    this.users = data.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        confirm(uuid) {
            this.userToRecover = uuid;
            this.dialog = true;
        },
        getName(n) {
            if (n == "Doctor") return "Dottore";
            if (n == "Secretary") return "Segretaria";
            if (n == "Admin") return "Amministratore";
        },
        getColor(n) {
            if (n == "Doctor") return "primary";
            if (n == "Secretary") return "success";
            if (n == "Admin") return "error";
        },
    },
    mounted() {
        AthWebClient.get("/users/deleted/")
            .then((data) => {
                this.users = data.data;
            })
            .catch((err) => {
                console.error(err);
            });
    },
    data: () => ({
        dialog: false,
        userToRecover: null,
        users: [],
        headers: [
            { text: "Nome", value: "firstName" },
            { text: "Cognome", value: "lastName" },
            { text: "email", value: "email" },
            { text: "Ruolo", value: "role" },
            { text: "Telefono", value: "phone" },
            { text: "Azione", value: "actions" },
        ],
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
