import AthWebClient from "./ath-webclient";

export default {
    name: "AthCompaniesService",
    getCompaniesAuto: function(page, perPage, filters) {
        filters;

        let url = "/phcompanies/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if(filters.search) {
            url += '&search=' + filters.search;
        }

        return AthWebClient.get(url).then((res) => {
            return { data: res.data.content, total: res.data.totalElements };
        })
        .catch(err => {throw err;});
    },
};
