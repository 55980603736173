<template>
    <v-container>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Orario Ambulatorio creato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="12"
                   sm="12"
                   md="8"
                   lg="6"
                   xl="6">
                <ath-select-doctor label="Seleziona Dottore"
                                   :doctors="doctors"
                                   v-on:ath-select-doctor="handleSelectDoctor($event)"></ath-select-doctor>
            </v-col>
            <v-col cols="12"
                   sm="12"
                   md="4"
                   lg="6">
                <h1 style="padding: 1rem; text-decoration: underline 3px rgb(33, 150, 243); line-height: 1.2;"
                    v-if="doctor && doctor.uuid">
                    {{ doctor.firstName + ' ' + doctor.lastName }}
                </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   sm="12"
                   md="12">
                <table style="border-collapse: collapse;">
                    <thead>
                        <tr>
                            <th v-for="col in cols"
                                v-bind:key="col.id">{{ col.name }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="border: 1px solid black;" v-for="(row) in rows"
                            v-bind:key="row.id">
                            <!-- <td style="padding: 1rem!important;">
                                <v-checkbox v-model="thirdColumnEnabled[i]"></v-checkbox>
                            </td> -->
                            <td style="padding: 1rem!important;">
                                <h4>{{ row.name }}</h4>
                            </td>
                            <td style="padding: 1rem!important;">
                                <v-row>
                                    <v-col cols="12"
                                           sm="12">
                                        <v-text-field hint="Ambulatorio"
                                                      persistent-hint
                                                      filled
                                                      outlined
                                                      style="min-width: 160px"
                                                      v-model="weekdaysAllocation[row.name][0].location"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Inizio"
                                                      persistent-hint
                                                      filled
                                                      outlined
                                                      style="min-width: 160px"
                                                      v-model="weekdaysAllocation[row.name][0].startingHour"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Fine"
                                                      persistent-hint
                                                      filled
                                                      outlined
                                                      style="min-width: 160px"
                                                      v-model="weekdaysAllocation[row.name][0].endingHour"></v-text-field>
                                    </v-col>
                                </v-row>
                            </td>
                            <td style="padding: 1rem!important;">
                                <v-row>
                                    <v-col cols="12"
                                           sm="12">
                                        <v-text-field hint="Ambulatorio"
                                                      persistent-hint
                                                      filled
                                                      outlined
                                                      style="min-width: 160px"
                                                      v-model="weekdaysAllocation[row.name][1].location"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Inizio"
                                                      persistent-hint
                                                      outlined
                                                      style="min-width: 160px"
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][1].startingHour"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Fine"
                                                      persistent-hint
                                                      outlined
                                                      style="min-width: 160px"
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][1].endingHour"></v-text-field>
                                    </v-col>
                                </v-row>
                            </td>
                            <td style="padding: 1rem!important;">
                                <v-row>
                                    <v-col cols="12"
                                           sm="12">
                                        <v-text-field hint="Ambulatorio"
                                                      persistent-hint
                                                      outlined
                                                      style="min-width: 160px"
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][2].location"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Inizio"
                                                      persistent-hint
                                                      outlined
                                                      style="min-width: 160px"
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][2].startingHour"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field hint="Ora Fine"
                                                      persistent-hint
                                                      outlined
                                                      style="min-width: 160px"
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][2].endingHour"></v-text-field>
                                    </v-col>
                                </v-row>
                            </td>
                            <!-- <td style="padding: 1rem!important;">
                                <v-row>
                                    <v-col cols="12"
                                           sm="12">
                                        <v-text-field :disabled="!thirdColumnEnabled[i]"
                                                      hint="Ambulatorio"
                                                      persistent-hint
                                                      outlined
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][3].location"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field :disabled="!thirdColumnEnabled[i]"
                                                      hint="Ora Inizio"
                                                      persistent-hint
                                                      outlined
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][3].startingHour"></v-text-field>
                                    </v-col>
                                    <v-col cols="12"
                                           sm="6">
                                        <v-text-field :disabled="!thirdColumnEnabled[i]"
                                                      hint="Ora Fine"
                                                      persistent-hint
                                                      outlined
                                                      filled
                                                      v-model="weekdaysAllocation[row.name][3].endingHour"></v-text-field>
                                    </v-col>
                                </v-row>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   sm="2"
                   offset-sm="5">
                <v-btn color="primary"
                       block
                       @click="create()"
                       :disabled="!this.doctor">
                    Conferma
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthWebClient from "../../shared/ath-webclient";

const emptyWeekdaysAllocation = {
    lunedi: [
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
    ],
    martedi: [
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
    ],
    mercoledi: [
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
    ],
    giovedi: [
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
    ],
    venerdi: [
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
        { location: "", startingHour: "", endingHour: "" },
    ],
};

export default {
    components: { athSelectDoctor },
    name: "AthCreateLocationMetadataComponent",
    data: () => ({
        doctor: null,
        doctors: [],
        rows: [
            { id: 1, name: "lunedi", enabled: true },
            { id: 2, name: "martedi", enabled: true },
            { id: 3, name: "mercoledi", enabled: true },
            { id: 4, name: "giovedi", enabled: true },
            { id: 5, name: "venerdi", enabled: true },
        ],
        cols: [
            // { id: 99, name: "Abilita Ambulatorio Aggiuntivo" },
            { id: 0, name: "Giorno" },
            { id: 1, name: "Orario" },
            { id: 2, name: "Orario" },
            { id: 3, name: "Orario" },
            // { id: 4, name: "Orario Extra" },
        ],
        thirdColumnEnabled: [false, false, false, false, false],
        weekdaysAllocation: emptyWeekdaysAllocation,
        successMessage: false,
        isEditing: false,
    }),
    mounted() {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        create() {
            if (this.isEditing == false) {
                AthWebClient.post("/general/user-metadata/", {
                    userUUID: this.doctor.uuid,
                    weekdaysAllocationJSON: JSON.stringify(
                        this.weekdaysAllocation
                    ),
                })
                    .then((data) => {
                        data;
                        this.successMessage = true;
                    })
                    .catch((err) => {
                        err;
                    });
            }
            else
            {
                AthWebClient.put("/general/user-metadata/" + this.doctor.uuid, {
                    userUUID: this.doctor.uuid,
                    weekdaysAllocationJSON: JSON.stringify(
                        this.weekdaysAllocation
                    ),
                })
                    .then((data) => {
                        data;
                        this.successMessage = true;
                    })
                    .catch((err) => {
                        err;
                    });
            }
        },
        handleSelectDoctor(uuid) {
            this.doctor = this.doctors.filter((d) => d.uuid == uuid)[0];

            this.weekdaysAllocation = emptyWeekdaysAllocation;

            AthWebClient.get("/general/user-metadata/" + uuid)
                .then((res) => {
                    // dati presenti, da modificare
                    if (res.data && res.data.weekdaysAllocation) {
                        this.weekdaysAllocation = JSON.parse(
                            res.data.weekdaysAllocation
                        );

                        this.isEditing = true;
                    }
                    // dati non presenti, da creare
                    else {
                        this.weekdaysAllocation = emptyWeekdaysAllocation;
                        this.isEditing = false;
                    }
                })
                .catch((err) => {
                    err;
                });
        },
    },
};
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: inherit !important;
}

.v-input--is-disabled:not(.v-input--is-readonly) {
    opacity: 0.4;
}
</style>