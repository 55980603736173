var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-snackbar',{attrs:{"color":"success","top":"","centered":"","elevation":"10"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.successMessage = false}}},[_vm._v(" Chiudi ")])]},proxy:true}]),model:{value:(_vm.successMessage),callback:function ($$v) {_vm.successMessage=$$v},expression:"successMessage"}},[_vm._v(" Utente eliminato con successo! ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","offset-md":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("Utenti")]),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"disable-sort":true},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.role.name)}},[_vm._v(" "+_vm._s(_vm.getName(item.role.name))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Modifica ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUser(item.uuid)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Elimina ")],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"960px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Modifica Utente")]),_c('v-icon',{attrs:{"large":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v("mdi-close")])],1),_c('br'),_c('v-card-text',[(_vm.userToEdit)?_c('ath-edit-user',{attrs:{"modal":true,"user":_vm.userToEdit},on:{"ath-user-modified":function($event){return _vm.refresh()}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }