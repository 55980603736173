<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Sostituzione creata con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               sm="12"
               md="10"
               offset-md="1">
            <v-container fluid class="pa-0">
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center;">
                        Crea Sostituzione

                    </v-card-title>
                </v-card>
            </v-container>
            <v-card>
                <v-col cols="12"
                       sm="12"
                       class="text-h5 text-center info--text mt-2"><span>Seleziona Dottori e Date Sostituzione</span>
                    <v-divider></v-divider>
                </v-col>
                <v-container fluid
                             style="padding: 1rem;">
                    <v-row>
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               md="6">
                            <ath-select-doctor :pi="true"
                                               hint="Il dottore che sarà assente"
                                               label="Dottore"
                                               :doctors="doctors"
                                               v-on:ath-select-doctor="($event) => leavingDoctor = $event"></ath-select-doctor>
                        </v-col>
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               md="6">
                            <ath-select-doctor :pi="true"
                                               hint="Il dottore che lo/la sostituirà"
                                               label="Sostituito da"
                                               :doctors="doctors"
                                               v-on:ath-select-doctor="($event) => substituteDoctor = $event"></ath-select-doctor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               md="6">
                            <v-menu v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="formattedStart"
                                                  label="Inzio Sostituzione"
                                                  prepend-inner-icon="mdi-calendar-arrow-left"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="start"
                                               no-title
                                               @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12"
                               xs="12"
                               sm="6"
                               md="6">
                            <v-menu v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="formattedEnd"
                                                  label="Fine Sostituzione"
                                                  prepend-inner-icon="mdi-calendar-arrow-right"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="end"
                                               no-title
                                               @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               sm="6">
                            <v-textarea outlined
                                        prepend-inner-icon="mdi-note"
                                        label="Note"
                                        rows="3"
                                        v-model="notes"></v-textarea>
                        </v-col>
                        <v-col cols="12"
                               sm="6">
                            <v-card color="blue lighten-5">
                                <v-card-text>
                                    <v-icon>mdi-information-outline</v-icon> Quando viene creata una sostituzione, il medico che sostituisce 
                                    <u>riceverà automaticamente</u> gli appuntamenti e le prescrizioni del medico sostituito. <br />
                                </v-card-text>
                            </v-card>
                            <br />
                            <v-card color="warning lighten-2">
                                <v-card-text>
                                    <v-icon>mdi-alert-outline</v-icon> Una volta creata la sostituzione, 
                                    cancellarla <u>non</u> riporta indietro gli appuntamenti e le prescrizioni spostate.
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               sm="12"
                               md="2"
                               offset-md="5"
                               style="display: flex; flex-direction: column; align-items: center; ">
                            <v-btn color="info"
                                   raised
                                   block
                                   @click="create()">
                                Salva
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            <br />
            <br />
            <v-card>
                <v-card-title>Sostituzioni Attive</v-card-title>
                <v-card-text>
                    <ath-substitutions :get-active="true"
                                       :refreshToggle="refresh"></ath-substitutions>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import athSelectDoctor from "../ath-select-doctor/ath-select-doctor.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthSubstitionsService from "../../shared/ath-substitutions.service";
import AthSubstitutions from "../ath-substitutions/ath-substitutions.vue";
import moment from "moment";

export default {
    components: { athSelectDoctor, AthSubstitutions },
    name: "AthCreateSubstitution",
    computed: {
        formattedStart: {
            get() {
                return this.start
                    ? moment(this.start).format("DD/MM/YYYY")
                    : "";
            },
            set() {},
        },
        formattedEnd: {
            get() {
                return this.end ? moment(this.end).format("DD/MM/YYYY") : "";
            },
            set() {},
        },
    },
    mounted: function () {
        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        create() {
            if (!this.validate()) {
                return;
            }

            AthSubstitionsService.create({
                leavingDoctorUUID: this.leavingDoctor,
                substituteDoctorUUID: this.substituteDoctor,
                startDate: moment(this.start).format("YYYY-MM-DD"),
                endDate: moment(this.end).format("YYYY-MM-DD"),
                notes: this.notes,
            })
                .then((res) => {
                    res;
                    this.successMessage = true;
                    this.refresh = !this.refresh;
                })
                .catch((err) => {
                    err;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione della sostituzione!",
                    });
                });
        },

        validate() {
            if (
                moment(this.end, "YYYY-MM-DD").isSame(
                    moment(this.start, "YYYY-MM-DD")
                )
            ) {
                return false;
            }

            return true;
        },
    },
    data: () => ({
        doctors: [],
        start: null,
        menu2: false,
        end: null,
        menu3: false,
        notes: null,
        leavingDoctor: null,
        substituteDoctor: null,
        successMessage: false,
        refresh: false,
    }),
};
</script>

<style>
.v-messages__message {
    color: black;
}
</style>
