<template>
    <v-container fluid>
        <v-snackbar v-model="successEditMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Prescrizione Modificata con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successEditMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-row dense>
            <v-col cols="12"
                   :md="dashboardMode ? 12 : 8"
                   :offset-md="dashboardMode ? 0 : 2">
                <v-card hover>
                    <p class="text-h4 text-center text--primary"
                       style="padding: 10px 0px 10px 0px;">Prescrizioni</p>
                </v-card>
                <ath-appointments-filters :dashboard-mode="dashboardMode"
                                          :doctors="doctors"
                                          :doctor-filter-enabled="doctorFilterEnabled"
                                          v-on:ath-appointemnts-filter-apply-filters="
                        handleFilterUpdate($event)
                    "></ath-appointments-filters>
                <br />
                <ath-prescriptions-table :headers="headers"
                                         :events="prescriptions"
                                         :total="prescriptionsTotal"
                                         :loading="loading"
                                         v-on:ath-pagination-update="handlePaginationUpdate"
                                         v-on:ath-prescriptions-table-set-done-status="toggleCompleted($event)"
                                         v-on:ath-view-prescription-details="handleViewDetails($event)"
                                         v-on:ath-edit-prescription="editPrescription($event)"
                                         v-on:ath-delete-prescription-with-reason="openDeleteDialog($event)"
                                         :page="page">
                    <!-- <template v-slot:filters
                        ></template> -->
                </ath-prescriptions-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog"
                  max-width="600px">
            <ath-view-prescription :item="prescriptionDetail"
                                   @ath-prescriptions-table-set-close-reason="dialog = false;
                                        setCloseReason($event); 
                                        $emit('ath-prescriptions-table-set-close-reason', $event);"
                                   v-on:ath-prescriptions-table-set-status="setCompleted($event)"
                                   v-on:ath-close-view-prescription="dialog = false;">
            </ath-view-prescription>
        </v-dialog>

        <!-- edit dialog -->
        <v-dialog v-model="editDialog"
                  persistent
                  width="960">
            <v-card>
                <v-card-title class="text-h4 text-center">
                    Modifica Prescrizione
                </v-card-title>
                <v-card-text>
                    <ath-edit-prescription v-if="editDialog"
                                           v-on:ath-cancel-edit-prescription="editDialog = false"
                                           v-on:ath-prescription-edit-success="handleEdit()"
                                           class="black--text"
                                           :prescriptionUUID="prescriptionToEdit">
                    </ath-edit-prescription>
                    <!-- <ath-edit-appointment v-if="editDialog"
                                          v-on:ath-cancel-edit-appointment="editDialog = false"
                                          v-on:ath-appointment-edit-success="handleEdit()"
                                          class="black--text"
                                          :appointmentUUID="appointmentToEdit"></ath-edit-appointment> -->
                </v-card-text>

                <v-divider></v-divider>

                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="editDialog = false">
                        Annulla
                    </v-btn>
                    <v-btn color="error"
                           raised>
                        Conferma
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal"
                  persistent
                  width="960">
            <v-card>
                <v-card-title class="text-h5">
                    Cancella Prescrizione
                </v-card-title>

                <v-card-text>
                    Per cancellare la prescrizione bisogna obbligatoriamente inserire una motivazione e confermare.

                    <br />
                    <br />

                    <v-text-field label="Motivazione"
                                  outlined
                                  v-model="deleteReason"
                                  color="error"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteModal = false">
                        Annulla
                    </v-btn>
                    <v-btn color="error"
                           raised
                           :disabled="!deleteReason || deleteReason.length < 3"
                           @click="deleteWithReason()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AthPrescriptionsTable from "./ath-prescriptions-table.vue";
import AthPrescriptionsService from "./ath-prescriptions.service";
import AthAppointmentsFilters from "../ath-appointments/ath-appointments-filters/ath-appointments-filters.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthViewPrescription from "../ath-view-prescription/ath-view-prescription.vue";
import AthEditPrescription from "../ath-edit-prescription/ath-edit-prescription.vue";

export default {
    name: "ath-prescriptions",
    components: {
        AthPrescriptionsTable,
        AthAppointmentsFilters,
        AthViewPrescription,
        AthEditPrescription,
    },
    props: {
        dashboardMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.loading = true;

        if (this.dashboardMode) {
            this.filters.showOnlyToday = true;
        }

        AthPrescriptionsService.getPrescriptionsAuto(
            this.page,
            this.perPage,
            this.filters
        ).then((data) => {
            this.prescriptions = data.data;
            this.prescriptionsTotal = data.total;
            this.loading = false;
        });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.doctors = [];
            });
    },
    methods: {
        handleEdit() {
            this.editDialog = false;
            this.successEditMessage = true;
            this.handlePaginationUpdate({ page: 1, perPage: 10 });
        },
        editPrescription(uuid) {
            this.editDialog = true;
            this.prescriptionToEdit = uuid;
        },
        handleViewDetails(prescription) {
            this.prescriptionDetail = prescription;
            this.dialog = true;
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthPrescriptionsService.getPrescriptionsAuto(
                this.page,
                this.perPage,
                this.filters
            ).then((data) => {
                this.prescriptions = data.data;
                this.prescriptionsTotal = data.total;
                this.loading = false;
            });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
        toggleCompleted: function (prescriptionUUID) {
            AthPrescriptionsService.toggleCompleted(prescriptionUUID)
                .then((res) => {
                    res;
                    AthPrescriptionsService.getPrescriptionsAuto(
                        this.page,
                        this.perPage,
                        this.filters
                    ).then((data) => {
                        this.prescriptions = data.data;
                        this.prescriptionsTotal = data.total;
                        this.loading = false;
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        setCompleted: function (event) {
            AthPrescriptionsService.setCompleted(event.uuid, event.status)
                .then((res) => {
                    res;
                    AthPrescriptionsService.getPrescriptionsAuto(
                        this.page,
                        this.perPage,
                        this.filters
                    ).then((data) => {
                        this.prescriptions = data.data;
                        this.prescriptionsTotal = data.total;
                        this.loading = false;
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        setCloseReason: function (payload) {
            AthPrescriptionsService.setCloseReason(
                payload.auuid,
                payload.acloseReason
            )
                .then((res) => {
                    res;
                    AthPrescriptionsService.getPrescriptionsAuto(
                        this.page,
                        this.perPage,
                        this.filters
                    ).then((data) => {
                        this.prescriptions = data.data;
                        this.prescriptionsTotal = data.total;
                        this.loading = false;
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        openDeleteDialog: function (uuid) {
            this.deleteModal = true;
            this.prescriptionToDelete = uuid;
            this.deleteReason = null;
        },
        deleteWithReason: function () {
            if (this.deleteReason == null || this.deleteReason.length < 1) {
                alert("ERROR");
                return;
            }

            AthPrescriptionsService.deleteWithReason(
                this.prescriptionToDelete,
                this.deleteReason
            )
                .then((res) => {
                    res;
                    this.deleteModal = false;
                    this.prescriptionToDelete = null;
                    this.deleteReason = null;

                    this.handlePaginationUpdate({ page: 1, perPage: 10 });

                    this.successMessage = true;
                })
                .catch((err) => {
                    err;
                    this.deleteModal = false;
                    this.prescriptionToDelete = null;
                    this.deleteReason = null;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la cancellazione",
                    });
                });
        },
    },
    data: () => ({
        // data for the appointments table
        prescriptions: [],
        prescriptionsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Paziente", value: "patient" },
            { text: "Dottore", value: "doctor" },
            { text: "Data", value: "date" },
            { text: "Completata", value: "done" },
            // { text: "Chiusura Veloce", value: "closeReason" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            from: null,
            end: null,
            doctorId: null,
            sortByDate: null,
            showOnlyToday: null,
            showOnlyCompleted: null,
        },

        // details
        prescriptionDetail: null,

        // details dialog
        dialog: false,

        // dummy for change detection
        dummy: false,

        prescriptionToEdit: false,

        editDialog: false,

        successEditMessage: false,

        deleteModal: false,

        deleteReason: null,

        prescriptionToDelete: null,
    }),
};
</script>
