export default {
    filters: {
        from: String,
        end: String,
        doctorId: Number,
        sortByDate: String,
        showOnlyToday: Boolean,
        showOnlyCompleted: Boolean,
        showOnlyNotCompleted: Boolean,
        patientId: String,
    }
}