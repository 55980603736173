<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   md="8"
                   offset-md="2">
                <v-card>
                    <v-card-title>Admin Dashboard &nbsp;<span class="red--text">DEMO</span></v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <v-btn @click="graphAppointmentsPrescriptionsLastWeek()"
                                       color="primary">Aggiorna Grafici</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="2">
                                <v-card class="text-center"
                                        style="background-color: #06283D; color: white;">
                                    <v-card-title style="justify-content: center">
                                        <v-icon color="white">mdi-calendar-month</v-icon>&nbsp;Appuntamenti
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h4 white--text">{{ formatNum(totals.appointments) }}</span>
                                        <span class="text-h6 white--text"> ({{ totals.appointments.toLocaleString('it') }}) </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   md="2">
                                <v-card class="text-center"
                                        style="background-color: #256D85; color: white;">
                                    <v-card-title style="justify-content: center">
                                        <v-icon color="white">mdi-newspaper-variant-multiple-outline</v-icon>&nbsp;Prescrizioni
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h4 white--text">{{ formatNum(totals.prescriptions) }}</span>
                                        <span class="text-h6 white--text"> ({{ totals.prescriptions.toLocaleString('it') }}) </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   md="2">
                                <v-card class="text-center"
                                        style="background-color: #47B5FF;">
                                    <v-card-title style="justify-content: center">
                                        <v-icon>mdi-account-injury-outline</v-icon>&nbsp;Pazienti
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h4">{{ formatNum(totals.patients) }}</span>
                                        <span class="text-h6"> ({{ totals.patients.toLocaleString('it') }}) </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-card class="text-center"
                                        style="background-color: #DFF6FF;">
                                    <v-card-title style="justify-content: center">
                                        <v-icon>mdi-account-box</v-icon>&nbsp;Utenti
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12"
                                                   md="12"
                                                   class="text-center">
                                                <span class="text-h4">{{ users.total }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"
                                                   md="4">
                                                <v-card class="text-center">
                                                    <v-card-title style="justify-content: center">
                                                        <v-icon>mdi-security</v-icon>&nbsp;Amministratori
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <span class="text-h4">{{ users.admins }}</span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12"
                                                   md="4">
                                                <v-card class="text-center">
                                                    <v-card-title style="justify-content: center">
                                                        <v-icon>mdi-face-woman</v-icon>&nbsp;Segretarie
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <span class="text-h4">{{ users.secretaries }}</span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12"
                                                   md="4">
                                                <v-card class="text-center">
                                                    <v-card-title style="justify-content: center">
                                                        <v-icon>mdi-stethoscope</v-icon>&nbsp;Dottori
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <span class="text-h4">{{ users.doctors }}</span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="12">
                                <p class="text-h5">Appuntamenti &amp; Prescrizioni (scorsa settimana)</p>
                                <div id="graphAppointmentsPrescriptionsPerWeek"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Plotly from "plotly.js-dist";
import moment from "moment";

export default {
    name: "AthAdminDashboard",
    mounted: function () {},
    data: () => ({
        totals: {
            patients: 33123,
            appointments: 56912,
            prescriptions: 67154,
        },
        users: {
            total: 21,
            admins: 2,
            secretaries: 3,
            doctors: 16,
        },
    }),
    methods: {
        formatNum(value) {
            return ("" + value).substring(0, 2) + "k";
        },
        graphAppointmentsPrescriptionsLastWeek() {
            let thisWeek = [];
            let randomAppointments = [];
            let randomPrescriptions = [];

            const startDate = moment();

            for (let i = 7; i > 0; i--) {
                thisWeek.push(
                    (startDate.clone().subtract(i, "days").format("DD/MM/YYYY") 
                    + " (" 
                    + startDate.locale('it').clone().subtract(i, "days").format("dddd")
                    + ")") 
                );
            }

            for (let i = 0; i < 7; i++) {
                randomAppointments.push(
                    Math.floor(Math.random() * (40 - 10 + 1) + 10)
                );
                randomPrescriptions.push(
                    Math.floor(Math.random() * (40 - 10 + 1) + 10)
                );
            }

            var trace1 = {
                x: [...thisWeek],
                y: [...randomAppointments],
                name: "Appuntamenti",
                type: "bar",
                text: randomAppointments.map(String),
                textposition: "auto",
                hoverinfo: "none",
                marker: {
                    color: "#256D85",
                },
            };
            var trace2 = {
                x: [...thisWeek],
                y: [...randomPrescriptions],
                name: "Prescrizioni",
                type: "bar",
                text: randomPrescriptions.map(String),
                textposition: "auto",
                hoverinfo: "none",
                marker: {
                    color: "#06283D",
                },
            };
            var data = [trace1, trace2];
            var layout = { barmode: "group" };
            Plotly.newPlot(
                "graphAppointmentsPrescriptionsPerWeek",
                data,
                layout,
                {
                    displayModeBar: false,
                    responsive: true,
                    staticPlot: true,
                }
            );
        },
    },
};
</script>