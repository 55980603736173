<template>
    <v-row>
        <v-col cols="12" sm="12" md="10" offset-md="1">
          <v-container fluid>
            <v-card hover>
              <v-card-title class="text-h4" style="justify-content: center">Crea Azienda Farmaceutica</v-card-title>
            </v-card>
          </v-container>
          <v-container fluid>
            <v-card>
              <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Nome"
                                prepend-inner-icon="mdi-account"
                                outlined
                                v-model="company.name"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Indirizzo"
                                prepend-inner-icon="mdi-map-marker"
                                outlined
                                v-model="company.address"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Email"
                                prepend-inner-icon="mdi-email"
                                outlined
                                v-model="company.email"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Telefono"
                                prepend-inner-icon="mdi-phone"
                                outlined
                                v-model="company.phone"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-textarea
                                outlined
                                prepend-inner-icon="mdi-note"
                                label="Note"
                                rows="2"
                                v-model="company.notes"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    "
                        >
                            <v-btn color="info" @click="create()" raised>
                                Salva
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
          </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthCreatePhCompany",
    methods: {
        create() {
            AthWebClient.post("/phcompanies/", this.company)
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Azienda Farmaceutica creata con successo",
                    })
                    .then((val) => {
                        val;
                        this.$router.push("/ph-companies");
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione dell'Azienda Farmaceutica!",
                    });
                });
        }
    },
    data: () => ({
        company: {},
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
