<template>
    <v-data-table
        :headers="headers"
        :items="drugs"
        disable-pagination
        class="elevation-1"
    >
    <template v-slot:[`item.quantity`]="{ item }">
            <v-btn
              icon
              x-small
              color="info"
              @click="$emit('ath-decrement-drug', item.id)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            {{ item.quantity }}
            <v-btn
              icon
              x-small
              color="info"
              @click="$emit('ath-increment-drug', item.id)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              color="error"
              @click="$emit('ath-remove-drug', item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "AthDrugsList",
    props: ["drugs"],
    data: () => ({
        headers: [
            { text: "Nome", value: "name" },
            { text: "Principio Attivo", value: "activeSubstance" },
            { text: "Qtà", value: "quantity"},
            { text: "Azioni", value: "actions" },
        ],
    }),
};
</script>
