<template>
    <v-app>
        <v-navigation-drawer v-if="$store.state.user.loggedIn"
                             clipped
                             app
                             v-model="drawer">
            <v-list dense
                    nav>
                <v-list-item :class="{ info: $route.path == item.link, red: item.link[0] == '@' } "
                             @click="navigate(item)"
                             :disabled="item.isHeader"
                             v-for="item in menuItems"
                             :key="item.title"
                             link>
                    <v-list-item-icon v-if="!item.isHeader">
                        <v-icon :color="$route.path == item.link ? 'white' : 'dark'">{{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content :class="{ 'white--text': ($route.path == item.link || item.link[0] == '@') }"
                                         v-if="!item.isHeader">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content v-if="item.isHeader">
                        <span style="font-size: 1.1rem; text-align: center; 
                            text-decoration: underline; text-decoration-color: #2196f3; text-decoration-thickness: 3px;">{{
                                item.title
                            }}</span>
                    </v-list-item-content>
                </v-list-item>
                <span v-if="$store.state.user.loginRole >= 5 ">
                    <v-list-item :class="{ info: $route.path == item.link }"
                                 @click="navigate(item)"
                                 :disabled="item.isHeader"
                                 v-for="item in adminMenuItems"
                                 :key="item.title"
                                 link>
                        <v-list-item-icon v-if="!item.isHeader">
                            <v-icon :color="$route.path == item.link ? 'white' : 'dark'">{{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content :class="{ 'white--text': $route.path == item.link }"
                                             v-if="!item.isHeader">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-if="item.isHeader">
                            <span style="font-size: 1.1rem; text-align: center;
                                text-decoration: underline; text-decoration-color: #2196f3; text-decoration-thickness: 3px;">{{
                                    item.title
                                }}</span>
                        </v-list-item-content>
                    </v-list-item>
                </span>
            </v-list>
        </v-navigation-drawer>
        <v-container v-if="$store.state.user.loggedIn">
            <v-row no-gutters>
                <v-app-bar clipped-left
                           color="#3E64FF"
                           app
                           height="80">
                    <v-col cols="auto">
                        <v-app-bar-nav-icon style="color: white !important"
                                            @click="toggleNavigationDrawer()"></v-app-bar-nav-icon>
                    </v-col>
                    <v-col cols="auto">
                        <img src="@/assets/logo-small.png"
                             style="max-height: 70px; " />
                    </v-col>
                    <v-col cols="auto"
                           class="flex-fill justify-start">
                        <h1 class="white--text font-weight-light d-none d-lg-block">
                            Associazione Medica
                        </h1>
                    </v-col>
                    <v-col cols="4"
                           class="d-flex flex-fill justify-end mb-7">
                        <v-speed-dial v-model="fab2"
                                      :top="true"
                                      :bottom="false"
                                      :right="true"
                                      :left="false"
                                      :direction="'left'"
                                      :open-on-hover="false"
                                      :transition="'fade-transition'"
                                      class="d-none d-sm-block mr-2">
                            <template v-slot:activator>
                                <v-btn v-model="fab2"
                                       color="white"
                                       fab>
                                    <v-icon v-if="fab2"> mdi-close</v-icon>
                                    <v-icon v-else> mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <v-btn class="d-none d-md-block"
                                   color="#ECFCFF"
                                   @click="createAppointment">
                                <v-icon>mdi-calendar-month</v-icon>&nbsp;Appuntamento
                            </v-btn>
                            <v-btn class="d-none d-md-block"
                                   color="#B2FCFF"
                                   @click="createPrescription">
                                <v-icon>mdi-iv-bag</v-icon>&nbsp;Prescrizione
                            </v-btn>
                            <v-btn class="d-none d-md-block"
                                   color="#5EDFFF"
                                   @click="createPause">
                                <v-icon>mdi-alarm</v-icon>&nbsp;Pausa
                            </v-btn>
                            <v-btn class="d-md-none"
                                   color="#ECFCFF"
                                   @click="createAppointment">
                                <v-icon>mdi-calendar-month</v-icon>
                            </v-btn>
                            <v-btn class="d-md-none"
                                   color="#B2FCFF"
                                   @click="createPrescription">
                                <v-icon>mdi-iv-bag</v-icon>
                            </v-btn>
                            <v-btn class="d-md-none"
                                   color="#5EDFFF">
                                <v-icon>mdi-alarm</v-icon>
                            </v-btn>
                        </v-speed-dial>

                        &nbsp;
                        <!-- hide chat on mobile -->
                        <v-speed-dial v-model="fab3"
                                      :top="true"
                                      :right="true"
                                      :direction="'bottom'"
                                      :open-on-hover="false"
                                      :transition="'scale-transition'"
                                      class="mr-2">
                            <template v-slot:activator>
                                <v-btn fab
                                       class="d-none d-sm-block"
                                       @click.stop="$store.commit('ui.chat.toggle')">
                                    <v-badge :value="$store.state.ui.chat.unreads > 0"
                                             :content="$store.state.ui.chat.unreads"
                                             bordered
                                             class="white--text"
                                             color="error"
                                             overlap>
                                        <v-icon large
                                                color="black">
                                            mdi-chat
                                        </v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>
                        </v-speed-dial>

                        &nbsp;
                        <v-speed-dial v-model="fab"
                                      :top="true"
                                      :right="true"
                                      :direction="'bottom'"
                                      :open-on-hover="false"
                                      :transition="'scale-transition'">
                            <template v-slot:activator>
                                <v-btn v-model="fab"
                                       color="white"
                                       fab>
                                    <v-icon v-if="fab"> mdi-close</v-icon>
                                    <v-icon v-else>
                                        {{ $store.state.user.loginName }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn color="black"
                                   raised
                                   class="white--text"
                                   @click="editProfile()">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn @click="logout()"
                                   color="red"
                                   raised
                                   class="white--text">
                                <v-icon>mdi-logout</v-icon>
                            </v-btn>
                        </v-speed-dial>
                    </v-col>
                </v-app-bar>
            </v-row>
        </v-container>
        <!-- Sizes your content based upon application components -->
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <v-navigation-drawer v-if="$store.state.user.loggedIn"
                                     v-model="$store.state.ui.chat.open"
                                     absolute
                                     right
                                     hide-overlay
                                     temporary
                                     style="max-width: 850px !important; min-width: 850px !important;">
                    <!--<ath-chat></ath-chat>-->
                    <AthChatFullpage></AthChatFullpage>
                </v-navigation-drawer>
                <v-slide-x-transition mode="out-in">
                    <router-view></router-view>
                </v-slide-x-transition>
            </v-container>
        </v-main>

        <!-- <v-footer padless app color="red">
        <span style="color: yellow; font-size: 2rem; width: 100%"
            ><marquee>VERSIONE DI SVILUPPO</marquee></span
        >
    </v-footer> -->
    </v-app>
</template>

<script>
import AthChatFullpage from "./views/ath-chat-fullpage.view.vue";
// import athChat from "./components/ath-chat/ath-chat.vue";
// import athNotifications from "./components/ath-notifications/ath-notifications.vue";

export default {
    // components: { athChat },
    components: { AthChatFullpage },
    name: "App",
    // components: {
    //     athNotifications,
    // },
    data: () => ({
        drawer: true,
        drawer2: true,
        fab: null,
        fab2: null,
        fab3: null,
        adminMenuItems: [
            {
                title: "Amministrazione",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Crea Utente",
                icon: "mdi-account-plus-outline",
                link: "/admin/create/user",
                isHeader: false,
            },
            {
                title: "Sostituzioni Attive",
                icon: "mdi-swap-horizontal",
                link: "/substitutions",
                isHeader: false,
            },
            {
                title: "Recupera Utente",
                icon: "mdi-ambulance",
                link: "/admin/recover/users",
                isHeader: false,
            },
            {
                title: "Lista Utenti",
                icon: "mdi-account-box",
                link: "/admin/list/users",
                isHeader: false,
            },
            {
                title: "Gestione Orari",
                icon: "mdi-timetable",
                link: "/create/location-metadata",
                isHeader: false,
            },
            {
                title: "Appuntamenti Cancellati",
                icon: "mdi-file-cancel",
                link: "/admin/list/appointments/deleted",
                isHeader: false,
            },
            {
                title: "Prescrizioni Cancellate",
                icon: "mdi-file-cancel",
                link: "/admin/list/prescriptions/deleted",
                isHeader: false,
            },
            {
                title: "Admin Dashboard",
                icon: "mdi-monitor-dashboard",
                link: "/admin/dashboard",
                isHeader: false,
            },
            // TODO: for later
            // {
            //     title: "Invio Notifiche",
            //     icon: "mdi-email-fast",
            //     link: "/admin/create/notification",
            //     isHeader: false,
            // },
        ],
        menuItems: [
            {
                title: "Home",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Dashboard",
                icon: "mdi-view-dashboard",
                link: "/dashboard",
                isHeader: false,
            },
            {
                title: "Appuntamenti",
                icon: "mdi-calendar-month",
                link: "/appointments",
                isHeader: false,
            },
            {
                title: "Prescrizioni",
                icon: "mdi-newspaper-variant-multiple-outline",
                link: "/prescriptions",
                isHeader: false,
            },
            {
                title: "Blocco Calendari",
                icon: "mdi-calendar-remove",
                link: "/calendar-blocks",
                isHeader: false,
            },
            {
                title: "Eventi",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Crea Appuntamento",
                icon: "mdi-calendar-plus",
                link: "/create-appointment",
                isHeader: false,
            },
            {
                title: "Crea Prescrizione",
                icon: "mdi-newspaper-variant-outline",
                link: "/create-prescription",
                isHeader: false,
            },
            {
                title: "Crea Sostituzione",
                icon: "mdi-swap-horizontal-circle-outline",
                link: "/create-substitution",
                isHeader: false,
            },
            {
                title: "Crea Blocco Calendario",
                icon: "mdi-calendar-remove",
                link: "/create-calendar-block",
                isHeader: false,
            },
            // {
            //     title: "Crea Impegnativa",
            //     icon: "mdi-account-multiple-check",
            //     link: "/create-exam",
            //     isHeader: false,
            // },
            {
                title: "Anagrafiche",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Pazienti",
                icon: "mdi-account-injury-outline",
                link: "/patients",
                isHeader: false,
            },
            {
                title: "Farmaci",
                icon: "mdi-pill",
                link: "/drugs",
                isHeader: false,
            },
            {
                title: "Informatori",
                icon: "mdi-account-tie",
                link: "/representatives",
                isHeader: false,
            },
            {
                title: "Aziende Farmaceutiche",
                icon: "mdi-domain",
                link: "/ph-companies",
                isHeader: false,
            },

            {
                title: "Storico Sostituzioni",
                icon: "mdi-folder-swap-outline",
                link: "/substitutions-history",
                isHeader: false,
            },

            {
                title: "Configurazione",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Crea Paziente",
                icon: "mdi-account-plus",
                link: "/create-patient",
                isHeader: false,
            },
            {
                title: "Crea Farmaco",
                icon: "mdi-tag-plus",
                link: "/create-drug",
                isHeader: false,
            },
            {
                title: "Crea Informatore",
                icon: "mdi-account-tie-outline",
                link: "/create-representative",
                isHeader: false,
            },
            {
                title: "Crea Azienda Farmaceutica",
                icon: "mdi-office-building-cog-outline",
                link: "/create-ph-company",
                isHeader: false,
            },
            {
                title: "Comunicazione",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Segnala Problema",
                icon: "mdi-bug",
                link: "/create/bug-report",
                isHeader: false,
            },
            {
                title: "Profilo",
                icon: "",
                link: "",
                isHeader: true,
            },
            {
                title: "Chat",
                icon: "mdi-chat",
                //link: "/view/my/chats",
                link: "/testing/chat-fullpage",
                isHeader: false,
            },
            {
                title: "Modifica Profilo",
                icon: "mdi-pencil",
                link: "/edit/my/profile",
                //class: ".d-none .d-sm-flex .d-md-none"
                isHeader: false,
            },
            {
                title: "Logout",
                icon: "mdi-logout",
                link: "@logout",
                isHeader: false,
            },
        ],
    }),
    created: function () {
        this.drawer = !this.$vuetify.breakpoint.mobile;
    },
    methods: {
        navigate: function (ev) {
            if (ev.link && ev.link[0] == "@") {
                if (ev.link == "@logout") {
                    this.logout();
                }
            } else {
                this.$router.push(
                    ev.link || "/",
                    () => {},
                    () => {}
                );
            }
        },
        toggleNavigationDrawer: function () {
            this.drawer = !this.drawer;
        },
        logout() {
            this.$store.commit("user.logout");
            this.$router.push("/login");
        },
        createAppointment: function () {
            this.$router.push(
                "/create-appointment",
                () => {},
                () => {}
            );
        },
        createPause: function () {
            this.$router.push(
                "/create-calendar-block",
                () => {},
                () => {}
            );
        },
        createPrescription: function () {
            this.$router.push(
                "/create-prescription",
                () => {},
                () => {}
            );
        },
        editProfile: function () {
            this.$router.push(
                "/edit/my/profile",
                () => {},
                () => {}
            );
        },
    },
};
</script>

<style scoped>
.v-list-item--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}

aside {
    width: auto !important;
    top: -22px !important;
}
</style>
