<template>
    <v-container>
        <ath-create-user :modal="false"></ath-create-user>
    </v-container>
</template>

<script>
import AthCreateUser from '../../components/ath-create-user/ath-create-user.vue';

export default {
    name: 'AthAdminCreateUserView',
    components: {AthCreateUser}
}
</script>