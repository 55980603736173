import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthAppointmentsService",
    create(appointment) {
        let url = "/appointments/";

        return AthWebClient.post(url, appointment)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    edit(uuid, appointment) {
        let url = "/appointments/" + uuid;

        return AthWebClient.put(url, appointment)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    deleteWithReason(uuid, reason) {
        let url = "/appointments/delete-with-reason/" + uuid;

        return AthWebClient.delete(url, { data: { deleteReason: reason } })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getAppointment: function(uuid) {
        let url = "/appointments/" + uuid;

        return AthWebClient.get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getAppointmentsAuto: function(page, perPage, filters) {
        // (page - 1) because spring has 0-based pagination
        let url =
            "/appointments/auto?page=" + (page - 1) + "&perPage=" + perPage;

        if (filters.from != null) url += "&startDate=" + filters.from;

        if (filters.end != null) {
            url += "&endDate=" + filters.end;
        }

        if (filters.doctorId != null) url += "&doctorUUID=" + filters.doctorId;

        if (filters.showOnlyToday != null)
            url += "&showOnlyToday=" + filters.showOnlyToday;

        if (filters.showOnlyCompleted != null)
            url += "&showOnlyCompleted=" + filters.showOnlyCompleted;
        
        if (filters.showOnlyNotCompleted != null)
            url += "&showOnlyNotCompleted=" + filters.showOnlyNotCompleted;

        if (filters.sortByDate != null)
            url += "&sortByDateDirection=" + filters.sortByDate;

        if (filters.patientId != null) url += "&patientUUID=" + filters.patientId;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    getDeletedAppointmentsAuto: function(page, perPage, filters) {
        // (page - 1) because spring has 0-based pagination
        let url =
            "/appointments/deleted/auto?page=" +
            (page - 1) +
            "&perPage=" +
            perPage;

        if (filters.from != null) url += "&startDate=" + filters.from;

        if (filters.end != null) {
            url += "&endDate=" + filters.end;
        }

        if (filters.doctorId != null) url += "&doctorUUID=" + filters.doctorId;

        if (filters.showOnlyToday != null)
            url += "&showOnlyToday=" + filters.showOnlyToday;

        if (filters.showOnlyCompleted != null)
            url += "&showOnlyCompleted=" + filters.showOnlyCompleted;

        if (filters.sortByDate != null)
            url += "&sortByDateDirection=" + filters.sortByDate;

        return AthWebClient.get(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
    toggleCompleted(appointmentUUID) {
        let url = "/appointments/toggle-completed/" + appointmentUUID;

        return AthWebClient.put(url)
            .then((res) => {
                return {
                    data: res.data.content,
                    total: res.data.totalElements,
                };
            })
            .catch((err) => {
                throw err;
            });
    },
};
