<template>
    <v-row v-if="user">
        <v-col cols="12"
               sm="12"
               :md="modal ? 12 : 8"
               :offset-md="modal ? 0 : 2">
            <v-card elevation="0">
                <v-card-title v-if="!modal">Modifica Utente</v-card-title>
                <v-container fluid
                             style="padding: 1rem;">
                    <v-row>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Nome"
                                          prepend-inner-icon="mdi-account"
                                          outlined
                                          v-model="user.firstName">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Cognome"
                                          prepend-inner-icon="mdi-account-outline"
                                          outlined
                                          v-model="user.lastName">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Email"
                                          prepend-inner-icon="mdi-at"
                                          outlined
                                          v-model="user.email">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6">
                            <v-select :items="roles"
                                      label="Ruolo"
                                      outlined
                                      success
                                      prepend-inner-icon="mdi-stethoscope"
                                      v-model="user.role.uuid"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Orario Inizio"
                                          prepend-inner-icon="mdi-clock-time-eight"
                                          outlined
                                          v-model="user.startTime">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Orario Fine"
                                          prepend-inner-icon="mdi-clock-time-eight-outline"
                                          outlined
                                          v-model="user.endTime">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               md="6">
                            <v-select :items="durations"
                                      label="Durata Predefenita (min.)"
                                      outlined
                                      prepend-inner-icon="mdi-timer"
                                      v-model="user.preferredIntervalTimeMins"></v-select>
                        </v-col>
                        <v-col cols="12"
                               md="6">
                            <v-text-field label="Note"
                                          prepend-inner-icon="mdi-note"
                                          outlined
                                          v-model="user.notes">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox v-model="changePassword"
                                        label="Abilita Cambio Password"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               md="6">
                            <v-text-field :disabled="!changePassword"
                                          label="Password"
                                          prepend-inner-icon="mdi-lock"
                                          outlined
                                          v-model="user.password"
                                          :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                          counter
                                          :type="showPass1 ? 'text' : 'password'"
                                          @click:append="showPass1 = !showPass1"
                                          :rules="[rules.required, rules.password, rules.length(8), rules.confirmMatch(user.password, user.confirmPassword)]">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6">
                            <v-text-field :disabled="!changePassword"
                                          label="Conferma Password"
                                          prepend-inner-icon="mdi-lock-outline"
                                          outlined
                                          v-model="user.confirmPassword"
                                          :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                          :type="showPass2 ? 'text' : 'password'"
                                          @click:append="showPass2 = !showPass2"
                                          counter
                                          :rules="[rules.required, rules.password, rules.length(8), rules.confirmMatch(user.password, user.confirmPassword)]">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               sm="12"
                               style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                ">
                            <v-btn color="info"
                                   @click="edit()"
                                   raised>
                                Salva
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    components: {},
    name: "AthEditUser",
    props: ["modal", "user"],
    methods: {
        edit() {
            let payload = Object.assign({}, this.user);
            payload.roleUUID = this.user.role.uuid;
            payload.changePassword = this.changePassword;

            AthWebClient.put("/users/" + this.user.uuid, payload)
            .then((res) => {
                    console.info(res);
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Utente Modificato con successo",
                    })
                    .then((val) => {
                        val;
                        this.$emit("ath-user-modified", res);
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la modifica dell'utente. Utente con questa email già esiste!",
                    })
                    .then((e) => {
                        e;
                        this.$emit("ath-user-modified", {});
                    })
                });
        },
        getName(n) {
            if (n == "Doctor") return "Dottore";
            if (n == "Secretary") return "Segretaria";
            if (n == "Admin") return "Amministratore";
        },
    },
    mounted() {
        AthWebClient.get("/general/roles/")
            .then((data) => {
                this.roles = data.data.map((it) => ({
                    text: this.getName(it.name),
                    value: it.uuid,
                }));
            })
            .catch((err) => {
                console.error(err);
            });
    },
    data: () => ({
        roles: [],
        changePassword: false,
        showPass1: false,
        showPass2: false,
        durations: [
            { text: "5 min", value: 5 },
            { text: "10 min", value: 10 },
            { text: "15 min", value: 15 },
            { text: "20 min", value: 20 },
            { text: "25 min", value: 25 },
            { text: "30 min", value: 30 },
        ],
        rules: {
            length: (len) => (v) =>
                (v || "").length >= len ||
                `La password inserita è troppo corta, il numero di caratteri minimo è ${len}`,
            password: (v) =>
                !!(v || "").match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
                ) ||
                "La password deve contenere almeno una lettera maiuscola, una minuscola, una cifra, ed un carattere speciale",
            required: (v) => !!v || "Questo campo è obbligatorio",
            confirmMatch: (p, pd) =>
                p == pd || "Le due password non corrispondono.",
        },
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
