<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12"
                   sm="12"
                   :md="dashboardMode ? 12 : 8"
                   :offset-md="dashboardMode ? 0 : 2">
                <v-card hover>
                    <p class="text-h4 text--primary text-center"
                       style="padding: 10px 0px 10px 0px">
                        Appuntamenti Cancellati
                    </p>
                </v-card>
                <ath-appointments-filters :dashboard-mode="dashboardMode"
                                          :doctors="doctors"
                                          :doctor-filter-enabled="doctorFilterEnabled"
                                          v-on:ath-appointemnts-filter-apply-filters="handleFilterUpdate($event)">
                </ath-appointments-filters>
                <br />
                <ath-events-table :headers="headers"
                                  :events="appointments"
                                  :total="appointmentsTotal"
                                  :loading="loading"
                                  v-on:ath-pagination-update="handlePaginationUpdate"
                                  :page="page">
                </ath-events-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthEventsTable from "../ath-events-table/ath-events-table.vue";
import AthAppointmentsService from "../ath-appointments/ath-appointments.service";
import AthAppointmentsFilters from "../ath-appointments/ath-appointments-filters/ath-appointments-filters.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";

export default {
    name: "AthDeletedAppointments",
    components: {
        AthEventsTable,
        AthAppointmentsFilters,
    },
    props: {
        dashboardMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.loading = true;

        if (this.dashboardMode) {
            this.filters.showOnlyToday = true;
        }

        // load appointments
        AthAppointmentsService.getDeletedAppointmentsAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.appointments = data.data;
                this.appointmentsTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
                this.doctors = [];
            });
    },
    methods: {
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthAppointmentsService.getDeletedAppointmentsAuto(
                this.page,
                this.perPage,
                this.filters
            )
                .then((data) => {
                    this.appointments = data.data;
                    this.appointmentsTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
    },
    data: () => ({
        // data for the appointments table
        appointments: [],
        appointmentsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Cliente", value: "patient" },
            { text: "Dottore", value: "doctor" },
            { text: "Motivazione", value: "reason" },
            // { text: "Tipo", value: "type" },
            { text: "Data", value: "date" },
            { text: "Ora", value: "time" },
            { text: "Completato", value: "done" },
            // { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            from: null,
            end: null,
            doctorId: null,
            sortByDate: null,
            showOnlyToday: null,
            showOnlyCompleted: null,
        },
    }),
};
</script>
