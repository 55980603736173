<template>
    <v-container >
        <v-row style="justify-content: center">
            <v-col cols="12" sm="7" md="5" lg="4" xl="3">
                <v-card style="border-radius: 1rem" elevation="5">
                    <div
                        style="
                            display: flex;
                            background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
                            max-height: 350px;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-content: flex-end;
                            justify-content: center;
                            align-items: center;
                        "
                    >
                        <h1
                            style="
                                color: white;
                                text-align: center;
                                padding: 1rem;
                                font-weight: 400;
                            "
                        >
                            Associazione Medica
                        </h1>
                        <img
                            src="https://portale.associazione-medica.it/img/logo-small.51f21bd0.png"
                            style="margin-top: -30px"
                        />
                    </div>
                    <v-card-title
                        style="justify-content: center; font-size: 1.3rem"
                        >Log In</v-card-title
                    >
                    <v-card-text style="padding: 1.5rem">
                        <v-text-field
                            label="Email"
                            outlined
                            :loading="loading"
                            v-model="email"
                        ></v-text-field>
                        <v-text-field
                            label="Password"
                            type="password"
                            outlined
                            :loading="loading"
                            v-model="password"
                            v-on:keyup.enter="login()"
                        ></v-text-field>
                        <v-btn @click="login()" color="info" x-large block
                            >Login</v-btn
                        >
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <ath-backend-status></ath-backend-status>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row style="justify-content: center">
          <v-col cols="12" sm="7" md="5" lg="4" xl="3">
                <v-card hover shaped elevation="5" >
                    <v-card-title>Citazione del Giorno</v-card-title>
                    <v-card-text
                        ><em class="pt-4">{{ quote.text }}</em> <br />
                        <p style="text-align: right; margin-top: 1rem">
                            {{ getAuthorName(quote.author) }}
                        </p></v-card-text
                    >
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import athWebClient from "../../shared/ath-webclient";
import athBackendStatus from "../ath-backend-status/ath-backend-status.vue";

export default {
    components: { athBackendStatus },
    name: "AthLogin",
    data: () => ({
        loading: false,
        email: null,
        password: null,
        quote: {
            text: "",
            author: "",
        },
    }),
    mounted: function () {
        let quotes = [
            // { text: "Se sei triste, sii felice", author: "Aristotele, 2020" },
            // { text: "Compra i Bitcoin e salva Harambe",author: "Abraham Lincoln, 1984" },
            // { text: "Problemi di Alzheimer? Dimenticali!", author: "Barbara D'Urso, 2001" },
            {
                text: "Una mela al giorno toglie il medico di torno. Basta avere una buona mira.",
                author: "SIR WINSTON CHURCHILL",
            },
            {
                text: "Il dottore del futuro non darà medicine, ma invece motiverà i suoi pazienti ad avere cura del proprio corpo, alla dieta, ed alla causa e prevenzione della malattia.",
                author: "THOMAS ALVA EDISON",
            },
            {
                text: "La cosa che più ti aiuta a guarire è il buon umore.",
                author: "PINDARO",
            },
            {
                text: "Tutti sanno che è più necessaria la prevenzione della cura, ma pochi premiano gli atti di prevenzione.",
                author: "NASSIM NICHOLAS TALEB",
            },
            {
                text: "Una gran parte di quello che i medici sanno è insegnato loro dai malati.",
                author: "MARCEL PROUST",
            },
            {
                text: "Non è che abbiamo perso la fede: l'abbiamo semplicemente trasferita da Dio alla professione medica.",
                author: "GEORGE BERNARD SHAW",
            },
            {
                text: "Nulla è di per sé veleno, tutto è di per sé veleno, è la dose che fa il veleno.",
                author: "PARACELSO",
            },
            {
                text: "Alcuni pazienti, sebbene consapevoli della serietà delle loro condizioni, recuperano la propria salute semplicemente attraverso il rallegrarsi della bravura del medico.",
                author: "IPPOCRATE DI COO",
            },
            {
                text: "La medicina è la sola professione che lotta incessantemente per distruggere la ragione della propria esistenza.",
                author: "JAMES BRYCE",
            },
            {
                text: "Uno dei primi compiti del medico è di educare le masse a non prendere medicine.",
                author: "WILLIAM OSLER",
            },
            {
                text: "Conoscere il naturale progresso delle malattie è conoscere più della metà della medicina.",
                author: "ARMAND TROUSSEAU",
            },
            {
                text: "Ascoltare un paziente non richiede solo strumenti medici.",
                author: "Guido Jonckers",
            },
            {
                text: "Ognuno, se sapesse quel che i medici fanno, gli cederebbe volentieri la metà dei propri beni, a patto che non si accostassero mai troppo.",
                author: "LEV TOLSTOJ",
            },
            {
                text: "Quasi tutti i dottori hanno la loro malattia favorita.",
                author: "HENRY FIELDING",
            },
            {
                text: "Un medico che curi se stesso ha un matto per paziente.",
                author: "WILLIAM OSLER",
            },
            {
                text: "Medicina è ripareggiamento de' disequalati elementi; Malattia è discordanza d'elementi fusi nel vitale corpo.",
                author: "LEONARDO DA VINCI",
            },
            {
                text: "Quando un uomo affronta sei anni di addestramento per diventare dottore non sarà mai più lo stesso. Sa troppo.",
                author: "ENID ALGERINE BAGNOLD",
            },
            {
                text: "Ci sono dei mali dai quali non bisogna cercare di guarire perché sono i soli a proteggerci contro quelli più gravi.",
                author: "MARCEL PROUST",
            },
            {
                text: "Fai attenzione quando leggilibri di medicina. Potresti morire per un errore di stampa.",
                author: "MARK TWAIN",
            },
            {
                text: "Il medico saggio deve essere esperto tanto per prescrivere un rimedio quanto per non prescrivere nulla.",
                author: "BALTASAR GRACIÀN",
            },
            {
                text: "Prevenire è meglio che curare.",
                author: "ERASMO DA ROTTERDAM",
            },
            {
                text: "La medicina è la mia legittima sposa, mentre la letteratura è la mia amante: quando mi stanco di una, passo la notte con l'altra.",
                author: "ANTON PAVLOVIC CECHOV",
            },
        ];

        this.quote = quotes[Math.floor(Math.random() * quotes.length)];

        // athWebClient.get("/general/new-version")
        // .then((res) => {
        //     if(res.data.response == "true")
        //     {
        //         console.info("Cleaning up");
        //         localStorage.clear();
        //     }
        // })
    },
    methods: {
        getAuthorName(name) {
            let transform = name.toLowerCase();

            let re = /(\b[a-z](?!\s))/g;

            transform = transform.replace(re, function (x) {
                return x.toUpperCase();
            });

            return transform;
        },
        login: function () {
            this.loading = true;
            athWebClient
                .post("/login/", {
                    email: this.email,
                    password: this.password,
                })
                .then((response) => {
                    this.$store.commit("user.login", {
                        loginToken: response.data.token,
                        loginRole: response.data.user.role.level,
                        loginEmail: response.data.user.email,
                        loginId: response.data.user.uuid,
                        loginName: response.data.user.firstName[0] + response.data.user.lastName[0],
                        preferredIntervalTimeMins: response.data.user.preferredIntervalTimeMins,
                    });
                    this.loading = false;
                    this.$router.push("/dashboard");
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Credenziali incorrette. Si prega di controllare email e password",
                    });
                });
        },
    },
};
</script>