<template>
    <v-container fluid>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Appuntamento eliminato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="successEditMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Appuntamento Modificato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successEditMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="deleteModal"
                  persistent
                  width="960">
            <v-card>
                <v-card-title class="text-h5">
                    Cancella Appuntamento
                </v-card-title>

                <v-card-text>
                    Inserire la motivazione (obbligatoria) per procedere con la cancellazione dell'evento.

                    <br />
                    <br />

                    <v-text-field label="Motivazione"
                                  outlined
                                  v-model="deleteReason"
                                  color="error"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteModal = false">
                        Annulla
                    </v-btn>
                    <v-btn color="error"
                           raised
                           :disabled="!deleteReason || deleteReason.length < 3"
                           @click="deleteWithReason()">
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- edit dialog -->
        <v-dialog v-model="editDialog"
                  persistent
                  width="960">
            <v-card>
                <v-card-title class="text-h4 justify-center">
                    Modifica Appuntamento
                </v-card-title>
                <v-card-text>
                    <ath-edit-appointment v-if="editDialog"
                                          v-on:ath-cancel-edit-appointment="editDialog = false"
                                          v-on:ath-appointment-edit-success="handleEdit()"
                                          class="black--text"
                                          :appointmentUUID="appointmentToEdit"></ath-edit-appointment>
                </v-card-text>

                <v-divider></v-divider>

                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="editDialog = false">
                        Annulla
                    </v-btn>
                    <v-btn color="error"
                           raised>
                        Conferma
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

        <!-- view dialog -->
        <v-dialog v-model="viewDialog"
                  v-if="appointmentToView"
                  width="600px">
            <v-card>
                <v-card-title style="justify-content: center">
                    Dettagli Appuntamento
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12"
                               sm="12">
                            <h3 class="black--text">Dati</h3>
                        </v-col>
                        <v-col cols="12"
                               sm="6">
                            <v-text-field prepend-inner-icon="mdi-calendar"
                                          label="Data"
                                          outlined
                                          readonly
                                          :value="getDate(appointmentToView.adate)"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6">
                            <v-text-field prepend-inner-icon="mdi-account"
                                          label="Paziente"
                                          outlined
                                          readonly
                                          :value="appointmentToView.pfirstName + ' ' + appointmentToView.plastName"
                                          append-icon="mdi-eye"
                                          @click:append="showDetails(appointmentToView.puuid)"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                            sm="12"
                            v-if="showPatientDetails">
                            <v-card cols="12"
                                    sm="12"
                                    color="blue lighten-5">
                                <v-card-text>
                                    <b>{{ patient.firstName + " " + patient.lastName }} | {{ getBirthdate(patient.birthdate) }} | {{ patient.address }}</b>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12"
                               sm="6">
                            <v-text-field :prepend-inner-icon="
                            appointmentToView.acompleted ? 'mdi-check' : 'mdi-close'
                        "
                                          :success="appointmentToView.acompleted"
                                          :error="!appointmentToView.acompleted"
                                          label="Compleata"
                                          outlined
                                          readonly
                                          :value="appointmentToView.acompleted ? 'SI' : 'NO'"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6">
                            <v-text-field prepend-inner-icon="mdi-note"
                                          label="Motivazione Modifica"
                                          outlined
                                          readonly
                                          :value="appointmentToView.aeditReason"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="12">
                            <v-text-field prepend-inner-icon="mdi-stethoscope"
                                          label="Dottore"
                                          outlined
                                          readonly
                                          append-icon="mdi-open-in-new"
                                          :value="appointmentToView.ufirstName + ' ' + appointmentToView.ulastName"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="12">
                            <v-textarea prepend-inner-icon="mdi-note"
                                          label="Note"
                                          outlined
                                          readonly
                                          :value="appointmentToView.anotes"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn block color="info" @click.stop="viewDialog = false; appointmentToView = null;">
                        Chiudi
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row dense>
            <v-col cols="12"
                   sm="12"
                   :md="dashboardMode ? 12 : 8"
                   :offset-md="dashboardMode ? 0 : 2">
                <v-card hover>
                    <p class="text-h4 text--primary text-center"
                       style="padding: 10px 0px 10px 0px">
                        Appuntamenti
                    </p>
                </v-card>
                <ath-appointments-filters :dashboard-mode="dashboardMode"
                                          :doctors="doctors"
                                          :doctor-filter-enabled="doctorFilterEnabled"
                                          v-on:ath-appointemnts-filter-apply-filters="handleFilterUpdate($event)">
                </ath-appointments-filters>
                <br />
                <ath-events-table :headers="headers"
                                  :events="appointments"
                                  :total="appointmentsTotal"
                                  :loading="loading"
                                  v-on:ath-pagination-update="handlePaginationUpdate"
                                  v-on:ath-events-table-set-done-status="toggleCompleted($event)"
                                  v-on:ath-delete-with-reason="openDeleteDialog($event)"
                                  v-on:ath-edit-resource="openEditDialog($event)"
                                  v-on:ath-event-row-click="openViewDialog($event)"
                                  :page="page">
                </ath-events-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AthEventsTable from "../ath-events-table/ath-events-table.vue";
import AthAppointmentsService from "./ath-appointments.service";
import AthAppointmentsFilters from "./ath-appointments-filters/ath-appointments-filters.vue";
import AthDoctorsService from "../../shared/ath-doctors.service";
import AthEditAppointment from "../ath-edit-appointment/ath-edit-appointment.vue";
import AthPatientsService from "../../shared/ath-patients.service";
import moment from "moment";

export default {
    name: "ath-appointments",
    components: {
        AthEventsTable,
        AthAppointmentsFilters,
        AthEditAppointment,
    },
    props: {
        dashboardMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.loading = true;

        if (this.dashboardMode) {
            this.filters.showOnlyToday = true;
        }

        // load appointments
        AthAppointmentsService.getAppointmentsAuto(
            this.page,
            this.perPage,
            this.filters
        )
            .then((data) => {
                this.appointments = data.data;
                this.appointmentsTotal = data.total;
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });

        // load doctors
        AthDoctorsService.getDoctors()
            .then((data) => {
                this.doctors = data;
                this.doctorFilterEnabled = this.doctors.length > 0;
            })
            .catch((err) => {
                err;
                this.loading = false;
                this.doctors = [];
            });
    },
    methods: {
        getBirthdate(d) {
            return d;
            // let md = moment(d, "YYYY-MM-DD");

            // if (md.isValid) return md.format("DD/MM/YYYY");
            // else return md;
        },
        showDetails(puuid) {
            AthPatientsService.getPatientByUUID(puuid)
                .then((res) => {
                    this.patient = res;
                    this.showPatientDetails = !this.showPatientDetails;
                })
                .catch((err) => {
                    err;
                    this.patient = null;
                });
        },
        handleEdit: function () {
            this.editDialog = false;
            this.successEditMessage = true;
            this.handlePaginationUpdate({ page: 1, perPage: 10 });
        },
        openDeleteDialog: function (uuid) {
            this.deleteModal = true;
            this.appointmentToDelete = uuid;
            this.deleteReason = null;
        },
        deleteWithReason: function () {
            if (this.deleteReason == null || this.deleteReason.length < 1) {
                alert("ERROR");
                return;
            }

            AthAppointmentsService.deleteWithReason(
                this.appointmentToDelete,
                this.deleteReason
            )
                .then((res) => {
                    res;
                    this.deleteModal = false;
                    this.appointmentToDelete = null;
                    this.deleteReason = null;

                    this.handlePaginationUpdate({ page: 1, perPage: 10 });

                    this.successMessage = true;
                })
                .catch((err) => {
                    err;
                    this.deleteModal = false;
                    this.appointmentToDelete = null;
                    this.deleteReason = null;
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la cancellazione",
                    });
                });
        },
        handlePaginationUpdate: function (paginationOptions) {
            this.page = paginationOptions.page;
            this.perPage = paginationOptions.perPage;

            this.loading = true;

            AthAppointmentsService.getAppointmentsAuto(
                this.page,
                this.perPage,
                this.filters
            )
                .then((data) => {
                    this.appointments = data.data;
                    this.appointmentsTotal = data.total;
                    this.loading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                });
        },
        handleFilterUpdate: function (fils) {
            this.filters = fils;

            this.handlePaginationUpdate({ page: 1, perPage: this.perPage });
        },
        toggleCompleted: function (appointmentUUID) {
            AthAppointmentsService.toggleCompleted(appointmentUUID)
                .then((res) => {
                    res;
                    AthAppointmentsService.getAppointmentsAuto(
                        this.page,
                        this.perPage,
                        this.filters
                    ).then((data) => {
                        this.appointments = data.data;
                        this.appointmentsTotal = data.total;
                        this.loading = false;
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        openEditDialog: function (uuid) {
            this.appointmentToEdit = uuid;

            this.editDialog = true;
        },
        openViewDialog: function (item) {
            this.appointmentToView = item;

            this.viewDialog = true;
        },
        getDate(d) {
            return moment(d).format("DD/MM/YYYY");
        },
    },
    data: () => ({
        // data for the appointments table
        appointments: [],
        appointmentsTotal: 0,

        // array of doctors for which we should filter
        doctors: [],

        // determines if the user can see/use the doctor filter component
        doctorFilterEnabled: false,

        // headers for the appointments table
        headers: [
            { text: "Cliente", value: "patient" },
            { text: "Dottore", value: "doctor" },
            // { text: "Tipo", value: "type" },
            { text: "Data", value: "date" },
            { text: "Ora", value: "time" },
            { text: "Completato", value: "done" },
            { text: "Azione", value: "actions" },
        ],

        // ui-related
        loading: true,

        // pagination
        page: 1,
        perPage: 10,

        // filtering
        filters: {
            from: null,
            end: null,
            doctorId: null,
            sortByDate: null,
            showOnlyToday: null,
            showOnlyCompleted: null,
        },

        deleteModal: false,

        appointmentToDelete: null,

        deleteReason: null,

        successMessage: false,

        appointmentToEdit: null,

        editDialog: false,

        successEditMessage: false,

        viewDialog: false,

        appointmentToView: null,

        showPatientDetails: false,

        patient: null,
    }),
};
</script>
