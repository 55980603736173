<template>
    <v-row>
        <v-col cols="12"
               sm="12"
               :md="modal ? 12 : 10"
               :offset-md="modal ? 0 : 1">
            <v-container fluid>
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center;">
                        Crea Utente
                    </v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-card>
                    <v-container>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Nome"
                                              prepend-inner-icon="mdi-account"
                                              outlined
                                              v-model="user.firstName">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Cognome"
                                              prepend-inner-icon="mdi-account-outline"
                                              outlined
                                              v-model="user.lastName">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Email"
                                              prepend-inner-icon="mdi-at"
                                              outlined
                                              v-model="user.email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-select :items="roles"
                                          label="Ruolo"
                                          outlined
                                          success
                                          prepend-inner-icon="mdi-stethoscope"
                                          item-text="text"
                                          item-value="value"
                                          v-model="user.roleUUID"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Password"
                                              prepend-inner-icon="mdi-lock"
                                              outlined
                                              v-model="user.password"
                                              :rules="[rules.required, rules.password, rules.length(8), rules.confirmMatch(user.password, user.confirmPassword)]"
                                              :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                              counter
                                              :type="showPass1 ? 'text' : 'password'"
                                              @click:append="showPass1 = !showPass1">

                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Conferma Password"
                                              prepend-inner-icon="mdi-lock-outline"
                                              outlined
                                              v-model="user.confirmPassword"
                                              :rules="[rules.required, rules.confirmMatch(user.password, user.confirmPassword)]"
                                              :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                              :type="showPass2 ? 'text' : 'password'"
                                              @click:append="showPass2 = !showPass2"
                                              counter>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Orario Inizio"
                                              prepend-inner-icon="mdi-clock-time-eight"
                                              placeholder="08:00"
                                              outlined
                                              v-model="user.startTime">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Orario Fine"
                                              prepend-inner-icon="mdi-clock-time-eight-outline"
                                              placeholder="17:00"
                                              outlined
                                              v-model="user.endTime">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-select :items="durations"
                                          label="Durata Predefenita (min.)"
                                          outlined
                                          prepend-inner-icon="mdi-timer"
                                          v-model="user.preferredIntervalTimeMins"></v-select>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Telefono"
                                              prepend-inner-icon="mdi-phone"
                                              outlined
                                              v-model="user.phone">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12">
                                <v-textarea label="Note"
                                            prepend-inner-icon="mdi-note"
                                            outlined
                                            v-model="user.notes">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="12"
                                   style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                ">
                                <v-btn color="info"
                                       @click="create()"
                                       raised>
                                    Salva
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    components: {},
    name: "AthCreateUser",
    props: ["modal"],
    methods: {
        create() {
            if (
                !this.rules.confirmMatch(
                    this.user.password,
                    this.user.confirmPassword
                )
            ) {
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Le due password devono coincidere! Riprovare",
                });

                return;
            }

            if (
                (this.user.startTime == null || this.user.endTime == null) 
                || (this.user.startTime.match(/^\d\d:\d\d$/g) == null || this.user.endTime.match(/^\d\d:\d\d$/g) == null)
            ) {
                this.$swal({
                    icon: "error",
                    title: "Errore",
                    text: "Data di inizio e fine devono essere, ad esempio, come: 08:00, 19:00, 09:15, ecc",
                });

                return;
            }

            if(this.user.roleUUID.value) this.user.roleUUID = this.user.roleUUID.value;

            AthWebClient.post("/users/", this.user)
                .then((res) => {
                    res;
                    this.$swal({
                        icon: "success",
                        title: "Successo",
                        text: "Utente creato con successo",
                    })
                    .then((val) => {
                        val;
                        this.$router.push("/admin/list/users");
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "L'utente con email " + this.user.email + " già esiste! Si prega di cambiare email",
                    });
                });
        },
        getName(n) {
            if (n == "Doctor") return "Dottore";
            if (n == "Secretary") return "Segretaria";
            if (n == "Admin") return "Amministratore";
        },
    },
    mounted() {
        AthWebClient.get("/general/roles/")
            .then((data) => {
                this.roles = data.data.map((it) => ({
                    text: this.getName(it.name),
                    value: it.uuid,
                }));
                this.user.roleUUID = this.roles[0];
            })
            .catch((err) => {
                console.error(err);
            });
    },
    data: () => ({
        user: {},
        roles: [],
        durations: [
            { text: "5 min", value: 5 },
            { text: "10 min", value: 10 },
            { text: "15 min", value: 15 },
            { text: "20 min", value: 20 },
            { text: "25 min", value: 25 },
            { text: "30 min", value: 30 },
        ],
        showPass1: false,
        showPass2: false,
        rules: {
            length: (len) => (v) =>
                (v || "").length >= len ||
                `La password inserita è troppo corta, il numero di caratteri minimo è ${len}`,
            password: (v) =>
                !!(v || "").match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
                ) ||
                "La password deve contenere almeno una lettera maiuscola, una minuscola, una cifra, ed un carattere speciale",
            required: (v) => !!v || "Questo campo è obbligatorio",
            confirmMatch: (p, pd) =>
                p == pd || "Le due password non corrispondono.",
        },
    }),
};
</script>

<style scoped>
.theme--light.v-label {
    color: black;
}
</style>
