<template>
    <v-autocomplete
        outlined
        clearable
        v-on:change="handleSelectDrug"
        :items="drugs"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="isLoading"
        :search-input.sync="search"
        :filter="filter"
        prepend-inner-icon="mdi-pill"
        label="Ricerca Farmaco"
        no-data-text="Scrivi per cercare"
        v-model="dummy"
        ref="selectDrugRef"
        v-on:focus="$refs.selectDrugRef.reset()"
    >
        <template v-slot:selection="{ item }">
            <span
                >{{ item.name }} | <b>{{ item.activeSubstance }}</b></span
            >
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title
                    ><b>{{ item.name }}</b> | {{ item.activeSubstance }}
                </v-list-item-title>
                <v-list-item-subtitle class="primary--text text-button"
                    ><v-icon>mdi-add</v-icon> Clicca per aggiungere</v-list-item-subtitle
                >
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthSelectDrug",
    props: ['clearInputToggle'],
    methods: {
        // looks okay as it is, because search is done server-side.
        // in case, refer to: https://vuetifyjs.com/en/api/v-autocomplete/#props-filter
        // and: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L40
        filter: (item, queryText, itemText) => {
            // console.info({item, queryText, itemText});
            if(queryText.length < 3) return;

            item;
            queryText;
            itemText;
            // return itemText.toUpperCase().replace(/ /g,'').indexOf(queryText.toUpperCase().replace(/ /g,'')) > -1
            return true;
        },
        // these two cannot be inlined as in the other component because of https://github.com/vuetifyjs/vuetify/issues/6635#issuecomment-528444479
        itemText: function(item) {
            return item.name + " " + item.activeSubstance;
        },
        // the return type must be a primitive and apparenty an array or an object are not okay
        // so we return a json string containing the whole element
        itemValue: function(item) {
            return JSON.stringify(item);
        },
        handleSelectDrug($event) {
            this.$emit("ath-select-drug", $event);
            return $event;
        },
    },
    data: () => ({
        isLoading: false,
        drugs: [],
        search: null,
        dummy: null,
    }),
    watch: {
        search(val) {
            if(this.isLoading || !val || val.length < 3) return;
            
            this.isLoading = true;

            AthWebClient.get("/drugs/search/" + encodeURIComponent(val))
                .then((res) => {
                    this.drugs = res.data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
        },

        clearInputToggle: function(ignore) {
            ignore;
            this.dummy = null;
            this.$refs.selectDrugRef.reset();
        }
    },
};
</script>
