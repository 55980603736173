<template>
    <v-row>
        <v-snackbar v-model="successMessage"
                    color="success"
                    top
                    centered
                    elevation="10">
            Farmaco creato con successo!

            <template v-slot:action>
                <v-btn color="white"
                       text
                       @click="successMessage = false">
                    Chiudi
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12"
               sm="12"
               md="10"
               offset-md="1">
            <v-container fluid>
                <v-card hover>
                    <v-card-title class="text-h4"
                                  style="justify-content: center">Crea Farmaco</v-card-title>
                </v-card>
            </v-container>
            <v-container fluid>
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Nome"
                                              prepend-inner-icon="mdi-pill"
                                              outlined
                                              v-model="drug.name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   md="6">
                                <v-text-field label="Principio Attivo"
                                              prepend-inner-icon="mdi-note"
                                              outlined
                                              v-model="drug.activeSubstance">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                   sm="2"
                                   offset-sm="5"
                                   class="d-flex align-center justify-center">
                                <v-btn block
                                       @click="create()"
                                       color="info"
                                       raised>
                                    Salva
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import AthWebClient from "../../shared/ath-webclient";

export default {
    name: "AthCreateDrug",
    methods: {
        create() {
            AthWebClient.post("/drugs/", this.drug)
                .then((res) => {
                    res;
                    this.successMessage = true;
                    this.drug = {};
                })
                .catch((err) => {
                    console.info(err);
                    this.$swal({
                        icon: "error",
                        title: "Errore",
                        text: "Errore durante la creazione del farmaco! Riprovare",
                    });
                });
        },
    },
    data: () => ({
        drug: {},
        successMessage: false,
    }),
};
</script>
