<template>
    <div>
        <v-card hover>
            <v-data-table :headers="headers"
                          :items="events"
                          :items-per-page="perPage"
                          :server-items-length="total"
                          :options.sync="options"
                          :page="page"
                          @click:row="$emit('ath-event-row-click', $event)"
                          :footer-props="{
                              'items-per-page-options': [5, 10, 15, 25, 50],
                              'show-current-page': true,
                              'show-first-last-page': true,
                          }"
                          class="elevation-1"
                          disable-filtering
                          disable-sort
                          hide-default-header
                          mobile-breakpoint="100">
                <!-- make headers bold -->
                <template v-slot:header="heads">
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader"
                                scope="col"
                                v-for="h in heads.props.headers"
                                :key="h.text"
                                style="
                                    font-size: 1rem;
                                    color: rgba(0, 0, 0, 0.87);
                                "
                                class="text-start">
                                {{ h.text }}
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:top>
                    <slot name="filters"></slot>
                </template>

                <template v-slot:[`item.patient`]="{ item }">
                    <b style="font-size: large;"
                       v-if="item.atype == 'WITH_PATIENT'">{{ item.pfirstName }}
                        {{ item.plastName }}</b>
                    <b style="font-size: large; color: #4caf50;"
                       v-if="item.atype == 'WITH_REPRESENTATIVE'">{{ item.rfirstName }}
                        {{ item.rlastName }}<br />({{ item.cname }})</b>
                </template>

                <template v-slot:[`item.doctor`]="{ item }">
                    {{ item.ufirstName }}
                    {{ item.ulastName }}
                </template>

                <template v-slot:[`item.reason`]="{ item }">
                    <h3>{{ item.adeleteReason }}</h3>
                </template>

                <!--                <template v-slot:[`item.type`]="{ item }">-->
                <!--                    <v-chip-->
                <!--                        label-->
                <!--                        outlined-->
                <!--                        class="ma-2"-->
                <!--                        :color="getChipColor(item.atype)"-->

                <!--                    >-->
                <!--                        {{ item.atype | getType }}-->
                <!--                    </v-chip>-->
                <!--                </template>-->

                <template v-slot:[`item.date`]="{ item }">
                    {{ item.astart | extractDate }}
                </template>

                <template v-slot:[`item.time`]="{ item }">
                    <v-tooltip top
                               color="blue-grey darken-4">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs"
                                  v-on="on">{{ item.astart | extractTime }}</span>
                        </template>
                        <span>Termina alle <b>{{ item.aend | extractTime }}</b></span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.done`]="{ item }">
                    <v-btn max-width="50"
                           color="success"
                           v-if="item.acompleted"
                           @click.stop="
                            $emit(
                                'ath-events-table-set-done-status',
                                item.auuid
                            )
                        ">
                        <v-icon left> mdi-check-bold </v-icon>
                        Si
                    </v-btn>
                    <v-btn class="white--text"
                           color="red lighten-1"
                           max-width="50"
                           v-if="!item.acompleted"
                           @click.stop="
                            $emit(
                                'ath-events-table-set-done-status',
                                item.auuid
                            )
                        ">
                        <v-icon left> mdi-close-thick </v-icon>
                        No
                    </v-btn>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom
                            left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="info"
                                   outlined
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn small
                                           color="primary"
                                           @click="$emit('ath-edit-resource', item.auuid)">
                                        <v-icon>mdi-pencil</v-icon> Modifica
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn small
                                           color="error"
                                           @click="deleteWithReason(item.auuid)">
                                        <v-icon>mdi-delete</v-icon> Elimina
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
            <v-overlay color="white"
                       absolute
                       :value="loading"
                       light
                       opacity="0.7">
                <v-progress-circular color="info"
                                     indeterminate
                                     size="128"></v-progress-circular>
            </v-overlay>
        </v-card>
    </div>
</template>

<script>
/**
 * Events table
 *
 * @props
 *  - headers:Array<v-header> contains the headers for the table
 *  - events:Array<any> contains the rows to be disaplyed in the table
 *  - loading:Boolean indicates if the table is loading or not
 *  - page:Number page to display
 *  - perPage:Number how many rows to display
 *  - total:Number total number of elements that the table will display. Total means total, since the table is paginated. Server-side returned total
 * @events
 *  - ath-events-table-set-done-status:Boolean event that indicates that the user clicked on the "mark as (un)done" button
 */

import moment from "moment";

export default {
    name: "ath-events-table",
    props: [
        // dataSource
        "headers",
        "events",
        "loading",

        // pagination
        "page",
        "perPage",
        "total",
    ],
    watch: {
        options: {
            handler(opts) {
                this.$emit("ath-pagination-update", {
                    page: opts.page,
                    perPage: opts.itemsPerPage,
                });
            },
            deep: true,
        },
    },
    filters: {
        extractDate: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
        extractTime: function (date) {
            return moment(date).format("HH:mm");
        },
        getType(type) {
            if (type == "WITH_PATIENT") return "P";
            else if (type == "WITH_REPRESENTATIVE") return "I";
            else return "TIPO SCONOSCIUTO";
        },
    },
    methods: {
        getDuration(start, end) {
            return moment(end).diff(moment(start), "minutes");
        },
        alert(s) {
            window.alert(s);
        },
        getChipColor(type) {
            if (type == "WITH_PATIENT") return "info";
            else if (type == "WITH_REPRESENTATIVE") return "green";
            else return "default";
        },
        deleteWithReason(uuid) {
            this.$emit("ath-delete-with-reason", uuid);
        },
    },
    data() {
        return {
            options: {},
        };
    },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
    background-color: rgba(94, 223, 255, 0.08);
}
</style>