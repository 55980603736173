<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12"
                   sm="12"
                   md="6"
                   offset-md="3">
                <v-card>
                    <v-card-title>
                        <v-icon color="error">mdi-alert</v-icon> Gestione Feature Toggles (sperimentale)<v-icon color="error">mdi-alert</v-icon>
                    </v-card-title>
                    <v-card-text class="black--text">
                        Qui è possibile attivare o disattivare diverse funzionalità per tutti gli utenti e/o solo per gli admin
                        <br />
                        <br />
                        Funzionalità Sperimentale, non utilizzare al momento
                        <br />
                        <br />
                        <v-divider></v-divider>
                        <br />
                        <v-container>
                            <v-switch inset
                                      label="Funzionalità 1"></v-switch>
                            <v-switch inset
                                      label="Funzionalità 2"></v-switch>
                            <v-switch inset
                                      label="Funzionalità 3"></v-switch>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12"
                                   sm="4"
                                   offset-sm="4">
                                <v-btn block
                                       color="info">Salva Configurazione</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "AthManageFeatureToggles",
    data: () => ({}),
    mounted() {},
    methods: {},
    watch: {},
};
</script>

<style scoped>
</style>