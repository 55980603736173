var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"hover":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":_vm.perPage,"server-items-length":_vm.total,"options":_vm.options,"page":_vm.page,"footer-props":{
                        'items-per-page-options': [5, 10, 15, 25, 50],
                        'show-current-page': true,
                        'show-first-last-page': true,
                      },"disable-filtering":"","disable-sort":"","hide-default-header":"","mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.handleRowClick($event)}},scopedSlots:_vm._u([{key:"header",fn:function(heads){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((heads.props.headers),function(h){return _c('th',{key:h.text,staticClass:"text-start",staticStyle:{"font-size":"1rem","color":"rgba(0, 0, 0, 0.87)"},attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" "+_vm._s(h.text)+" ")])}),0)])]}},{key:"top",fn:function(){return [_vm._t("filters")]},proxy:true},{key:"item.patient",fn:function(ref){
                      var item = ref.item;
return [_c('b',{staticStyle:{"font-size":"large"}},[_vm._v(_vm._s(item.pfirstName)+" "+_vm._s(item.plastName))])]}},{key:"item.doctor",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.ufirstName)+" "+_vm._s(item.ulastName)+" ")]}},{key:"item.type",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getChipColor(item.atype),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("getType")(item.atype))+" ")])]}},{key:"item.date",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("extractDate")(item.adate))+" ")]}},{key:"item.duration",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"blue-grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.adurationMins)+" Minuti")])]}}],null,true)},[_c('span',[_vm._v("Termina alle "),_c('b',[_vm._v(_vm._s(_vm._f("extractTime")(item.aend)))])])])]}},{key:"item.done",fn:function(ref){
                      var item = ref.item;
return [(item.acompleted)?_c('v-btn',{attrs:{"color":"success"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-bold ")]),_vm._v(" Si ")],1):_vm._e(),(!item.acompleted)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"red lighten-1"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-close-thick ")]),_vm._v(" No ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('ath-edit-prescription', item.auuid)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Modifica ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.$emit('ath-delete-prescription-with-reason', item.auuid)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Elimina ")],1)],1)],1)],1)],1)]}},{key:"item.reuse",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"raised":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('ath-reuse-prescription-ev', item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-account-outline")]),_vm._v(" Usa come modello ")],1)]}},(_vm.reusePrescription)?{key:"item.drugs",fn:function(ref){
                      var item = ref.item;
return [_c('ath-drugs-for-prescription',{attrs:{"prescriptionUUID":item.auuid}})]}}:null],null,true)}),_c('v-overlay',{attrs:{"color":"white","absolute":"","value":_vm.loading,"light":"","opacity":"0.7"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"128"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }